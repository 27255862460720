import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../common-components/Button';
import H3 from '../../../../text-components/H3';
import H5 from '../../../../text-components/H5';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import StatuswiseComplaintListModal from './StatuswiseComplaintListModal';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import swal from 'sweetalert';
import { useReactToPrint } from 'react-to-print'
import { officeCommunication } from '../../../../services/officeCommunication';
import { useNavigate } from 'react-router-dom';
import { hideLoader, showLoader } from '../../../../redux-store/loaderReducer';
import Loader from '../../../../utilities/Loader';
import * as FileSaver from "file-saver";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
ChartJS.defaults.set('plugins.datalabels', {
    color: '#FFF'
});



const Reports = () => {
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const navigate = useNavigate();
    const scrollToList = useRef();
    const printGraph = useRef();
    const chartRef = useRef();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader.showHideLoader);

    //get select box values
    const [selectBoxValues, setSelectBoxValues] = useState(
        {
            filterBy: "",
            agingType: "",
        }
    );

    const [departments, setDepartments] = useState([]);
    const [subDepartmentData, setSubDepartmentData] = useState();
    const [departmentData, setDepartmentData] = useState();
    const [statusGraphData, setStatusGraphData] = useState();
    const [agingGraphData, setAgingGraphData] = useState();
    const [userSatisfactionGraphData, setUserSatisfactionGraphData] = useState();
    // click on Bar data will be set in state
    const [labelData, setLabelData] = useState({
        complaintStatus: "",
        startDate: "",
        endDate: "",
        departmentId: "",
        showComplaintList: false,
        searchFilterValue: "",
        agingFilterValue: "creationDate",
        dataBetween: "",
        rating: "",
        filterDepartmentId: ""
    });

    //Print Graph
    const printSelectedGraph = useReactToPrint({
        content: () => printGraph.current,
    })

    //Scroll to last Complaint List
    const scrollToComplaintList = () => {
        scrollToList?.current?.lastChild?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    //Graph Filter
    const graphFilter = (filterValue) => {
        if (filterValue === "By_Department") {
            fetchAllDepartmentGraphData();
        } else if (filterValue === "By_Sub_Department") {
            getDepartments();
            fetchSubDepartmentGraphData();
        } else if (filterValue === "By_Status") {
            fetchAllStatusGraphData();
        } else if (filterValue === "By_Aging") {
            fetchAgingGraphData(selectBoxValues?.agingType);
        } else if (filterValue === "By_User_Feedback") {
            fetchUserSatisfactionGraphData();
        }
        else {
            fetchAllDepartmentGraphData();
        }
        setLabelData({ startDate: "", endDate: "" })
    }

    //Aging Filter
    const agingFilter = (agingFilterValue) => {
        fetchAgingGraphData(agingFilterValue);
        setLabelData({ ...labelData, agingFilterValue: agingFilterValue });
    }

    //Get Graph data
    const getGraphData = (event) => {
        //Index Number to get selected department
        const indexNumber = getElementAtEvent(chartRef.current, event)[0].index;
        //Dataset Index Number to get selected department Status
        const dataSetIndexNumber = getElementAtEvent(chartRef.current, event)[0].datasetIndex;

        if ((selectBoxValues?.filterBy === "" || selectBoxValues?.filterBy === "By_Department")) {
            setLabelData({ ...labelData, complaintStatus: departmentGraphData.datasets[dataSetIndexNumber].label, departmentId: departmentData.departments[indexNumber].departmentId, searchFilterValue: "By_Department", showComplaintList: true });
        } else if (selectBoxValues?.filterBy === "By_Status") {
            setLabelData({ ...labelData, complaintStatus: statusWiseGraphData.labels[indexNumber], searchFilterValue: "By_Status", showComplaintList: true });
        } else if (selectBoxValues?.filterBy === "By_Aging") {
            setLabelData({ ...labelData, complaintStatus: agingGraph.datasets[dataSetIndexNumber].label, dataBetween: agingGraph.labels[indexNumber], searchFilterValue: "By_Aging", showComplaintList: true });
        } else if (selectBoxValues?.filterBy === "By_User_Feedback") {
            const firstLetterOfLable = userFeedbackGraphData.datasets[dataSetIndexNumber].label.charAt(0);
            const rating = firstLetterOfLable.toLowerCase() === "r" ? "reopen" : Number(firstLetterOfLable);
            setLabelData({ ...labelData, rating: rating, complaintStatus: rating, departmentId: userSatisfactionGraphData.departments[indexNumber].departmentId, searchFilterValue: "By_User_Feedback", showComplaintList: true });
        } else if (selectBoxValues?.filterBy === "By_Sub_Department") {
            setLabelData({ ...labelData, complaintStatus: subDepartmentGraphData.datasets[dataSetIndexNumber].label, subDepartmentId: subDepartmentData.subDepartments[indexNumber].subDepartmentId, searchFilterValue: "By_Sub_Department", showComplaintList: true });
        }
        scrollToComplaintList();
    }

    //get departments 
    async function getDepartments() {
        try {
            const responseFromServer = await officeCommunication.getDepartments()
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDepartments(responseFromServer?.data?.department);
                dispatch(hideLoader());
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.status, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.status, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    //Get All Department graph data
    const fetchAllDepartmentGraphData = async () => {
        dispatch(showLoader());
        try {
            const responseFromServer = await officeCommunication.getAllDepartmentGraphDetails(labelData.startDate, labelData.endDate);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDepartmentData(responseFromServer?.data?.graphData);
                dispatch(hideLoader());
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.status, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.status, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    //get sub department graph data
    async function fetchSubDepartmentGraphData(departmentId) {
        dispatch(showLoader());
        try {
            const responseFromServer = await officeCommunication.getSubDepartmentGraphDetails(departmentId, labelData.startDate, labelData.endDate);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setSubDepartmentData(responseFromServer?.data?.graphData);
                dispatch(hideLoader());
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.status, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.status, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    //Get All Status graph data
    const fetchAllStatusGraphData = async () => {
        dispatch(showLoader());
        try {
            const responseFromServer = await officeCommunication.getAllStatusGraphDetails(labelData?.startDate, labelData?.endDate);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setStatusGraphData(responseFromServer?.data?.statusWiseCount);
                dispatch(hideLoader());
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    //Get Aging graph data
    const fetchAgingGraphData = async (agingFilterValue) => {
        dispatch(showLoader());
        try {
            const responseFromServer = await officeCommunication.getAgingGraphDetails(agingFilterValue);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAgingGraphData(responseFromServer?.data?.graphData);
                dispatch(hideLoader());
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.massage, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.massage, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            swal({ text: error?.response?.data?.massage || error?.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    //Get User Satisfaction Graph data
    const fetchUserSatisfactionGraphData = async () => {
        dispatch(showLoader());
        try {
            const responseFromServer = await officeCommunication.getUserSatisfactionGraphDetails(labelData?.startDate, labelData?.endDate);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setUserSatisfactionGraphData(responseFromServer?.data?.graphData);
                dispatch(hideLoader());
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }
    //================Filter By Department================
    //Filter graph By Department data
    const departmentGraphData = {
        labels: departmentData?.departments?.map((ele) => { return ele?.departmentName }),
        datasets: [
            {
                label: 'New',
                data: departmentData?.newComplaintsCountArray,
                backgroundColor: [`${theme === "WHITE" ? "#25316D" : "#6678d4"}`],
                barThickness: 60,
            },
            {
                label: 'Reassigned',
                data: departmentData?.inprogressComplaintsCountArray,
                backgroundColor: ["#FFC23C"],
                barThickness: 60,
            },
            {
                label: 'Re-Opened',
                data: departmentData?.reopenComplaintsCountArray,
                backgroundColor: ['#9B0000'],
                barThickness: 60,
            },
            {
                label: 'Resolved',
                data: departmentData?.resolveComplaintsCountArray,
                backgroundColor: [`${theme === "WHITE" ? "#54B435" : "#70db4d"}`],
                barThickness: 60,
            },
            {
                label: 'Closed',
                data: departmentData?.closedComplaintsCountArray,
                backgroundColor: [`${theme === "WHITE" ? "#006400" : "#05a105"}`],
                barThickness: 60,
            },
        ],

        plugins: [ChartDataLabels],

    };
    //Department wise filter bar options
    const departmentBaroptions = {
        plugins: {
            title: {
                display: true,
                text: 'Filtered By Department',
                color: `${theme === "WHITE" ? "grey" : "skyblue"}`,
                align: "start",
            },
            legend: {
                display: true,
                align: "start",
                labels: {
                    color: `${theme === "WHITE" ? "grey" : "skyblue"}`,
                },
            }

        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`, font: {
                        size: 11
                    },
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`,
                    min: 0,
                    stepSize: 30
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
        },
    };

    //? filter by sub department graph
    const subDepartmentGraphData = {
        labels: subDepartmentData?.subDepartments?.map((ele) => { return ele?.subDepartmentName }),
        datasets: [
            {
                label: 'New',
                data: subDepartmentData?.newComplaintsCountArray,
                backgroundColor: [`${theme === "WHITE" ? "#25316D" : "#6678d4"}`],
                barThickness: 60,
            },
            {
                label: 'Reassigned',
                data: subDepartmentData?.inprogressComplaintsCountArray,
                backgroundColor: ["#FFC23C"],
                barThickness: 60,
            },
            {
                label: 'Re-Opened',
                data: subDepartmentData?.reopenComplaintsCountArray,
                backgroundColor: ['#9B0000'],
                barThickness: 60,
            },
            {
                label: 'Resolved',
                data: subDepartmentData?.resolveComplaintsCountArray,
                backgroundColor: [`${theme === "WHITE" ? "#54B435" : "#70db4d"}`],
                barThickness: 60,
            },
            {
                label: 'Closed',
                data: subDepartmentData?.closedComplaintsCountArray,
                backgroundColor: [`${theme === "WHITE" ? "#006400" : "#05a105"}`],
                barThickness: 60,
            },
        ],

        plugins: [ChartDataLabels],

    };
    //Department wise filter bar options
    const subDepartmentBarOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Filtered By Sub Department',
                color: `${theme === "WHITE" ? "grey" : "skyblue"}`,
                align: "start",
            },
            legend: {
                display: true,
                align: "start",
                labels: {
                    color: `${theme === "WHITE" ? "grey" : "skyblue"}`,
                },
            }

        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`, font: {
                        size: 11
                    },
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`,
                    min: 0,
                    stepSize: 30
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
        },
    };
    //=================Filter By Status===============
    //Filter grpah by Status data
    const statusWiseGraphData = {
        labels: ['New', 'Reassigned', 'Re-Opened', 'Resolved', 'Closed'],
        datasets: [
            {
                label: "",
                data: [statusGraphData?.new, statusGraphData?.reassigned, statusGraphData?.reopen, statusGraphData?.resolved, statusGraphData?.closed],
                backgroundColor: [`${theme === "WHITE" ? "#25316D" : "#6678d4"}`, '#FFC23C', '#9B0000', `${theme === "WHITE" ? "#54B435" : "#70db4d"}`, `${theme === "WHITE" ? "#006400" : "#05a105"}`],
                barThickness: 70,
            },
        ],
        plugins: [ChartDataLabels],

    };
    //Status wise filter bar options
    const statusBaroptions = {
        plugins: {
            title: {
                display: true,
                text: 'Filtered By Status',
                color: `${theme === "WHITE" ? "grey" : "skyblue"}`,
            },
            legend: {
                display: false,

            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`, font: {
                        size: 11,
                    },
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`,
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`,
                    min: 0,
                    stepSize: 30
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
        },
    };

    //================Filter By Aging================
    //Filter graph By Aging data
    const agingGraph = {
        labels: agingGraphData?.labels,
        datasets: [
            {
                label: 'New',
                data: agingGraphData?.newComplaint,
                backgroundColor: [`${theme === "WHITE" ? "#25316D" : "#6678d4"}`],
                barThickness: 70,
            },
            {
                label: 'Reassigned',
                data: agingGraphData?.inprogressComplaint,
                backgroundColor: ['#FFC23C'],
                barThickness: 70
            },
            {
                label: 'Re-Opened',
                data: agingGraphData?.reopenComplaint,
                backgroundColor: ['#9B0000'],
                barThickness: 70
            },
            {
                label: 'Resolved',
                data: agingGraphData?.resolveComplaint,
                backgroundColor: [`${theme === "WHITE" ? "#54B435" : "#70db4d"}`],
                barThickness: 70
            },
            {
                label: 'Closed',
                data: agingGraphData?.closedComplaint,
                backgroundColor: [`${theme === "WHITE" ? "#006400" : "#05a105"}`],
                barThickness: 70
            },
        ],

        plugins: [ChartDataLabels],

    };
    //Department wise filter bar options
    const agingBaroptions = {
        plugins: {
            title: {
                display: true,
                text: 'Filtered By Aging',
                color: `${theme === "WHITE" ? "grey" : "skyblue"}`
            },
            legend: {
                display: true,
                labels: {
                    color: `${theme === "WHITE" ? "grey" : "skyblue"}`
                }
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`, font: {
                        size: 11
                    }
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`,
                    min: 0,
                    stepSize: 30
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
        },
    };

    //================Filter By User Feedback================
    //Filter graph By user feedback data
    const userFeedbackGraphData = {
        labels: userSatisfactionGraphData?.departments?.map((ele) => { return ele?.departmentName }),
        datasets: [
            {
                label: 'Re-Opened',
                data: userSatisfactionGraphData?.reopenCount,
                backgroundColor: ['#9B0000'],
                barThickness: 70,
            },
            {
                label: '1 Star',
                data: userSatisfactionGraphData?.oneStarRating,
                backgroundColor: ['#8AFF8A'],
                barThickness: 70
            },
            {
                label: '2 Star',
                data: userSatisfactionGraphData?.twoStarRating,
                backgroundColor: ['#2EFF2E'],
                barThickness: 70
            },
            {
                label: '3 Star',
                data: userSatisfactionGraphData?.threeStarRating,
                backgroundColor: ['#00D100'],
                barThickness: 70
            },
            {
                label: '4 Star',
                data: userSatisfactionGraphData?.fourStarRating,
                backgroundColor: ['#007500'],
                barThickness: 70
            },
            {
                label: '5 Star',
                data: userSatisfactionGraphData?.fiveStarRating,
                backgroundColor: ['#002c00'],
                barThickness: 70
            },
        ],

        plugins: [ChartDataLabels],

    };
    //Department wise filter bar options
    const userFeedbackBaroptions = {
        plugins: {
            title: {
                display: true,
                text: 'Filtered By User Feedback',
                color: `${theme === "WHITE" ? "grey" : "skyblue"}`,
                align: "start"
            },
            legend: {
                display: true,
                align: "start",
                labels: {
                    color: `${theme === "WHITE" ? "grey" : "skyblue"}`
                }
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`, font: {
                        size: 11
                    }
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "skyblue"}`,
                    min: 0,
                    stepSize: 30
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
        },
    };


    function exportDataIntoExcel() {
        try {

            swal({
                // title: "Are you sure?",
                text: "Are you sure you want to export?",
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    try {
                        dispatch(showLoader());
                        const serverResponse = await officeCommunication.exportDataDepartmentWiseComplaintsCount();
                        if (serverResponse?.data?.status === "FAILED") {
                            swal({ text: "Failed to export data in excel", icon: "warning" });
                        } else {
                            //Create a Blob from the PDF Stream
                            const file = new Blob([serverResponse.data], {
                                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            });
                            FileSaver.saveAs(file, "departmentWiseComplaintCount.xlsx");
                        }
                        dispatch(hideLoader());
                    } catch (error) {
                        console.log("error", error.response.data)
                        dispatch(hideLoader());
                        swal({ text: error.message, icon: "warning" });
                    }


                } else {
                    return;
                }
            });
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }


    function exportSubDepartmentWiseComplaintsCount() {
        try {
            swal({
                // title: "Are you sure?",
                text: "Are you sure you want to export?",
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    try {
                        dispatch(showLoader());
                        const serverResponse = await officeCommunication.exportSubDepartmentWiseComplaintsCount(labelData.filterDepartmentId);
                        if (serverResponse?.data?.status === "FAILED") {
                            swal({ text: "Failed to export data in excel", icon: "warning" });
                        } else {
                            //Create a Blob from the PDF Stream
                            const file = new Blob([serverResponse.data], {
                                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            });
                            FileSaver.saveAs(file, "SubdepartmentWiseComplaintCount.xlsx");
                        }
                        dispatch(hideLoader());
                    } catch (error) {
                        dispatch(hideLoader());
                        swal({ text: error.message, icon: "warning" });
                    }


                } else {
                    return;
                }
            });
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }

    useEffect(() => {
        fetchAllDepartmentGraphData();
    }, []);

    //Get the data as per date filter
    useEffect(() => {
        if (!([undefined, null, ""].includes(labelData?.startDate)) && !([undefined, null, ""].includes(labelData?.endDate))) {
            if (selectBoxValues?.filterBy === "By_Department") {
                fetchAllDepartmentGraphData();
            } else if (selectBoxValues?.filterBy === "By_Sub_Department") {
                fetchSubDepartmentGraphData(labelData.filterDepartmentId);
            } else if (selectBoxValues?.filterBy === "By_Status") {
                fetchAllStatusGraphData();
            } else if (selectBoxValues?.filterBy === "By_User_Feedback") {
                fetchUserSatisfactionGraphData();
            }
            else {
                fetchAllDepartmentGraphData();
            }
        }
    }, [labelData.startDate, labelData.endDate]);

    useEffect(() => {
        if (labelData.showComplaintList) {
            scrollToComplaintList();
        }
    }, [labelData.showComplaintList])

    return (
        <div className="panel department_report">
            <div className="lookups_search_header report_header">
                <div className="row">
                    <div className="col-lg-1 col-md-1">
                        <H3>Reports</H3>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-lg-0 mb-md-3">
                        <div className="input_field ms-2 me-2">
                            <select className="form-select" onChange={(event) => { graphFilter(event.target.value); setSelectBoxValues({ ...selectBoxValues, filterBy: event.target.value, }); setLabelData({ filterDepartmentId: "" }) }}>
                                <option value="">Filter By</option>
                                <option value="By_Department">By Department</option>
                                <option value="By_Sub_Department">By Sub Department</option>
                                <option value="By_Status">By Status</option>
                                <option value="By_Aging">By Aging</option>
                                <option value="By_User_Feedback">By User Feedback</option>
                            </select>
                        </div>
                    </div>
                    {selectBoxValues.filterBy === "By_Aging" &&
                        <div className="col-lg-2 col-md-3">
                            <div className="input_field">
                                <select className="form-select" onChange={(event) => { agingFilter(event.target.value); setSelectBoxValues({ ...selectBoxValues, agingType: event.target.value }) }}>
                                    <option value="creationDate">Creation Date</option>
                                    <option value="lastUpdatedDate">Last Updated Date</option>
                                </select>
                            </div>
                        </div>
                    }
                    {selectBoxValues.filterBy === "By_Sub_Department" &&
                        <div className="col-lg-2 col-md-3">
                            <div className="input_field">
                                <select className="form-select" onChange={(event) => { fetchSubDepartmentGraphData(event.target.value); setLabelData({ ...labelData, filterDepartmentId: event.target.value }) }}>
                                    <option value="">select department</option>
                                    {departments?.map((departmentDetails, index) => {
                                        return (
                                            <option value={departmentDetails._id} key={index}>{departmentDetails.departmentName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    }
                    {selectBoxValues.filterBy !== "By_Aging" ?
                        <>
                            <div className="col-lg-2 col-md-4">
                                <div className="date_filter">
                                    <H5>From Date:</H5>
                                    <input type="date" value={labelData.startDate} onChange={(e) => setLabelData({ ...labelData, startDate: e.target.value })} className="form-control" style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4">
                                <div className="date_filter">
                                    <H5>To Date:</H5>
                                    <input type="date" value={labelData.endDate} onChange={(e) => setLabelData({ ...labelData, endDate: e.target.value })} className="form-control" style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="col-lg-3 col-md-4"></div>
                            <div className="col-lg-3 col-md-4"></div>
                        </>
                    }
                </div>
                <div className="report_button_group">
                    {
                        [null, undefined, "", "By_Department"].includes(selectBoxValues?.filterBy)
                            ?
                            <Button name="Download Report" onClick={exportDataIntoExcel} icon={<i className="fa-regular fa-file-lines"></i>} />
                            :

                            <></>
                    }
                    {
                        (selectBoxValues?.filterBy === "By_Sub_Department") ?
                            <Button name="Download Report" onClick={exportSubDepartmentWiseComplaintsCount} icon={<i className="fa-regular fa-file-lines"></i>} />
                            :
                            <></>
                    }
                    <Button name="Print" onClick={printSelectedGraph} icon={<i className="fa-solid fa-print"></i>} />
                </div>

            </div>

            <div className="grpah_section">
                {loader === true ?
                    <Loader /> : <>
                        <div className="bar_graph_main" ref={printGraph}>
                            {((selectBoxValues.filterBy === "") || (selectBoxValues.filterBy === "By_Department")) &&
                                <div style={{ minWidth: (departmentData?.departments?.length * 120) }}>
                                    <Bar ref={chartRef} options={departmentBaroptions} data={departmentGraphData} onClick={getGraphData} />
                                </div>
                            }
                            {selectBoxValues.filterBy === "By_Status" &&
                                <Bar ref={chartRef} options={statusBaroptions} data={statusWiseGraphData} onClick={getGraphData} />
                            }
                            {selectBoxValues.filterBy === "By_Aging" &&
                                <Bar ref={chartRef} options={agingBaroptions} data={agingGraph} onClick={getGraphData} />
                            }
                            {selectBoxValues.filterBy === "By_User_Feedback" &&
                                <div style={{ minWidth: (userSatisfactionGraphData?.departments?.length * 120) }}>
                                    <Bar ref={chartRef} options={userFeedbackBaroptions} data={userFeedbackGraphData} onClick={getGraphData} />
                                </div>
                            }
                            {
                                selectBoxValues.filterBy === "By_Sub_Department" &&
                                <div style={{ minWidth: (subDepartmentData?.subDepartments?.length * 120) }}>
                                    <Bar ref={chartRef} options={subDepartmentBarOptions} data={subDepartmentGraphData} onClick={getGraphData} />
                                </div>
                            }
                        </div>
                    </>}
            </div>
            {labelData.showComplaintList &&
                <div ref={scrollToList}>
                    <StatuswiseComplaintListModal labelData={labelData} setLabelData={setLabelData} />
                </div>
            }
        </div>
    )
}

export default Reports