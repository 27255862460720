
import React from 'react'
import { useSelector } from 'react-redux';

const H2Public = ({ whiteThemeColor, darkThemeColor, children }) => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier.fontSize);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
                <h2 style={{ fontSize: `${48 * fontSizeMultiplier / 100}px`, color: `${darkWhiteThemeToggle === "WHITE" ? whiteThemeColor = "#394D61" : darkThemeColor = "#FFF"}` }}>{children}</h2>

    )
}

export default H2Public