import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import H3 from '../../../../text-components/H3';
import useValidator from "../../../../utilities/useValidator";
import InputField from '../../../../common-components/InputField';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from '../../../../utilities/Loader';

const CreateDepartment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);

    const [validator, showValidationMessage] = useValidator();
    //get all input box values
    const [data, setData] = useState(
        {
            department: "",
            location: ""
        }
    )

    //Create Department
    const createDepartment = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            dispatch(showLoader());
            const dataToSend = {
                departmentName: data.department,
                location: data.location
            }
            const serverResponse = await officeCommunication.createDepartment(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideLoader());
                navigate("/dashboard/department-management")
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }

    }

    return (
        <div className="dashboard_form_section">
            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                    <div className="from_title">
                        <H3>Create Department</H3>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <InputField type="text" name="department" value={data.department} onChange={(event) => setData({ ...data, department: event.target.value  })} placeholder="Enter Department Name" />
                            {validator.message("department", data.department, "required", {
                                messages: {
                                    required: "Department is required",
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <InputField type="text" name="location" value={data.location} onChange={(event) => setData({ ...data, location: event.target.value  })} placeholder="Enter Location" />
                            {validator.message("location", data.location, "required", {
                                messages: {
                                    required: "Location is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="button_group">
                        <button className="button" onClick={() => createDepartment()}>Create</button>
                        <button className="button" onClick={() => navigate("/dashboard/department-management")}>Cancel</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default CreateDepartment