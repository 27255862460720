import { createSlice } from "@reduxjs/toolkit";

const scrollToTop = createSlice({
    name: "scrollToTopSlice",
    initialState: {
        scroll: "default",
    },
    reducers: {
        scrollTop: (state, action) => {
            state.scroll = action.payload;
            return state
        }
    }
});

export const { scrollTop } = scrollToTop.actions;
export default scrollToTop.reducer;