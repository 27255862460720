import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import H3 from '../../../../text-components/H3';
import useValidator from "../../../../utilities/useValidator";
import InputField from '../../../../common-components/InputField';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from '../../../../utilities/Loader';

const UpdateUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const [validator, showValidationMessage] = useValidator();
    //get query params
    const [searchUrl] = useSearchParams();
    const [departments, setDepartments] = useState([]);
    const [subDepartments, setSubDepartments] = useState([]);
    const [posts, setPosts] = useState([]);

    //get all input box values
    const [userDetails, setUserDetails] = useState(
        {
            userName: "",
            email: "",
            userType: "",
            departmentName: "",
            subDepartmentName: "",
            postName: "",
            subDepartmentId: "",
            departmentId: "",
            postId: "",
            profileUrl: "",

        }
    );

    const [file, setFile] = useState("");


    //fetch Department details on initial load
    async function fetchDepartment() {
        try {
            dispatch(showLoader());
            //call API 
            const serverResponse = await officeCommunication.getDepartments();
            if (serverResponse.data.status === "SUCCESS") {
                setDepartments(serverResponse.data.department);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setDepartments([]);
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    };

    //get department wise subdepartment
    const getDepartmentWiseSubDepartment = async (departmentId) => {
        try {
            if (departmentId === "") {
                return;
            } else {

                const serverResponse = await officeCommunication.getSubDepartmentByDepartment(departmentId);
                if (serverResponse?.data?.status === "SUCCESS") {
                    setSubDepartments(serverResponse?.data?.subdepartment);
                } else if (serverResponse?.data?.status === "JWT_INVALID") {
                    navigate("/login");
                    swal({ text: serverResponse?.data?.message, icon: "warning" });
                } else {
                    setSubDepartments([]);
                    swal({ text: serverResponse?.data?.message, icon: "warning" });
                }
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    };


    //get sub department wise post
    const getSubDepartmentWisePost = async (subDepartmentId) => {
        try {
            if (subDepartmentId === "") {
                return;
            } else {
                const serverResponse = await officeCommunication.getPostBySubDepartment(subDepartmentId);
                if (serverResponse?.data?.status === "SUCCESS") {
                    setPosts(serverResponse?.data?.post);
                } else if (serverResponse?.data?.status === "JWT_INVALID") {
                    navigate("/login");
                    swal({ text: serverResponse?.data?.message, icon: "warning" });
                } else {
                    setPosts([]);
                    swal({ text: serverResponse?.data?.message, icon: "warning" });
                }
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    };


    //get user details on initial load
    const getUserDetails = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getUserByUserId(searchUrl.get("userId"));
            if (serverResponse?.data?.status === "SUCCESS") {
                setUserDetails(serverResponse?.data?.usersDetails);
                if (serverResponse?.data?.usersDetails.userType.toLowerCase() === "department user") {
                    getDepartmentWiseSubDepartment(serverResponse?.data?.usersDetails.departmentId);
                    getSubDepartmentWisePost(serverResponse?.data?.usersDetails?.subDepartmentId)
                }
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setUserDetails({});
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };

    const previewFile = async (fileUrl) => {
        try {
            if (typeof userDetails.profileUrl === "string") {
                const serverResponse = await officeCommunication.getImage(userDetails.profileUrl, searchUrl.get("userId"));
                //Create a Blob from the PDF Stream
                const file = new Blob([serverResponse.data], {
                    // type: "application/pdf"
                    type: "image/jpg"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
            } else {
                const previewDocument = URL.createObjectURL(userDetails.profileUrl);
                window.open(previewDocument);
            }

        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Update User 
    const updateUser = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            let isFileAttached = false;
            const formData = new FormData();
            if (userDetails.profileUrl && typeof userDetails.profileUrl === "object") {
                formData.append("profile", userDetails.profileUrl)
                isFileAttached = true;
            }

            const dataToSend = {
                userName: userDetails?.userName,
                userId: searchUrl.get("userId"),
                mobileNumber: userDetails?.mobileNumber,
                email: userDetails?.email,
                password: userDetails?.password,
                userType: userDetails?.userType,
            }

            if (userDetails.userType === "department user") {
                dataToSend["departmentName"] = userDetails?.departmentName;
                dataToSend["subDepartmentName"] = userDetails?.subDepartmentName;
                dataToSend["postName"] = userDetails?.postName;
                dataToSend["postId"] = userDetails?.postId;
                dataToSend["departmentId"] = userDetails?.departmentId;
                dataToSend["subDepartmentId"] = userDetails?.subDepartmentId;
                dataToSend["level"] = userDetails?.level;
            };


            const serverResponse = await officeCommunication.updateOfficeUser(formData, dataToSend, isFileAttached);
            if (serverResponse?.data?.status === "SUCCESS") {
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideLoader());
                navigate("/dashboard/user-management")
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }


        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });

        }
    }

    useEffect(() => {
        getUserDetails();
        fetchDepartment();
    }, []);
    return (
        <div className="dashboard_form_section">
            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form user_management_form" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                    <div className="from_title">
                        <H3>Update User</H3>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 input_field">
                            <InputField type="text" name="userName" value={userDetails.userName} onChange={(event) => setUserDetails({ ...userDetails, userName: event.target.value  })} placeholder="Enter User Name" />
                            {validator.message("userName", userDetails.userName, "required", {
                                messages: {
                                    required: "UserName is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-6 col-md-6 input_field">
                            <InputField type="number" name="mobileNumber" value={userDetails?.mobileNumber} onChange={(event) => setUserDetails({ ...userDetails, mobileNumber: event.target.value })} placeholder="Enter Mobile Number" />
                            {validator.message("mobileNumber", userDetails?.mobileNumber, "required|numeric", {
                                messages: {
                                    required: "Mobile Number is required"
                                }
                            })}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 input_field">
                            <InputField type="email" name="email" value={userDetails.email} onChange={(event) => setUserDetails({ ...userDetails, email: event.target.value })} placeholder="Enter Email" />
                            {validator.message("email", userDetails.email, "required|email", {
                                messages: {
                                    required: "Email is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-6 col-md-6 input_field file_box">
                            <input type="file" name="file" id="file" onChange={(event) => { setUserDetails({ ...userDetails, profileUrl: event.target.files[0] }); setFile(event.target.files[0]) }} className="form-control d-none" />
                            <div className="profile_pic">
                                <div className="choose_file">
                                    <label for="file">Choose File</label>
                                </div>
                                <div className="file_label">
                                    <label for="file">{file === "" ? "Change Profile Picture" : `${file?.name}`}</label>
                                </div>
                            </div>
                            {
                                userDetails?.profileUrl &&
                                <div className='attachment_icon' onClick={() => previewFile()} >
                                    <i className="fa-solid fa-paperclip"></i>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 input_field">
                            <select name="userType" onChange={(event) => setUserDetails({ ...userDetails, userType: event.target.value })} className="form-select">
                                <option value="">Select User Type</option>
                                <option value="department user" selected={userDetails.userType === "department user" ? true : false}>Department User</option>
                                <option value="customer care" selected={userDetails.userType === "customer care" ? true : false}>Customer Care</option>
                            </select>
                            {validator.message("userType", userDetails.userType, "required", {
                                messages: {
                                    required: "User Type is required"
                                }
                            })}
                        </div>
                        {userDetails.userType.toLocaleLowerCase() === "department user" &&
                            <div className="col-lg-6 col-md-6 input_field">
                                <select name="department" onChange={(event) => { setUserDetails({ ...userDetails, departmentName: JSON.parse(event.target.value).departmentName, departmentId: JSON.parse(event.target.value)._id }); getDepartmentWiseSubDepartment(JSON.parse(event.target.value)._id) }} className="form-select">
                                    <option value={JSON.stringify({ _id: "", departmentName: "" })}>Select Department</option>
                                    {departments.map((department, index) => {
                                        return (

                                            <option value={JSON.stringify(department)} selected={department._id === userDetails.departmentId}>{department.departmentName}</option>
                                        )
                                    })}
                                </select>
                                {validator.message("department", userDetails.departmentName, "required", {
                                    messages: {
                                        required: "Department is required"
                                    }
                                })}
                            </div>
                        }
                    </div>
                    {userDetails.userType.toLocaleLowerCase() === "department user" &&
                        <div className="row">
                            <div className="col-lg-6 col-md-6 input_field">
                                <select name="subDepartment" onChange={(event) => { setUserDetails({ ...userDetails, subDepartmentName: JSON.parse(event.target.value).subDepartmentName, subDepartmentId: JSON.parse(event.target.value)._id }); getSubDepartmentWisePost(JSON.parse(event.target.value)._id) }} className="form-select">
                                    <option value={JSON.stringify({ _id: "", subDepartmentName: "" })}>Select Sub Department</option>
                                    {subDepartments.map((subDepartment, index) => {
                                        return (
                                            <option value={JSON.stringify(subDepartment)} selected={subDepartment._id === userDetails?.subDepartmentId} key={index}>{subDepartment.subDepartmentName}</option>
                                        )
                                    })}
                                </select>
                                {validator.message("subDepartment", userDetails.subDepartmentName, "required", {
                                    messages: {
                                        required: "Sub Department is required"
                                    }
                                })}
                            </div>
                            <div className="col-lg-6 col-md-6 input_field">
                                <select name="post" onChange={(event) => setUserDetails({ ...userDetails, postName: JSON.parse(event.target.value).postName, postId: JSON.parse(event.target.value)._id, level: JSON.parse(event.target.value).level })} className="form-select">
                                    <option value={JSON.stringify({ _id: "", postName: "", level: "" })}>Select post</option>
                                    {posts.map((postDetails, index) => {
                                        return (
                                            <option value={JSON.stringify(postDetails)} selected={postDetails._id === userDetails?.postId} key={index}>{postDetails.postName} ({postDetails.level})</option>
                                        )
                                    })}
                                </select>
                                {validator.message("post", userDetails.postName, "required", {
                                    messages: {
                                        required: "Post is required"
                                    }
                                })}
                            </div>
                        </div>
                    }
                    <div className="button_group">
                        <button className="button" onClick={() => updateUser()}>Update</button>
                        <button className="button" onClick={() => navigate("/dashboard/user-management")}>Cancel</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default UpdateUser;