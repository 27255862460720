import swal from "sweetalert";
import axios from "axios";


const nodeEnvironment = process.env.REACT_APP_NODE_ENV;
const developmentURL = "http://localhost:3013";
const serverUrl = process.env.REACT_APP_NODE_URL;
const machine_IP = "http://192.168.29.169:3013";

function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if ("vandeMataramToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}

function getServerUrl() {

    if (nodeEnvironment === "development") {
        return developmentURL;
    }

    if (nodeEnvironment === "machine_IP") {
        return machine_IP;
    }

    if (nodeEnvironment === "server") {
        return serverUrl;
    }

    return serverUrl;
}

export const officeCommunication = {
    //********** Login **********
    login: (userData) => {
        try {
            return axios.post(`${getServerUrl()}/user/login`, userData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    resetPassword: (userData) => {
        try {
            return axios.post(`${getServerUrl()}/user/reset-password`, userData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    forgetPassword: (userData) => {
        try {
            return axios.post(`${getServerUrl()}/user/forget-password`, userData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    //?------------------------Department API----------------------------------
    getDepartments: () => {
        try {
            return axios.get(`${getServerUrl()}/department/get-all-department`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getAccessWiseDepartmentList: () => {
        try {
            return axios.get(`${getServerUrl()}/department/get-user-access-wise-departments`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getDepartmentById: (departmentId) => {
        try {
            return axios.post(`${getServerUrl()}/department/get-department-by-id`, { departmentId: departmentId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    updateDepartment: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/department/update-department`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    createDepartment: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/department/create-department`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    //?----------------------Sub department API-----------------------------------
    getSubDepartments: (selectedPage) => {
        try {
            return axios.get(`${getServerUrl()}/sub-department/get-all-subdepartment?page=${selectedPage}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getSubDepartmentById: (subDepartmentId) => {
        try {
            return axios.post(`${getServerUrl()}/sub-department/get-sub-department-by-id`, { subDepartmentId: subDepartmentId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getSubDepartmentByDepartment: (departmentId) => {
        try {
            return axios.post(`${getServerUrl()}/sub-department/get-department-wise-subdepartment`, { departmentId: departmentId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    updateSubDepartment: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/sub-department/update-sub-department`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    createSubDepartment: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/sub-department/create-sub-department`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getSubDepartmentBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/sub-department/search-subDepartment`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    //?----------------------------------Post management--------------------------------------------
    getAllPost: (selectedPage) => {
        try {
            return axios.get(`${getServerUrl()}/post-department/get-all-post?page=${selectedPage}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getPostById: (postId) => {
        try {
            return axios.post(`${getServerUrl()}/post-department/get-post-by-id`, { postId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getPostBySubDepartment: (subDepartmentId) => {
        try {
            return axios.post(`${getServerUrl()}/post-department/get-subdepartment-wise-post`, { subDepartmentId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    createPost: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/post-department/create-post`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    updatePost: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/post-department/update-post`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getPostNameBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/post-department/search-post`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    //?-----------------------------------------User Management---------------------------------
    getAllUsers: (selectedPage) => {
        try {
            return axios.get(`${getServerUrl()}/user-department/get-user-list?page=${selectedPage}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getUserBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user-department/search-user`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getUserProfileImage: () => {
        try {
            return axios.get(`${getServerUrl()}/user-department/get-user-profile-image`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportUserList: (searchString) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-user-list`, { searchString }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportSuperUserList: (searchString) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-super-user-list`, { searchString }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getImage: (fileUrl, userId) => {
        try {
            return axios.get(`${getServerUrl()}/user-department/get-image?fileUrl=${fileUrl}&userId=${userId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getUserByUserId: (userId) => {
        try {
            return axios.post(`${getServerUrl()}/user-department/get-user-by-id`, { userId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },

    createOfficeUser: (formData) => {
        try {
            return axios.post(`${getServerUrl()}/user-department/create-user`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });

        }
    },

    updateOfficeUser: (formData, dataToSend, isFileAttached) => {
        try {
            return axios.post(`${getServerUrl()}/user-department/update-user?isFileAttached=${isFileAttached}`, formData, {
                headers: {
                    "Content-Type": isFileAttached ? "multipart/form-data" : "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                    "userData": JSON.stringify(dataToSend)
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });

        }
    },

    editProfile: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/user-department/edit-user-profile`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });

        }
    },

    updateProfileImg: (formData) => {
        try {
            return axios.post(`${getServerUrl()}/user-department/edit-profile-image`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${getCookie()}`,

                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });

        }
    },


    //?-----------------------------Complaint lookUp---------------------------------------------
    getComplaintByStatus: (complaintStatus, selectedPage, startDate, endDate) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-status-wise-complaint?page=${selectedPage}`, { complaintStatus, startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getComplaintById: (complaintId) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-complaint-by-id`, { complaintId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportComplaintLookupComplaints: (dataToSend) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-subdepartment-wise-complaint`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },

    //-----------------------Customer care-------------------------
    sendOTP: (mobileNumber) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/mobile-verification`, { mobileNumber }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    verifyOTP: (mobileNumber, otp) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/otp-verify`, { mobileNumber, otp }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    raiseComplaint: (isFileAttached, formData) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/raise-complaint?isFileAttached=${isFileAttached}`, formData, {
                headers: {
                    "Content-Type": isFileAttached ? "multipart/form-data" : "application/json",
                    "Authorization": `Bearer ${getCookie()}`,
                    // "complaintData": JSON.stringify(dataToSend)
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getAllNewAndReopenComplaint: (selectedPage) => {
        try {
            return axios.get(`${getServerUrl()}/complaint-management/get-all-new-and-reopen-complaint-departmentWise?page=${selectedPage}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            })
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },

    getAllResolvedComplaints: () => {
        try {
            return axios.get(`${getServerUrl()}/complaint-management/get-all-resolved-complaint`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    previewComplaintAttachment: (fileUrl) => {
        try {
            return axios.get(`${getServerUrl()}/public-portal/get-file?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"

            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },

    //-------------------Get graph Data for Department User----------------------------
    getAllDepartmentPieChartDetails: (startDate, endDate) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-department-pie-chart-details`, { startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getDepartmentComplaintByStatus: (complaintStatus, startDate, endDate, selectedPage) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-complaint-by-status-for-department?page=${selectedPage}`, { complaintStatus, startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    //-------------------Get graph Data for Admin & Customer Care----------------------------
    //Get Graph data
    getAllDepartmentGraphDetails: (startDate, endDate) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-department-wise-graph-data`, { startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getAllStatusGraphDetails: (startDate, endDate) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-status-wise-graph`, { startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getAgingGraphDetails: (byFilter) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-aging-graph-data`, { byFilter }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getUserSatisfactionGraphDetails: (startDate, endDate) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-user-satisfaction-data`, { startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },

    //Get Complaint list
    getDepartmentComplaintsByDepartmentId: (departmentId, status, startDate, endDate, selectedPage) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-department-complaint-by-status?page=${selectedPage}`, { departmentId, complaintStatus: status, startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",

                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getComplaintListByStatus: (complaintStatus, startDate, endDate, selectedPage) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-status-wise-complaint?page=${selectedPage}`, { complaintStatus, startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",

                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getComplaintListByAging: (complaintStatus, dataBetween, byFilter, selectedPage) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-complaint-by-aging?page=${selectedPage}`, { complaintStatus, dataBetween, byFilter }, {
                headers: {
                    "Content-Type": "application/json",

                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getComplaintListByRating: (rating, departmentId, startDate, endDate, selectedPage) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-department-complaint-by-rating?page=${selectedPage}`, { rating, departmentId, startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",

                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },

    //---------------------Department User-----------------------------------
    resolveComplaint: (isFileAttached, formData) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/resolve-complaint?isFileAttached=${isFileAttached}`, formData, {
                headers: {
                    "Content-Type": isFileAttached ? "multipart/form-data" : "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    closeComplaint: (complaintData) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/close-complaint`, complaintData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    assignComplaint: (isFileAttached, formData) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/assign-complaint?isFileAttached=${isFileAttached}`, formData, {
                headers: {
                    "Content-Type": isFileAttached ? "multipart/form-data" : "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    reopenComplaint: (complaintId, reason) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/re-open-complaint`, { complaintId, reason }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    rejectComplaint: (complaintId, comment) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/reject-complaint`, { complaintId, comment }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    //Export Graph Data to Excell
    exportGraphDataByDepartment: (departmentId, complaintStatus) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-department-complaint-by-status`, { departmentId, complaintStatus }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportGraphDataByStatus: (complaintStatus) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-status-wise-complaint`, { complaintStatus }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportGraphDataByAging: (complaintStatus, dataBetween, byFilter) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-complaint-by-aging`, { complaintStatus, dataBetween, byFilter }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportGraphDataByRating: (rating, departmentId) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-department-complaint-by-rating`, { rating, departmentId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportGraphDataForDepartmentUser: (complaintStatus) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-complaint-by-status-for-department`, { complaintStatus }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportDataAllComplaints: (complaintStatus) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-status-wise-complaint`, { complaintStatus }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportDataDepartmentWiseComplaintsCount: () => {
        try {
            return axios.get(`${getServerUrl()}/data-to-export/export-department-wise-complaint-count`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportSubDepartmentWiseComplaintsCount: (departmentId) => {
        try {
            return axios.get(`${getServerUrl()}/data-to-export/export-sub-department-wise-complaint-count?departmentId=${departmentId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    //-----------Search Filter
    getStackedBarDepartmentIdwiseComplaintsBySearch: (departmentId, complaintStatus, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/search-department-complaint-by-status`, { departmentId, complaintStatus, searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getStackedBarStatuswiseComplaintsBySearch: (complaintStatus, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/search-status-wise-complaint`, { complaintStatus, searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getStackedBarAgingwiseComplaintsBySearch: (complaintStatus, dataBetween, byFilter, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/search-complaint-by-aging`, { complaintStatus, dataBetween, byFilter, searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getStackedBarRatingwiseComplaintsBySearch: (rating, departmentId, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/search-department-complaint-by-rating`, { rating, departmentId, searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getPieChartComplaintsBySearch: (complaintStatus, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/search-complaint-by-status-for-department`, { complaintStatus, searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getResolvedComplaintsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/search-resolved-complaint`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getLookupComplaintsBySearch: (complaintStatus, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/search-status-wise-complaint`, { complaintStatus, searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getComplaintsForDepartmentUserBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/search-all-new-and-reopen-complaint-departmentWise`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getEscalatedComplaintsBySearch: (departmentId, searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-escalated-complaint-by-search`, { departmentId, searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getCountNotification: () => {
        try {
            return axios.get(`${getServerUrl()}/complaint-management/get-notification-counts`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getAdminNotification: () => {
        try {
            return axios.get(`${getServerUrl()}/complaint-management/get-notification`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    notificationMarkRead: (notificationId) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/mark-notification-as-seen`, { notificationId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },

    notificationDelete: (notificationId) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/delete-notification`, { notificationId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    //Escalated Complaints API
    getEscalatedComplaintsGraphData: () => {
        try {
            return axios.get(`${getServerUrl()}/complaint-management/get-escalated-complaint-graph`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getEscalatedComplaintsByDepartmentId: (departmentId, selectedPage) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-escalated-complaint`, { departmentId, selectedPage }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportEscalatedComplaints: () => {
        try {
            return axios.get(`${getServerUrl()}/data-to-export/export-get-escalated-complaint`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },

    // Super User API
    postwiseUsers: (departmentId, subDepartmentId, postId) => {
        try {
            return axios.post(`${getServerUrl()}/user-department/get-user-by-department-and-subDepartment`, { departmentId, subDepartmentId, postId }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });

        }
    },
    createSuperUser: (formData) => {
        try {
            return axios.post(`${getServerUrl()}/user-department/create-super-user`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });

        }
    },
    getAllSuperUsers: (page) => {
        try {
            return axios.get(`${getServerUrl()}/user-department/get-super-users?page=${page}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });

        }
    },

    updateSuperUser: (dataToSend, isFileAttached, profile) => {
        try {
            let formData = new FormData();
            if (isFileAttached) {
                formData.append("profile", profile);
                formData.append("userData", JSON.stringify(dataToSend));
            } else {
                formData = dataToSend;
            }
            return axios.post(`${getServerUrl()}/user-department/update-super-user?isFileAttached=${isFileAttached}`, formData, {
                headers: {
                    "Content-Type": isFileAttached ? "multipart/form-data" : "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });

        }
    },
    getSuperUserBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user-department/search-super-user`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });

        }
    },
    getSubDepartmentGraphDetails: (departmentId, startDate, endDate) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-sub-department-wise-graph-data`, { departmentId, startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getSubDepartmentComplaintsBySubDepartmentId: (subDepartmentId, status, startDate, endDate, selectedPage) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/get-sub-department-complaint-by-status?page=${selectedPage}`, { subDepartmentId, complaintStatus: status, startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",

                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    exportGraphDataBySubDepartment: (subDepartmentId, complaintStatus, startDate, endDate) => {
        try {
            return axios.post(`${getServerUrl()}/data-to-export/export-sub-department-complaint-by-status`, { subDepartmentId, complaintStatus, startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getStackedBarSubDepartmentIdWiseComplaintsBySearch: (subDepartmentId, complaintStatus, searchString, page, startDate, endDate) => {
        try {
            return axios.post(`${getServerUrl()}/complaint-management/search-sub-department-complaint-by-status`, { subDepartmentId, complaintStatus, searchString, page, startDate, endDate }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
}