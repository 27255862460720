import React, { useEffect, useState } from 'react'
import { publicCommunication } from "../../../services/publicCommunication";
import swal from 'sweetalert';
import { useSelector } from 'react-redux';

const ComplaintsCounter = () => {

    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [complaintsCounterArray, setComplaintsCounterArray] = useState([]);

    // get complaints counts & visitor counts
    async function fetchCounts() {
        try {
            const serverResponse = await publicCommunication.fetchCounts();
            if (serverResponse.data.status === "SUCCESS") {
                setComplaintsCounterArray(serverResponse?.data?.countsArray);
            } else {
                setComplaintsCounterArray([]);
            }
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    };

    useEffect(() => {
        fetchCounts();
    }, [])

    return (
        <div className="complaints_counter_section">
            <div className="row">
                {complaintsCounterArray?.map((counterDetail, index) => (
                    <div className="col-lg-3 col-md-3 col-6 mt-lg-0 mt-md-0 mt-1 mb-lg-0 mb-md-0 mb-1 column" key={index}>
                        <div className="complaints_counter_card" style={{ backgroundImage: `${theme === "WHITE" ? "linear-gradient(45deg, #fff, #E1ECF3)" : "linear-gradient(45deg, #fff, #CBCBCB)"}` }}>
                            <div className="percent">
                                <svg>
                                    <circle cx="70" cy="70" r="70"></circle>
                                    <circle cx="70" cy="70" r="70" style={{ strokeDashoffset: `${440 - (440 * `${counterDetail?.percentage}`) / 100}`, stroke: `${counterDetail?.color}` }}></circle>
                                </svg>
                                <div className="num">
                                    <h2>{counterDetail?.title}</h2>
                                </div>
                            </div>
                            <h2 className="text">{counterDetail?.count}</h2>
                        </div>
                    </div>
                ))}
            </div>

        </div >
    )
}

export default ComplaintsCounter