import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import H3 from '../../../../text-components/H3';
import H5 from '../../../../text-components/H5';
import HeaderText from '../../../../text-components/HeaderText';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from '../../../../utilities/Loader';
import * as FileSaver from "file-saver";
import ReactPaginate from 'react-paginate';


const UserList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [value, setValue] = useState("");
    const limit = process.env.REACT_APP_LIMIT

    //Onclick set current page
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage)
        if (value) {
            getUserBySearch(value, selectedPage + 1)
        } else {
            fetchUsers(selectedPage + 1);
        }

    };



    // onchange Search filter
    const searchFilter = async (searchString) => {
        if (searchString === "") {
            //call API 
            const serverResponse = await officeCommunication.getAllUsers();
            if (serverResponse.data.status === "SUCCESS") {
                setFilteredUsers(serverResponse.data.users);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredUsers([]);
                setPageCount(0);
            }
        } else {
            getUserBySearch(searchString)
        }
    }

    //fetch user list by searchValue 
    async function getUserBySearch(searchString, page) {
        try {
            // dispatch(showLoader());
            //call API 
            const serverResponse = await officeCommunication.getUserBySearch(searchString, page);
            if (serverResponse.data.status === "SUCCESS") {
                setFilteredUsers(serverResponse.data.users);
                setPageCount(serverResponse?.data?.totalPages);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredUsers([]);
                setPageCount(0);
                // dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }


    //fetch users details on initial load
    async function fetchUsers(selectedPage) {
        try {
            dispatch(showLoader());
            //call API 
            const serverResponse = await officeCommunication.getAllUsers(selectedPage);
            if (serverResponse.data.status === "SUCCESS") {
                setFilteredUsers(serverResponse.data.users);
                setPageCount(serverResponse?.data?.totalPages);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredUsers([]);
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }



    //Export data into excel
    const exportData = async () => {
        try {
            swal({
                // title: "Are you sure?",
                text: "Are you sure you want to export?",
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    dispatch(showLoader());
                    const serverResponse = await officeCommunication.exportUserList(value);
                    if (serverResponse.data) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([serverResponse.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        FileSaver.saveAs(file, "userList.xlsx");
                    } else {
                        swal({ text: "Failed to export data in excel", icon: "warning" });
                    }
                    dispatch(hideLoader());

                } else {
                    return;
                }
            });
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [])
    return (
        <div className="panel">
            {loader === true ?
                <Loader />
                : <>
                    <div className="search_header">
                        <H3>User Management</H3>
                        <div className="search_input">
                            <input type="text" className="form-control" placeholder="Search by user..." title="Search by User Name, User Type, Departement, Sub Departement, Post" onChange={(e) => { searchFilter(e.target.value); setValue(e.target.value) }} style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </div>
                        <button className="button" onClick={() => navigate("/dashboard/user-management/create-user")}>Create User</button>

                        <button className="button ms-1" onClick={() => exportData()}  > exportData<i className="fa-regular fa-file-lines" /></button>

                    </div>
                    <div className="table_main user_list_table">
                        <table>
                            <thead>
                                <tr>
                                    <th><HeaderText>Serial Number</HeaderText></th>
                                    <th><HeaderText>User Name</HeaderText></th>
                                    <th><HeaderText>UserId</HeaderText></th>
                                    <th><HeaderText>User Type</HeaderText></th>
                                    <th><HeaderText>Department</HeaderText></th>
                                    <th><HeaderText>Sub Department</HeaderText></th>
                                    <th><HeaderText>Post</HeaderText></th>
                                    <th><HeaderText>Level</HeaderText></th>
                                    <th><HeaderText>Mobile Number</HeaderText></th>
                                    <th><HeaderText>Date</HeaderText></th>
                                    <th><HeaderText>Action</HeaderText></th>
                                </tr>
                            </thead>

                            {
                                filteredUsers.length > 0 ?
                                    <>
                                        <tbody>
                                            {filteredUsers.map((user, index) => {
                                                return (
                                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }} key={index}>
                                                        <td><H5>{(limit * (currentPage)) + (index + 1)}</H5></td>
                                                        <td><H5>{user?.userName}</H5></td>
                                                        <td><H5>{user?.userId}</H5></td>
                                                        <td><H5>{user?.userType}</H5></td>
                                                        <td>{user?.departmentName ? <H5>{user.departmentName}</H5> : <H5>--</H5>}</td>
                                                        <td>{user?.subDepartmentName ? <H5>{user.subDepartmentName}</H5> : <H5>--</H5>}</td>
                                                        <td>{user?.postName ? <H5>{user.postName}</H5> : <H5>--</H5>}</td>
                                                        <td>{user?.level ? <H5>{user.level}</H5> : <H5>--</H5>}</td>
                                                        <td><H5>{user?.mobileNumber}</H5></td>
                                                        <td><H5>{new Date(user.creationDate).toLocaleDateString()}</H5></td>
                                                        <td onClick={() => navigate(`/dashboard/user-management/update-user?userId=${user.userId}`)}><i className="fa-solid fa-pen-to-square"></i></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </>
                                    :
                                    <tbody>
                                        <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}><td></td><td></td><td></td><td></td><td></td></tr>
                                        <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><H3>User Not Available</H3></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                            }


                        </table>
                    </div>
                    {pageCount > 1 &&
                        <div className='pagination_div' style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}` }}>
                            <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                forcePage={currentPage}
                                activeClassName={"active"} />
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default UserList;