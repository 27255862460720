import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import H3 from '../../../../text-components/H3';
import useValidator from "../../../../utilities/useValidator";
import InputField from '../../../../common-components/InputField';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from '../../../../utilities/Loader';

const UpdatePost = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);

    const [validator, showValidationMessage] = useValidator();
    //get query params
    const [searchUrl] = useSearchParams();
    //get all input box values
    const [postDetails, setPostDetails] = useState(
        {
            postName: "",
            departmentName: "",
            departmentId: "",
            subDepartmentName: "",
            subDepartmentId: "",
            level: ""
        }
    )

    const [departments, setDepartments] = useState([])
    const [subDepartments, setSubDepartments] = useState([]);


    //get department details on initial load
    const getDepartment = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getDepartments();
            if (serverResponse?.data?.status === "SUCCESS") {
                setDepartments(serverResponse?.data?.department);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setDepartments([]);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };

    //get department wise subdepartment
    const getDepartmentWiseSubDepartment = async (departmentId) => {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getSubDepartmentByDepartment(departmentId);
            if (serverResponse?.data?.status === "SUCCESS") {
                setSubDepartments(serverResponse?.data?.subdepartment);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setSubDepartments([]);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };

    //get Post details on initial load
    const getPostDetails = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getPostById(searchUrl.get("id"));
            if (serverResponse?.data?.status === "SUCCESS") {
                setPostDetails(serverResponse?.data?.post);
                getDepartmentWiseSubDepartment(serverResponse?.data?.post?.departmentId)
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setPostDetails({});
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };


    //Update Post 
    const updatePost = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            };
            dispatch(showLoader());

            const dataToSend = {
                postName: postDetails?.postName,
                postId: postDetails?._id,
                departmentName: postDetails?.departmentName,
                departmentId: postDetails?.departmentId,
                subDepartmentId: postDetails?.subDepartmentId,
                subDepartmentName: postDetails?.subDepartmentName,
                level: postDetails?.level
            };
            const serverResponse = await officeCommunication.updatePost(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                swal({ text: serverResponse?.data?.message, icon: "success" });
                navigate("/dashboard/post-name-management");
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }

    }

    useEffect(() => {
        getDepartment();
        getPostDetails();
    }, [])

    return (
        <div className="dashboard_form_section">
            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                    <div className="from_title">
                        <H3>Update Post</H3>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <InputField type="text" name="post" value={postDetails.postName} onChange={(event) => setPostDetails({ ...postDetails, postName: event.target.value  })} placeholder="Enter Post Name" />
                            {validator.message("post", postDetails.postName, "required", {
                                messages: {
                                    required: "Post is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <select name="department" onChange={(event) => { setPostDetails({ ...postDetails, departmentName: JSON.parse(event.target.value).departmentName, departmentId: JSON.parse(event.target.value)._id }); getDepartmentWiseSubDepartment(JSON.parse(event.target.value)._id) }} className="form-select">
                                <option value={JSON.stringify({ _id: "", departmentName: "" })}>Select Department</option>
                                {departments.map((department, index) => {
                                    return (
                                        <option value={JSON.stringify(department)} selected={department._id === postDetails.departmentId} key={index}>{department.departmentName}</option>
                                    )
                                })}
                            </select>
                            {validator.message("department", postDetails.departmentName, "required", {
                                messages: {
                                    required: "Department is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <select name="subDepartment" onChange={(event) => setPostDetails({ ...postDetails, subDepartmentName: JSON.parse(event.target.value).subDepartmentName, subDepartmentId: JSON.parse(event.target.value)._id })} className="form-select">
                                <option value={JSON.stringify({ _id: "", subDepartmentName: "" })}>Select Sub Department</option>
                                {subDepartments.map((subDepartment, index) => {
                                    return (
                                        <option value={JSON.stringify(subDepartment)} selected={subDepartment._id === postDetails?.subDepartmentId} key={index}>{subDepartment.subDepartmentName}</option>
                                    )
                                })}
                            </select>
                            {validator.message("subDepartment", postDetails.subDepartmentName, "required", {
                                messages: {
                                    required: "subDepartment is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <select name="level" onChange={(event) => setPostDetails({ ...postDetails, level: event.target.value })} className="form-select" >
                                <option value="">Select Level</option>
                                <option value="Nodal Officer" selected={postDetails.level === "Nodal Officer" ? true : false}>Nodal Officer</option>
                                <option value="Sub HOD" selected={postDetails.level === "Sub HOD" ? true : false}>Sub HOD</option>
                                <option value="HOD" selected={postDetails.level === "HOD" ? true : false}>HOD</option>
                            </select>
                            {validator.message("level", postDetails.level, "required", {
                                messages: {
                                    required: "Level is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="button_group">
                        <button className="button" onClick={() => updatePost()}>Update</button>
                        <button className="button" onClick={() => navigate("/dashboard/post-name-management")}>Cancel</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default UpdatePost;