import React, { useEffect, useRef, useState } from 'react';
import H3 from '../../../../text-components/H3';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import swal from 'sweetalert';
import { officeCommunication } from '../../../../services/officeCommunication';
import { useNavigate } from 'react-router-dom';
import AccelatedComplaintListModal from './AccelatedComplaintListModal';
import { hideLoader, showLoader } from '../../../../redux-store/loaderReducer';
import Loader from '../../../../utilities/Loader';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
ChartJS.defaults.set('plugins.datalabels', {
    color: '#FFF'
});


const AccelatedComplaints = () => {
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scrollToList = useRef();
    const printGraph = useRef();
    const chartRef = useRef();
    const [departmentData, setDepartmentData] = useState();

    // click on Bar data will be set in state
    const [labelData, setLabelData] = useState({
        departmentId: "",
        showComplaintList: false,
    });

    //Scroll to last Complaint List
    const scrollToComplaintList = () => {
        scrollToList?.current?.lastChild?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }


    //Get Graph data
    const getGraphData = (event) => {
        //Index Number to get selected department
        const indexNumber = getElementAtEvent(chartRef.current, event)[0].index;
        //Dataset Index Number to get selected department Status
        setLabelData({ ...labelData, departmentId: departmentData.departments[indexNumber].departmentId, showComplaintList: true });
        scrollToComplaintList();
    }

    //Get All Department graph data
    const fetchEscalatedComplaintsGraphData = async () => {
        dispatch(showLoader());
        try {
            const responseFromServer = await officeCommunication.getEscalatedComplaintsGraphData();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDepartmentData(responseFromServer?.data?.graphData);
                dispatch(hideLoader());
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.status, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.status, icon: "warning" });
                setDepartmentData([]);
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    const escalatedComplaintsGraphData = {
        labels: departmentData?.departments?.map((ele) => { return ele?.departmentName }),
        datasets: [
            {
                data: departmentData?.counts,
                backgroundColor: [`${theme === "WHITE" ? "#25316D" : "#6678d4"}`],
                barThickness: 60,
            }
        ],

        plugins: [ChartDataLabels],

    };
    //Department wise filter bar options
    const escalatedComplaintsBaroptions = {
        plugins: {
            title: {
                display: false,
                text: 'Filtered By Department',
                color: `${theme === "WHITE" ? "grey" : "#ffffff"}`,
                align: "start",
            },
            legend: {
                display: false,
                align: "start",
                labels: {
                    color: `${theme === "WHITE" ? "grey" : "#ffffff"}`,
                },
            }

        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}`, font: {
                        size: 11
                    },
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}`,
                    min: 0,
                    stepSize: 5
                },
                grid: {
                    color: `${theme === "WHITE" ? "#DDDDDD" : "#707070"}`
                }
            },
        },
    };

    useEffect(() => {
        fetchEscalatedComplaintsGraphData();
    }, []);

    useEffect(() => {
        if (labelData.showComplaintList) {
            scrollToComplaintList();
        }
    }, [labelData.showComplaintList])

    return (
        <div className="panel department_report">
            <div className="lookups_search_header report_header">
                <div className="row">
                    <div className="col-12">
                        <H3>Escalated Complaints</H3>
                    </div>
                </div>

            </div>
            <div className="grpah_section">
                {loader === true ? <Loader /> : <>
                    <div className="bar_graph_main" ref={printGraph}>

                        <div style={{ minWidth: (departmentData?.departments?.length * 120) }}>
                            <Bar ref={chartRef} options={escalatedComplaintsBaroptions} data={escalatedComplaintsGraphData} onClick={getGraphData} />
                        </div>
                    </div>
                </>}

            </div>
            {labelData.showComplaintList &&
                <div ref={scrollToList}>
                    <AccelatedComplaintListModal labelData={labelData} setLabelData={setLabelData} />
                </div>
            }
        </div>
    )
}

export default AccelatedComplaints