import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

let decodeValue = null;
//**********************Helper Function ********************//
(function getcokkies() {
    // Split cookie string and get all individual valueName=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        // Decode the cookie value and return
        if ("vandeMataramToken" === cookiePair[0].trim()) {
            decodeValue = jwtDecode(cookiePair[1]);
            return decodeValue;
        }
    }
})();
//**********************Helper Function ********************//

const userDetails = createSlice({
    name: "userDetails",
    initialState: {
        email: decodeValue?.email ?? "",
        mobileNumber: decodeValue?.mobileNumber ?? "",
        departmentName: decodeValue?.departmentName ?? "", 
        departmentId: decodeValue?.departmentId ?? "",
        subDepartmentName: decodeValue?.subDepartmentName ?? "",
        subDepartmentId: decodeValue?.subDepartmentId ?? "",
        postName: decodeValue?.postName ?? "",
        level: decodeValue?.level ?? "",
        userType: decodeValue?.userType ?? "",
        name: decodeValue?.userName ?? "",
        userId: decodeValue?.userId ?? "",
    },
    reducers: {
        addUserDetails: (state, action) => {
            state.email = action.payload.userDetails.email;
            state.mobileNumber = action.payload.userDetails.mobileNumber;
            state.departmentName = action.payload.userDetails.departmentName;
            state.subDepartmentName = action.payload.userDetails.subDepartmentName;
            state.departmentId = action.payload.userDetails.departmentId;
            state.subDepartmentId = action.payload.userDetails.subDepartmentId;
            state.postName = action.payload.userDetails.postName;
            state.level = action.payload.userDetails.level;
            state.name = action.payload.userDetails.name;
            state.userId = action.payload.userDetails.userId;
            state.userType = action.payload.userDetails.userType;
            document.cookie = `vandeMataramToken=${action.payload.token};path=/`;
        },
        removeUserDetails: (state, action) => {
            state.name = "";
            state.userType = "";
            state.userId = "";
            document.cookie = "vandeMataramToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
    },
});

export const { addUserDetails, removeUserDetails } = userDetails.actions;
export default userDetails.reducer;
