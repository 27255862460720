import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { removeUserDetails } from "../redux-store/user-reducer";

const ProfileNav = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = async () => {
        swal({
            // title: "Are you sure?",
            text: `Are you sure?`,
            icon: "warning",
            buttons: ["No, cancel it!", "Yes, I am sure!"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                dispatch(removeUserDetails());
                navigate("/login")

            } else {
                return;
            }
        });

    }
    return (
        <div className="profile_nav">
            <NavLink to="/dashboard/profile">
                <div className="tab">
                    <div className="icon"><i className="fa-solid fa-user-tie"></i></div>
                    <div className="title"><span>My Account</span></div>
                </div>
            </NavLink>
            <div className="tab" onClick={() => logout()}>
                <div className="icon"><i className="fa-solid fa-right-from-bracket"></i></div>
                <div className="title"><span>Logout</span></div>
            </div>
        </div>
    )
}

export default ProfileNav