import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useValidator from "../../utilities/useValidator";
import swal from 'sweetalert';
import { officeCommunication } from '../../services/officeCommunication';
import { showLoader, hideLoader } from '../../redux-store/loaderReducer';
import { addUserDetails } from '../../redux-store/user-reducer';
import Loader from '../../utilities/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader.showHideLoader);
  const [validator, showValidationMessage] = useValidator();
  const [passwordInputType, setPasswordInputType] = useState(true)

  //Get Data from Input box
  const [data, setData] = useState(
    {
      userId: "",
      password: ""
    }
  )

  //User Login
  const login = async () => {
    try {
      //check Validation 
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }
      dispatch(showLoader());
      const dataToSend = {
        userId: data.userId.trim(),
        password: data.password.trim()
      }
      //call API 
      const serverResponse = await officeCommunication.login(dataToSend);
      if (serverResponse.data.status === "SUCCESS") {
        dispatch(addUserDetails({ userDetails: serverResponse.data.userDetails, token: serverResponse.data.jwtToken }));
        //navigate user according to user type
        if (serverResponse?.data?.userDetails?.userType.toLowerCase() === "admin") {
          navigate("/dashboard/department-management");
        } else if (serverResponse?.data?.userDetails?.userType.toLowerCase() === "customer care") {
          navigate("/dashboard/resolved-complaints");
        } else if (["department user", "super user"].includes(serverResponse?.data?.userDetails?.userType.toLowerCase())) {
          navigate("/dashboard/department-complaints");
        } else {
          navigate("/login");
        }
        swal({ text: serverResponse.data.message, icon: "success" });
        dispatch(hideLoader());
      } else {
        swal({ text: serverResponse.data.message, icon: "warning" });
        dispatch(hideLoader());
      }
    } catch (error) {
      swal({ text: error.message, icon: "warning" });
      dispatch(hideLoader());
    }
  }

  return (
    <div className="form_section">
      {loader === true ?
        <Loader />
        :
        <div className="form_main">
          <div className="centered_icon">
            <i className="fa-solid fa-users"></i>
          </div>
          <div className="input_field">
            <div className="input_icon">
              <i className="fa-solid fa-user-tie"></i>
            </div>
            <input type="text" name="userId" value={data.userId} onChange={(event) => setData({ ...data, userId: event.target.value })} className="form-control" placeholder="Enter User Id" />
          </div>
          {validator.message("userId", data.userId, "required", {
            messages: {
              required: "UserId is required"
            }
          })}
          <div className="input_field">
            <div className="input_icon">
              <i className="fa-solid fa-key"></i>
            </div>
            <input type={passwordInputType ? "password" : "text"} name="password" value={data.password} onChange={(event) => setData({ ...data, password: event.target.value })} className="form-control" placeholder="Enter Password" />
            <div className="show_pass_icon" onClick={() => setPasswordInputType(!passwordInputType)}>
              <FontAwesomeIcon icon={!passwordInputType ? faEyeSlash : faEye} />
            </div>

          </div>
          {validator.message("password", data.password, "required|min:8", {
            messages: {
              required: "Password is required"
            }
          })}
          <div className="forget_password">
            <span onClick={() => navigate("/reset-password")}>Reset Password ?</span>
            <span onClick={() => navigate("/forget-password-mobile-verification")}>Forget Password ?</span>
          </div>
          <button onClick={() => login()}>Login</button>
        </div>
      }
    </div>
  )
}

export default SignIn