import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import H3 from '../../../../text-components/H3';
import useValidator from "../../../../utilities/useValidator";
import InputField from '../../../../common-components/InputField';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from '../../../../utilities/Loader';


const UpdateSubDepartment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);

    const [validator, showValidationMessage] = useValidator();
    //get query params
    const [searchUrl] = useSearchParams();
    const [departments, setDepartments] = useState([]);
    //get all input box values
    const [subDepartmentDetails, setSubDepartmentDetails] = useState(
        {
            subDepartmentName: "",
            departmentName: "",
            location: "",
            departmentId: ""
        }
    )

    //fetch Department details on initial load
    async function fetchDepartment() {
        try {
            dispatch(showLoader());
            //call API 
            const serverResponse = await officeCommunication.getDepartments();
            if (serverResponse.data.status === "SUCCESS") {
                setDepartments(serverResponse.data.department);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setDepartments([]);
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    //get subDepartment details on initial load
    const getSubDepartmentDetails = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getSubDepartmentById(searchUrl.get("id"));
            if (serverResponse?.data?.status === "SUCCESS") {
                setSubDepartmentDetails(serverResponse?.data?.subDepartmentDetails);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setSubDepartmentDetails({});
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };

    //Update Sub Department
    const createSubDepartment = async () => {



        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            dispatch(showLoader());
            const dataToSend = {
                subDepartmentName: subDepartmentDetails.subDepartmentName,
                departmentName: subDepartmentDetails.departmentName,
                location: subDepartmentDetails.location,
                departmentId: subDepartmentDetails.departmentId,
                subDepartmentId: subDepartmentDetails._id
            }
            const serverResponse = await officeCommunication.updateSubDepartment(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideLoader());
                navigate("/dashboard/sub-department-management")
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }

    }

    useEffect(() => {
        getSubDepartmentDetails();
        fetchDepartment();
    }, [])

    return (
        <div className="dashboard_form_section">

            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                    <div className="from_title">
                        <H3>Update Sub Department</H3>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <InputField type="text" name="subDepartment" value={subDepartmentDetails.subDepartmentName} onChange={(event) => setSubDepartmentDetails({ ...subDepartmentDetails, subDepartmentName: event.target.value  })} placeholder="Enter Sub Department Name" />
                            {validator.message("subDepartment", subDepartmentDetails.subDepartmentName, "required", {
                                messages: {
                                    required: "Sub Department is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <select name="department" onChange={(event) => setSubDepartmentDetails({ ...subDepartmentDetails, departmentName: JSON.parse(event.target.value).departmentName, departmentId: JSON.parse(event.target.value)._id })} className="form-select" style={{ color: `${theme === "WHITE" ? "#8887C9" : "#8887C9"}` }}>
                                <option value={JSON.stringify({ _id: "", departmentName: "" })}>Select Department</option>
                                {departments.map((department, index) => {
                                    return (

                                        <option value={JSON.stringify(department)} selected={department._id === subDepartmentDetails.departmentId}>{department.departmentName}</option>
                                    )
                                })}
                            </select>
                            {validator.message("department", subDepartmentDetails.departmentName, "required", {
                                messages: {
                                    required: "Department is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <InputField type="text" name="location" value={subDepartmentDetails.location} onChange={(event) => setSubDepartmentDetails({ ...subDepartmentDetails, location: event.target.value  })} placeholder="Enter Location" />
                            {validator.message("location", subDepartmentDetails.location, "required", {
                                messages: {
                                    required: "Location is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="button_group">
                        <button className="button" onClick={() => createSubDepartment()}>update</button>
                        <button className="button" onClick={() => navigate("/dashboard/sub-department-management")}>Cancel</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default UpdateSubDepartment;