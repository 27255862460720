import React from 'react'
import { useSelector } from 'react-redux';

const PPublic = ({children }) => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier.fontSize);

    return (
        <p style={{ fontSize: `${14 * fontSizeMultiplier / 100}px` }}>{children}</p>
    )
}

export default PPublic