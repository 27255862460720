import React, { useEffect, useState } from 'react';
import Button from '../../../../common-components/Button'
import H3 from '../../../../text-components/H3'
import H5 from '../../../../text-components/H5';
import HeaderText from '../../../../text-components/HeaderText';
import { officeCommunication } from '../../../../services/officeCommunication';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import * as FileSaver from "file-saver";
import ComplaintDetail from '../../../../common-components/ComplaintDetail';
import ReactPaginate from 'react-paginate';

const StatuswiseComplaintListModal = ({ labelData, setLabelData }) => {
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [filteredComplaints, setFilteredComplaints] = useState([]);
    const [selectedComplaint, setSelectedComplaint] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate()
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const limit = process.env.REACT_APP_LIMIT
    const [showHideLoader, setShowHideLoader] = useState(false);


    //Onclick set current page
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage)
        if (searchValue) {
            fetchComplaintListBySearch(searchValue, selectedPage + 1);
        } else {
            if ((labelData?.searchFilterValue === "" || labelData?.searchFilterValue === "By_Department")) {
                fetchComplaintListByDepartmentId(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_Status") {
                fetchAllComplaintsByStatus(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_Aging") {
                fetchAllComplaintsByAgingAndStatus(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_User_Feedback") {
                fetchAllComplaintsByRating(currentPage + 1);
            }
            else {
                getDepartmentComplaintByStatus(currentPage + 1);
            }
        }
    };


    //set Data to Export
    const exportData = async () => {
        try {
            const totalComplaints = `${pageCount === 1 ? pageCount * limit : (pageCount - 1) * limit}`
            const splitStatus = labelData?.complaintStatus.split("-").join("").toLowerCase();
            const status = splitStatus === "reopened" ? "reopen" : splitStatus;
            swal({
                // title: "Are you sure?",
                text: pageCount > 1 ? `Complaints are more than ${totalComplaints}, Are you sure you want to export?` : "Are you sure you want to export?",
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    setShowHideLoader(true);
                    let responseFromServer;
                    if ((labelData?.searchFilterValue === "" || labelData?.searchFilterValue === "By_Department")) {
                        responseFromServer = await officeCommunication.exportGraphDataByDepartment(labelData?.departmentId, status);
                    } else if (labelData?.searchFilterValue === "By_Status") {
                        responseFromServer = await officeCommunication.exportGraphDataByStatus(status);
                    } else if (labelData?.searchFilterValue === "By_Aging") {
                        responseFromServer = await officeCommunication.exportGraphDataByAging(status, labelData?.dataBetween, labelData?.agingFilterValue);
                    } else if (labelData?.searchFilterValue === "By_User_Feedback") {
                        responseFromServer = await officeCommunication.exportGraphDataByRating(labelData?.rating, labelData?.departmentId);
                    } else if (labelData?.searchFilterValue === "By_Sub_Department") {
                        responseFromServer = await officeCommunication.exportGraphDataBySubDepartment(labelData?.subDepartmentId, status,labelData?.startDate, labelData?.endDate,);
                    } else {
                        responseFromServer = await officeCommunication.exportGraphDataForDepartmentUser(status);
                    }
                    if (responseFromServer.data) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([responseFromServer.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        FileSaver.saveAs(file, "Complaints.xlsx");
                    } else {
                        swal({ text: "Failed to export data in excel", icon: "warning" });
                    }
                    setShowHideLoader(false);
                } else {
                    return;
                }
            });
        } catch (error) {
            setShowHideLoader(false);
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Search Filter
    const searchFilter = (searchString) => {
        if (searchString === "") {
            if ((labelData?.searchFilterValue === "" || labelData?.searchFilterValue === "By_Department")) {
                fetchComplaintListByDepartmentId(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_Sub_Department") {
                fetchComplaintBySubDepartment(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_Status") {
                fetchAllComplaintsByStatus(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_Aging") {
                fetchAllComplaintsByAgingAndStatus(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_User_Feedback") {
                fetchAllComplaintsByRating(currentPage + 1);
            }
            else {
                getDepartmentComplaintByStatus(currentPage + 1);
            }
        } else {
            fetchComplaintListBySearch(searchString);
        }
    }

    //Get Complaints by Search
    const fetchComplaintListBySearch = async (searchString, page) => {
        try {
            const splitStatus = labelData?.complaintStatus.split("-").join("").toLowerCase();
            const status = splitStatus === "reopened" ? "reopen" : splitStatus;
            let responseFromServer;
            if ((labelData?.searchFilterValue === "" || labelData?.searchFilterValue === "By_Department")) {
                responseFromServer = await officeCommunication.getStackedBarDepartmentIdwiseComplaintsBySearch(labelData?.departmentId, status, searchString, page);
            } else if (labelData?.searchFilterValue === "By_Sub_Department") {
                responseFromServer = await officeCommunication.getStackedBarSubDepartmentIdWiseComplaintsBySearch(labelData?.subDepartmentId, status, searchString, page,labelData?.startDate, labelData?.endDate);
            } else if (labelData?.searchFilterValue === "By_Status") {
                responseFromServer = await officeCommunication.getStackedBarStatuswiseComplaintsBySearch(status, searchString, page);
            } else if (labelData?.searchFilterValue === "By_Aging") {
                responseFromServer = await officeCommunication.getStackedBarAgingwiseComplaintsBySearch(status, labelData?.dataBetween, labelData?.agingFilterValue, searchString, page);
            } else if (labelData?.searchFilterValue === "By_User_Feedback") {
                responseFromServer = await officeCommunication.getStackedBarRatingwiseComplaintsBySearch(labelData?.rating, labelData?.departmentId, searchString, page);
            }
            else {
                responseFromServer = await officeCommunication.getPieChartComplaintsBySearch(status, searchString, page);
            }

            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredComplaints(responseFromServer.data.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            } else {
                setFilteredComplaints([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //get department complaint by status on initial load
    const getDepartmentComplaintByStatus = async (selectedPage) => {
        try {
            const splitStatus = labelData?.complaintStatus.split("-").join("").toLowerCase();
            const status = splitStatus === "reopened" ? "reopen" : splitStatus;
            const serverResponse = await officeCommunication.getDepartmentComplaintByStatus(status, labelData?.startDate, labelData?.endDate, selectedPage)
            if (serverResponse?.data?.status === "SUCCESS") {
                setFilteredComplaints(serverResponse.data.complaints);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            } else {
                setFilteredComplaints([]);
                setPageCount(0)
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    };

    //Get complaint list by department id on initial load
    const fetchComplaintListByDepartmentId = async (selectedPage) => {
        try {
            const splitStatus = labelData?.complaintStatus.split("-").join("").toLowerCase();
            const status = splitStatus === "reopened" ? "reopen" : splitStatus;
            const responseFromServer = await officeCommunication.getDepartmentComplaintsByDepartmentId(labelData?.departmentId, status, labelData?.startDate, labelData?.endDate, selectedPage);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredComplaints(responseFromServer?.data?.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                setFilteredComplaints([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    //Get All Complaints by status on initial load
    const fetchAllComplaintsByStatus = async (selectedPage) => {
        try {
            const splitStatus = labelData?.complaintStatus.split("-").join("").toLowerCase();
            const status = splitStatus === "reopened" ? "reopen" : splitStatus;
            const responseFromServer = await officeCommunication.getComplaintListByStatus(status, labelData?.startDate, labelData?.endDate, selectedPage);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredComplaints(responseFromServer?.data?.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    //Get All Complaints by Aging on initial load
    const fetchAllComplaintsByAgingAndStatus = async (selectedPage) => {
        try {
            const splitStatus = labelData?.complaintStatus.split("-").join("").toLowerCase();
            const status = splitStatus === "reopened" ? "reopen" : splitStatus;
            const responseFromServer = await officeCommunication.getComplaintListByAging(status, labelData?.dataBetween, labelData?.agingFilterValue, selectedPage);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredComplaints(responseFromServer?.data?.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    //Get All Complaints by Rating on initial load
    const fetchAllComplaintsByRating = async (selectedPage) => {
        try {
            const responseFromServer = await officeCommunication.getComplaintListByRating(labelData?.rating, labelData?.departmentId, labelData?.startDate, labelData?.endDate, selectedPage);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredComplaints(responseFromServer?.data?.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    async function fetchComplaintBySubDepartment(selectedPage) {
        try {
            const splitStatus = labelData?.complaintStatus.split("-").join("").toLowerCase();
            const status = splitStatus === "reopened" ? "reopen" : splitStatus;
            const responseFromServer = await officeCommunication.getSubDepartmentComplaintsBySubDepartmentId(labelData?.subDepartmentId, status, labelData?.startDate, labelData?.endDate, selectedPage);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredComplaints(responseFromServer?.data?.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                setFilteredComplaints([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    useEffect(() => {
        if (labelData?.complaintStatus) {
            if ((labelData?.searchFilterValue === "" || labelData?.searchFilterValue === "By_Department")) {
                fetchComplaintListByDepartmentId(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_Status") {
                fetchAllComplaintsByStatus(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_Aging") {
                fetchAllComplaintsByAgingAndStatus(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_User_Feedback") {
                fetchAllComplaintsByRating(currentPage + 1);
            } else if (labelData?.searchFilterValue === "By_Sub_Department") {
                fetchComplaintBySubDepartment(currentPage + 1);
            }
            else {
                getDepartmentComplaintByStatus(currentPage + 1);
            }
        }
    }, [labelData, currentPage]);


    return (
        <>
            <div className="statuswise_complaint_list_modal" style={{ backgroundColor: `${theme === "WHITE" ? "#fff" : "#444444"}` }}>
                <div className="search_header statuswise_complaints_header">
                    {labelData?.searchFilterValue === "By_Aging" ?
                        <H3>Complaints</H3>
                        :
                        <>
                            {
                                (labelData?.searchFilterValue === "By_User_Feedback" && labelData?.rating.toString().length === 1) ?
                                    <H3>{`${labelData?.rating} star Complaints`}</H3>

                                    :

                                    <H3>{`${labelData?.complaintStatus}  Complaints`}</H3>


                            }
                        </>
                    }
                    <div className="search_input">
                        <input type="text" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search by Department..." title="Search by Department, Sub-Department, Complaint Type, Area Type" style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div className="button_group">
                        {filteredComplaints?.length > 0 &&
                            <Button name="Export to Excel" onClick={() => exportData()} icon={<i className="fa-regular fa-file-lines"></i>} />
                        }
                        <Button name="Cancel" onClick={() => setLabelData({ ...labelData, showComplaintList: false })} />
                    </div>

                </div>
                <div className="table_main statusWise_commplaint_table">
                    {showHideLoader === false ?
                        <table>
                            <thead>
                                <tr>
                                    <th><HeaderText>Serial Number</HeaderText></th>
                                    <th><HeaderText>Complaint Id</HeaderText></th>
                                    <th><HeaderText>Department</HeaderText></th>
                                    <th><HeaderText>Sub Department</HeaderText></th>
                                    <th><HeaderText>Complaint Type</HeaderText></th>
                                    <th><HeaderText>Area Type</HeaderText></th>
                                    <th><HeaderText>City / Grampanchayat</HeaderText></th>
                                    <th><HeaderText>Date</HeaderText></th>
                                </tr>
                            </thead>
                            {filteredComplaints?.length > 0 ?
                                <>
                                    <tbody>
                                        {filteredComplaints?.map((complaint, index) => {
                                            return (
                                                <tr key={index} style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }}>
                                                    <td><H5>{(limit * (currentPage)) + (index + 1)}</H5></td>
                                                    <td className="link" onClick={() => { setShowForm(true); setSelectedComplaint(complaint.complaintId) }} ><H5>{complaint.complaintId}</H5></td>
                                                    <td><H5>{complaint.departmentName}</H5></td>
                                                    <td><H5>{complaint.subDepartmentName}</H5></td>
                                                    <td><H5>{complaint.complaintType}</H5></td>
                                                    <td><H5>{complaint.personalDetails.areaType}</H5></td>
                                                    <td>{complaint.personalDetails.areaType === "urban"
                                                        ?
                                                        <H5>{complaint.personalDetails.city}</H5>
                                                        :
                                                        <H5>{complaint.personalDetails.gramPanchayat}</H5>

                                                    }
                                                    </td>
                                                    <td><H5>{new Date(complaint.updateDate).toLocaleDateString()}</H5></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </>
                                :
                                <tbody>
                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}><td></td></tr>
                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><H3>Complaint Not Available</H3></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                        :
                        <div className="modal_loader" style={{ backgroundColor: `${theme === "WHITE" ? "#fff" : "#444444"}` }}>
                            <H5>Exporting Data Please Wait...</H5>
                        </div>
                    }
                </div>
                {showForm &&
                    <ComplaintDetail selectedComplaint={selectedComplaint} setShowForm={setShowForm} />
                }
                {showForm === false &&
                    <>
                        {pageCount > 1 &&
                            <div className='grap_pagination_div' style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}` }}>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                    </>
                }
            </div>

        </>
    )
}

export default StatuswiseComplaintListModal