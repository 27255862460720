import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useValidator from '../../utilities/useValidator';
import swal from 'sweetalert';
import { hideLoader, showLoader } from '../../redux-store/loaderReducer';
import Loader from '../../utilities/Loader';
import { officeCommunication } from '../../services/officeCommunication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';



const ForgetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [searchUrl] = useSearchParams();
    const loader = useSelector((state) => state.loader.showHideLoader);

    const [data, setData] = useState(
        {
            userId: "",
            password: "",
            confirmPassword: ""
        }
    );
    const [passwordInputType, setPasswordInputType] = useState(true)

    // forgot password
    const forgetPassword = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const dataToSend = {
                userId: data.userId.trim(),
                newPassword: data.password.trim(),
                mobileNumber: searchUrl.get("mobileNumber"),
            }
            //call API
            const serverResponse = await officeCommunication.forgetPassword(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideLoader());
            } else {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    return (
        <div className="form_section">
            {loader === true ?
                <Loader />
                :
                <div className="form_main">
                    <div className="centered_icon">
                        <i className="fa-solid fa-users"></i>
                    </div>

                    <div className="input_field">
                        <div className="input_icon">
                            <i className="fa-solid fa-user-tie"></i>
                        </div>
                        <input type="text" name="userId" value={data.userId} onChange={(event) => setData({ ...data, userId: event.target.value })} className="form-control" placeholder="Enter User Id" />
                    </div>
                    {validator.message("userId", data.userId, "required", {
                        messages: {
                            required: "UserId is required"
                        }
                    })}

                    <div className="input_field">
                        <div className="input_icon">
                            <i className="fa-solid fa-key"></i>
                        </div>
                        <input type={passwordInputType ? "password" : "text"} name="password" onChange={(event) => setData({ ...data, password: event.target.value })} className="form-control" placeholder="Enter New Password" />
                        <div className="show_pass_icon" onClick={() => setPasswordInputType(!passwordInputType)}>
                            <FontAwesomeIcon icon={!passwordInputType ? faEyeSlash : faEye} />
                        </div>
                    </div>
                    {validator.message("password", data.password, "required|min:8", {
                        messages: {
                            required: "New Password is required"
                        }
                    })}

                    <div className="input_field">
                        <div className="input_icon">
                            <i className="fa-solid fa-key"></i>
                        </div>
                        <input type={passwordInputType ? "password" : "text"} name="confirmPassword" onChange={(event) => setData({ ...data, confirmPassword: event.target.value })} className="form-control" placeholder="Confirm Password" />
                    </div>
                    {validator.message("confirmPassword", data.confirmPassword, `required|min:8|in:${data.password}`, {
                        messages: {
                            required: "Confirm Password is required",
                            in: "Password Must be Matching!"
                        }
                    })}

                    <div className="forget_password">
                        <span onClick={() => navigate("/reset-password")}>Reset Password?</span>
                        <span onClick={() => navigate("/login")}>Login</span>
                    </div>

                    <button onClick={() => forgetPassword()}>Change Password</button>

                </div>
            }
        </div>
    )
}

export default ForgetPassword