 export const gallaryImg = [
    {
        "type": "medical",
        "imgUrl": "/Home/Gallary/Medical1.png",
        "attribute": "Medical First"
    },

    {
        "type": "medical",
        "imgUrl": "/Home/Gallary/Medical2.png",
        "attribute": "Medical Second"
    },

    {
        "type": "Corporation",
        "imgUrl": "/Home/Gallary/Corporation1.png",
        "attribute": "Corporation First"
    },

    {
        "type": "Corporation",
        "imgUrl": "/Home/Gallary/Corporation2.png",
        "attribute": "Corporation Second"
    },

    {
        "type": "Transport",
        "imgUrl": "/Home/Gallary/Transport1.png",
        "attribute": "Transport First"
    },

    {
        "type": "Transport",
        "imgUrl": "/Home/Gallary/Transport2.png",
        "attribute": "Transport Second"
    },

    {
        "type": "Transport",
        "imgUrl": "/Home/Gallary/Transport3.png",
        "attribute": "Transport Third"
    },

    {
        "type": "Transport",
        "imgUrl": "/Home/Gallary/Transport4.png",
        "attribute": "Transport forth"
    },

];