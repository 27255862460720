import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import H3 from '../../../../text-components/H3';
import H5 from '../../../../text-components/H5';
import HeaderText from '../../../../text-components/HeaderText';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from '../../../../utilities/Loader';


const DepartmentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);

  const loader = useSelector((state) => state.loader.showHideLoader);
  const [departments, setDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);

  // Search filter
  const searchFilter = (value) => {
    if (value === "") {
      setFilteredDepartments(departments);
    } else {
      const data = departments.filter((element) => (element.departmentName.toLowerCase().includes(value.toLowerCase()) || element.location.toLowerCase().includes(value.toLowerCase())))
      setFilteredDepartments(data);
    }
  }



  //fetch Department details on initial load
  async function fetchDepartment() {
    try {
      dispatch(showLoader());
      //call API 
      const serverResponse = await officeCommunication.getDepartments();
      if (serverResponse.data.status === "SUCCESS") {
        setDepartments(serverResponse.data.department);
        setFilteredDepartments(serverResponse.data.department);
        dispatch(hideLoader());
      } else if (serverResponse?.data?.status === "JWT_INVALID") {
        swal({ text: serverResponse.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setDepartments([]);
        setFilteredDepartments([]);
        dispatch(hideLoader());
      }

    } catch (error) {
      swal({ text: error.message, icon: "warning" });
      dispatch(hideLoader());
    }
  }

  useEffect(() => {
    fetchDepartment();
  }, [])

  return (
    <div className="panel">
      {loader === true ?
        <Loader />
        :
        <>
          <div className="search_header">
            <H3>Department Management</H3>
            <div className="search_input">
              <input type="text" className="form-control" placeholder="Search by Department..." onChange={(e) => searchFilter(e.target.value)} style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} title="Search By Department, Location" />
              <i className="fa-solid fa-magnifying-glass"></i>
            </div>
            <button className="button" onClick={() => navigate("/dashboard/department-management/create-department")}>Create Department</button>
          </div>
          <div className="table_main">
            <table>
              <thead>
                <tr>
                  <th><HeaderText>Serial Number</HeaderText></th>
                  <th><HeaderText>Department</HeaderText></th>
                  <th><HeaderText>Location</HeaderText></th>
                  <th><HeaderText>Date</HeaderText></th>
                  <th><HeaderText>Action</HeaderText></th>
                </tr>
              </thead>
              {filteredDepartments.length > 0 ?
                <>
                  <tbody>
                    {filteredDepartments.map((department, index) => {
                      return (
                        <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }} key={index}>
                          <td><H5>{index + 1}</H5></td>
                          <td><H5>{department?.departmentName}</H5></td>
                          <td><H5>{department?.location}</H5></td>
                          <td><H5>{new Date(department?.creationDate).toLocaleDateString()}</H5></td>
                          <td onClick={() => navigate(`/dashboard/department-management/update-department?id=${department._id}`)}><i className="fa-solid fa-pen-to-square"></i></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </>
                :
                <tbody>
                  <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}><td></td><td></td><td></td><td></td><td></td></tr>
                  <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}>
                    <td></td>
                    <td></td>
                    <td><H3>Department Not Available</H3></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              }

            </table>
          </div>
        </>
      }
    </div>
  )
}

export default DepartmentList