import React from 'react'
import { useSelector } from 'react-redux';

const H4Public = ({ whiteThemeColor, darkThemeColor, children }) => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier.fontSize);

    return (
        <h4 style={{ fontSize: `${13 * fontSizeMultiplier / 100}px` }}>{children}</h4>
    )
}

export default H4Public;