import { createSlice } from "@reduxjs/toolkit";

const darkWhiteThemeToggle = createSlice({
    name: "darkWhiteThemeToggleSlice",
    initialState: {
        theme: "WHITE",
    },
    reducers: {
        darkWhiteTheme: (state, action) => {
            state.theme = action.payload;
            return state
        }
    }
});

export const { darkWhiteTheme } = darkWhiteThemeToggle.actions;
export default darkWhiteThemeToggle.reducer;