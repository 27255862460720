import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import H3Public from '../../../text-components/H3Public';
import { publicCommunication } from "../../../services/publicCommunication";
import swal from "sweetalert";

const HeroSection = ({ setShowModals }) => {

    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [websiteVisitors, setWebsiteVisitors] = useState();

    // get complaints counts & visitor counts
    async function fetchCounts() {
        try {
            const serverResponse = await publicCommunication.fetchCounts();
            if (serverResponse.data.status === "SUCCESS") {
                setWebsiteVisitors(serverResponse?.data?.websiteVisitor);
            } else {
                setWebsiteVisitors("");
            }
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    };

    useEffect(() => {
        fetchCounts();
    }, []);


    return (
        <>

            <div className="hero">

                <div id="herocarouselControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">


                        <div className="carousel-item active">
                            <img src="/Home/bannerMain.jpg" className="d-block w-100" alt="VandeMantraChanda.in Banner" />
                        </div>

                        <div className="carousel-item">
                            <img src="/Home/Tadoba-tiger-cub-dry-grass.jpg" className="d-block w-100" alt="Tadoba tiger cub dry grass" />
                        </div>

                        <div className="carousel-item">
                            <img src="/Home/veer-shah-chandrapur.jpg" className="d-block w-100" alt="veer shah chandrapur" />
                        </div>

                        <div className="carousel-item ">
                            <img src="/Home/Tadoba-tiger-cub-road-track.jpg" className="d-block w-100" alt="Tadoba tiger cub road track" />
                        </div>

                        <div className="carousel-item">
                            <img src="/Home/Bhadrawati-Jain-Temple-chandrapur.jpg" className="d-block w-100" alt="Bhadrawati Jain Temple chandrapur" />
                        </div>

                        <div className="carousel-item ">
                            <img src="/Home/Tadoba-tiger-cub-red-soil.jpg" className="d-block w-100" alt="Tadoba tiger cub red soil" />
                        </div>

                        <div className="carousel-item">
                            <img src="/Home/Tadoba-rainforest-gate.jpg" className="d-block w-100" alt="Tadoba rainforest gate" />
                        </div>

                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#herocarouselControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#herocarouselControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

                <div className="heading_main">
                    <div className="heading_sub main_heading_text">
                        <div >
                            <h1>WELCOME TO CHANDRAPUR'S <br />
                                PUBLIC GRIEVANCE REDRESSAL SYSTEM</h1>
                            <div className="site_visitor_box">
                                <div className="site_visitor_details">
                                    <h3>Site Visitors </h3>
                                    <h2>{websiteVisitors}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="map">
                            <img src="/Home/ChandraOutline.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="track_complaint_main row" style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#1E1E20"}` }}>
                    <div className="col-md-8 col-lg-8 track_sub">
                        <div className="track_text_main">
                            <H3Public>Now you can also Raise your Complaint on call, Call us on toll free No - 1800-233-8691</H3Public>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                        <div className="track_button_main track_sub">
                            <button onClick={() => setShowModals({ trackComplaint: true })}> Track Your Complaint</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default HeroSection;