import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import Dashboard from "../common-components/Dashboard";
import UserProfile from "../common-components/UserProfile";
import AllComplaints from "../pages/office-dashboard/admin/complaint-lookups/AllComplaints";
import NewComplaints from "../pages/office-dashboard/admin/complaint-lookups/NewComplaints";
import InProgressComplaints from "../pages/office-dashboard/admin/complaint-lookups/InProgressComplaints";
import ResolvedComplaints from "../pages/office-dashboard/admin/complaint-lookups/ResolvedComplaints";
import ReOpenedComplaints from "../pages/office-dashboard/admin/complaint-lookups/ReOpenedComplaints";
import ClosedComplaints from "../pages/office-dashboard/admin/complaint-lookups/ClosedComplaints";
import ComplaintLookups from "../pages/office-dashboard/admin/complaint-lookups/ComplaintLookups";
import CreateDepartment from "../pages/office-dashboard/admin/department-management/CreateDepartment";
import DepartmentList from "../pages/office-dashboard/admin/department-management/DepartmentList";
import UpdateDepartment from "../pages/office-dashboard/admin/department-management/UpdateDepartment";
import CreatePost from "../pages/office-dashboard/admin/post-name-management/CreatePost";
import PostNameList from "../pages/office-dashboard/admin/post-name-management/PostNameList";
import UpdatePost from "../pages/office-dashboard/admin/post-name-management/UpdatePost";
import CreateSubDepartment from "../pages/office-dashboard/admin/sub-department-management/CreateSubDepartment";
import SubDepartmentList from "../pages/office-dashboard/admin/sub-department-management/SubDepartmentList";
import UpdateSubDepartment from "../pages/office-dashboard/admin/sub-department-management/UpdateSubDepartment";
import CreateUser from "../pages/office-dashboard/admin/user-management/CreateUser";
import UpdateUser from "../pages/office-dashboard/admin/user-management/UpdateUser";
import UserList from "../pages/office-dashboard/admin/user-management/UserList";
import SignIn from "../pages/office-dashboard/SignIn";
import PublicRoutes from "./PublicRoutes";
import Complaints from "../pages/office-dashboard/department-user/complaints/Complaints";
import CustomerCareResolvedComplaints from "../pages/office-dashboard/customer-care/resolved-complaints/CustomerCareResolvedComplaints";
import RaiseComplaint from "../pages/office-dashboard/customer-care/raise-complaints/RaiseComplaint";
import DepartmentReport from "../pages/office-dashboard/department-user/report/DepartmentReport";
import Reports from "../pages/office-dashboard/admin/reports/Reports";
import MobileVerification from "../pages/office-dashboard/customer-care/raise-complaints/MobileVerification";
import RaiseComplaintOutlet from "../pages/office-dashboard/customer-care/raise-complaints/RaiseComplaintOutlet";
import ResetPassword from "../pages/office-dashboard/ResetPassword";
import ForgetPassword from "../pages/office-dashboard/ForgetPassword";
import ForgetPasswordMobileOtp from "../pages/office-dashboard/ForgetPasswordMobileOtp";
import AccelatedComplaints from "../pages/office-dashboard/admin/accelated-complaints/AccelatedComplaints";
import Notifications from "../pages/office-dashboard/admin/notifications/Notifications";
import RejectedComplaints from "../pages/office-dashboard/admin/complaint-lookups/RejectedComplaints";
import SuperUserList from "../pages/office-dashboard/admin/super-user-management/SuperUserList";
import CreateSuperUser from "../pages/office-dashboard/admin/super-user-management/CreateSuperUser";
import UpdateSuperUser from "../pages/office-dashboard/admin/super-user-management/UpdateSuperUser";

const MainRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<SignIn />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/forget-password-mobile-verification" element={<ForgetPasswordMobileOtp />} />
            <Route path="/public/*" element={<PublicRoutes />}></Route>
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
                <Route path="/dashboard/department-management" element={<DepartmentList />} />
                <Route path="/dashboard/department-management/create-department" element={<CreateDepartment />} />
                <Route path="/dashboard/department-management/update-department" element={<UpdateDepartment />} />
                <Route path="/dashboard/sub-department-management" element={<SubDepartmentList />} />
                <Route path="/dashboard/sub-department-management/create-sub-department" element={<CreateSubDepartment />} />
                <Route path="/dashboard/sub-department-management/update-sub-department" element={<UpdateSubDepartment />} />
                <Route path="/dashboard/post-name-management" element={<PostNameList />} />
                <Route path="/dashboard/post-name-management/create-post" element={<CreatePost />} />
                <Route path="/dashboard/post-name-management/update-post" element={<UpdatePost />} />
                <Route path="/dashboard/user-management" element={<UserList />} />
                <Route path="/dashboard/user-management/create-user" element={<CreateUser />} />
                <Route path="/dashboard/user-management/update-user" element={<UpdateUser />} />
                <Route path="/dashboard/super-user-management" element={<SuperUserList />} />
                <Route path="/dashboard/super-user-management/create-super-user" element={<CreateSuperUser />} />
                <Route path="/dashboard/super-user-management/update-super-user" element={<UpdateSuperUser />} />
                <Route path="/dashboard/notifications" element={<Notifications />} />
                <Route path="/dashboard/accelated-complaints" element={<AccelatedComplaints />} />
                <Route path="/dashboard/complaint-lookups" element={<ComplaintLookups />}>
                    <Route path="/dashboard/complaint-lookups" element={<Navigate to="/dashboard/complaint-lookups/all-complaints" />} />
                    <Route path="/dashboard/complaint-lookups/all-complaints" element={<AllComplaints />} />
                    <Route path="/dashboard/complaint-lookups/new-complaints" element={<NewComplaints />} />
                    <Route path="/dashboard/complaint-lookups/in-progress-complaints" element={<InProgressComplaints />} />
                    <Route path="/dashboard/complaint-lookups/resolved-complaints" element={<ResolvedComplaints />} />
                    <Route path="/dashboard/complaint-lookups/re-opened-complaints" element={<ReOpenedComplaints />} />
                    <Route path="/dashboard/complaint-lookups/closed-complaints" element={<ClosedComplaints />} />
                    <Route path="/dashboard/complaint-lookups/rejected-complaints" element={<RejectedComplaints />} />
                </Route>
                <Route path="/dashboard/report" element={<Reports />} />
                <Route path="/dashboard/department-complaints" element={<Complaints />} />
                <Route path="/dashboard/department-report" element={<DepartmentReport />} />
                <Route path="/dashboard/resolved-complaints" element={<CustomerCareResolvedComplaints />} />
                <Route path="/dashboard/complaint" element={<RaiseComplaintOutlet />}>
                    <Route path="/dashboard/complaint" element={<Navigate to="/dashboard/complaint/mobile-verification" />} />
                    <Route path="/dashboard/complaint/mobile-verification" element={<MobileVerification />} />
                    <Route path="/dashboard/complaint/raise-complaint" element={<RaiseComplaint />} />
                </Route>
                <Route path="/dashboard/profile" element={<UserProfile />} />
            </Route>
            <Route path="*" element={<Navigate to="/public" replace />} />
        </Routes>
    );
}
export default MainRoutes;