import React, { useState, useEffect } from 'react';
import { publicCommunication } from '../../../services/publicCommunication';
import { showLoader, hideLoader } from '../../../redux-store/loaderReducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const TrackComplaintStatus = ({ setTrackComplaintStatus, selectedComplaintId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [complaint, setComplaint] = useState({});
    const [documentArray, setDocumentArray] = useState([]);


    //get all complaint against the verify mobile no 
    async function getComplaintDetails() {
        try {
            if (["", null, undefined].includes(selectedComplaintId)) {
                navigate("/");
                return;
            }
            dispatch(showLoader());
            const serverResponse = await publicCommunication.getComplaintById(selectedComplaintId);
            if (serverResponse.data.status === "SUCCESS") {
                setComplaint(serverResponse.data.complaint);
                if (["resolved", "closed"].includes(serverResponse.data.complaint.complaintStatus)) {
                    const documents = serverResponse.data.complaint.complaintStatus === "closed" ? serverResponse.data.complaint.logs[serverResponse.data.complaint.logs.length - 2].documentArray : serverResponse.data.complaint.logs[serverResponse.data.complaint.logs.length - 1].documentArray
                    setDocumentArray(documents);
                }
                dispatch(hideLoader());
            } else {
                setComplaint({});
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    //Preview Document
    const previewDocument = async (fileUrl) => {
        try {
            if (fileUrl) {
                const serverResponse = await publicCommunication.previewComplaintAttachment(fileUrl);
                const splitUrl = fileUrl.split(".");
                const extension = splitUrl[splitUrl.length - 1];
                //Create a Blob from the PDF Stream
                const file = new Blob([serverResponse.data], {
                    // type: "application/pdf"
                    type: extension === "pdf" ? "application/pdf" : "image/jpg"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
            } else {
                swal({ text: "File Not Found", icon: "warning" });
            }

        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    useEffect(() => {
        getComplaintDetails();
    }, []);

    return (
        <div className="public_form_section track_complaint_section">
            <div className="form_main">
                <div className="form_header">
                    <h6>Complaint Status</h6>
                    <div onClick={() => setTrackComplaintStatus(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className="form track_status">
                    <div className="complaint_status_main">
                        <div className="complaint_status_main_data">
                            <h3> Complaint ID - {complaint.complaintId}</h3>
                        </div>

                        <h3 className="status"> Status -{complaint.complaintStatus === "reopen" ? "reopened" : complaint.complaintStatus}</h3>
                        <div className="status_box">
                            <div className="status_main_box">
                                <div className={complaint.complaintStatus === "submitted" ? "status_name complaint_status" : "status_name"}><h4>Submitted</h4></div>
                                <div className={complaint.complaintStatus === "reassigned" ? "status_name complaint_status" : "status_name"}><h4>In-progress</h4></div>
                                <div className={complaint.complaintStatus === "resolved" ? "status_name complaint_status" : "status_name"}><h4>Resolved</h4></div>
                                <div className={complaint.complaintStatus === "reopen" ? "status_name complaint_status" : "status_name"}><h4>Re-opened</h4></div>
                                <div className={complaint.complaintStatus === "closed" ? "status_name complaint_status" : "status_name"}><h4>Closed</h4></div>
                            </div>
                            {/* <div className="center_line"></div> */}
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-md-12">
                            <div className="complaint_status_main_data">
                                <h3> Complaint Details</h3>
                                <div className="status_box">
                                    <h3>{complaint?.complaintDescription}</h3>
                                </div>
                            </div>
                        </div>
                        {
                            ["closed", "resolved"].includes(complaint?.complaintStatus) &&
                            <>
                                <div className="col-md-12">
                                    <div className="complaint_status_main_data">
                                        <h3> Resolution</h3>
                                        <div className="status_box">
                                            <h3>{complaint?.solution}</h3>
                                        </div>
                                    </div>
                                </div>
                                {documentArray?.length > 0 &&
                                    <div className="col-md-12">
                                        <div className="complaint_status_main_data">
                                            <h3> Attachment</h3>
                                            <div className="status_box pt-2 pb-2">
                                                {documentArray?.map((document, index) => (
                                                    <div className="Public_complaint_status_attachment" key={index}>
                                                        <h4>{document?.documentName} - </h4>
                                                        <span onClick={() => { previewDocument(document?.attachment) }}>
                                                            <i className="fa-solid fa-paperclip" ></i>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }

                            </>
                        }

                    </div>


                    <div className="complaint_status_button">
                        <button onClick={() => setTrackComplaintStatus(false)}>Close</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TrackComplaintStatus;