import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import AboutSection from "./AboutSection";
import AdministrationSection from "./AdministrationSection";
import ComplaintsCounter from "./ComplaintsCounter";
import GallarySection from "./GallarySection";
import HeroSection from "./HeroSection";
import ImpLinkNew from "./ImpLinkNew";
import { scrollToTop } from "../../../utilities/scrollToTopFunction";

const HomePage = ({ setShowModals }) => {
    const scrollPage = useRef();
    const scroll = useSelector((state) => state.scrollToTop.scroll);

    useEffect(() => {
        scrollToTop(scrollPage);
    }, [scroll]);

    return (
        <div ref={scrollPage}>
            <HeroSection setShowModals={setShowModals} />
            <ComplaintsCounter />
            <AboutSection />
            <AdministrationSection />
            <GallarySection />
            <ImpLinkNew />
        </div>
    );
}
export default HomePage;