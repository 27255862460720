import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import H3 from "../../../../text-components/H3";
import H5 from "../../../../text-components/H5";
import HeaderText from "../../../../text-components/HeaderText";
import ComplaintDetail from '../../../../common-components/ComplaintDetail';
import { showComplaintDetail } from "../../../../redux-store/showComplaintDetailReducer";
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { officeCommunication } from '../../../../services/officeCommunication';
import Loader from '../../../../utilities/Loader';
import ReactPaginate from 'react-paginate';

const CustomerCareResolvedComplaints = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const loader = useSelector((state) => state.loader.showHideLoader);
  const showComplaintForm = useSelector((state) => state.showComplaintDetailReducer.formState);
  const [filteredComplaints, setFilteredComplaints] = useState([]);
  const [selectedComplaint, setSelectedComplaint] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const limit = process.env.REACT_APP_LIMIT

  //Onclick set current page
  const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
      getResolvedComplaintsBySearch(searchValue, selectedPage + 1);
    } else {
      fetchAllResolvedComplaints(selectedPage + 1);
    }
  };

  //Search Filter 
  const searchFilter = async (searchString) => {
    if (searchString === "") {
      const responseFromServer = await officeCommunication.getAllResolvedComplaints();
      if (responseFromServer?.data?.status === "SUCCESS") {
        setFilteredComplaints(responseFromServer?.data?.complaint);
        setPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setFilteredComplaints([]);
      }
    } else {
      getResolvedComplaintsBySearch(searchString);
    }
  }

  //get Resolved Complaints By Search
  const getResolvedComplaintsBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await officeCommunication.getResolvedComplaintsBySearch(searchString, page);
      if (responseFromServer.data?.status === "SUCCESS") {
        setFilteredComplaints(responseFromServer?.data?.complaint);
        setPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer.data?.status === "JWT_INVALID") {
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        setFilteredComplaints([]);
        setPageCount(0);
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
    }
  }

  //Fetch All Resolved Complaints
  const fetchAllResolvedComplaints = async (selectedPage) => {
    try {
      dispatch(showLoader());
      const responseFromServer = await officeCommunication.getAllResolvedComplaints(selectedPage);
      if (responseFromServer?.data?.status === "SUCCESS") {
        dispatch(hideLoader());
        setFilteredComplaints(responseFromServer?.data?.complaint);
        setPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        dispatch(hideLoader());
        swal({ text: responseFromServer?.data?.message, icon: "warning" });
        navigate("/login");
      } else {
        dispatch(hideLoader());
        setFilteredComplaints([]);
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
    }
  }

  useEffect(() => {
    fetchAllResolvedComplaints();
  }, []);

  return (
    <div className="panel">
      {loader === true ?
        <Loader />
        :
        <>
          <div className="search_header">
            <H3>Resolved Complaints</H3>
            <div className="search_input">
              <input type="text" className="form-control" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} placeholder="Search by Complaint Id..." title="Search by Complaint Id, Departement, Sub Departement, Complaint Type" style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
              <i className="fa-solid fa-magnifying-glass"></i>
            </div>
            {showComplaintForm === false &&
              <>
                {pageCount > 1 &&
                  <div className='pagination_div' style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}` }}>
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      forcePage={currentPage}
                      activeClassName={"active"} />
                  </div>
                }
              </>
            }
          </div>
          <div className="table_main">
            <table>
              <thead>
                <tr>
                  <th><HeaderText>Serial Number</HeaderText></th>
                  <th><HeaderText>Complaint Id</HeaderText></th>
                  <th><HeaderText>Department</HeaderText></th>
                  <th><HeaderText>Sub Department</HeaderText></th>
                  <th><HeaderText>Complaint Type</HeaderText></th>
                  <th><HeaderText>Area Type</HeaderText></th>
                  <th><HeaderText>City / Grampanchayat</HeaderText></th>
                  <th><HeaderText>Mobile Number</HeaderText></th>
                  <th><HeaderText>Last Action Date</HeaderText></th>
                  <th><HeaderText>Status</HeaderText></th>
                </tr>
              </thead>
              {filteredComplaints.length > 0 ?
                <>
                  <tbody>
                    {filteredComplaints?.map((complaintList, index) => (
                      <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }} key={index}>
                        <td><H5>{(limit * (currentPage)) + (index + 1)}</H5></td>
                        <td className="link" onClick={() => { dispatch(showComplaintDetail()); setSelectedComplaint(complaintList?.complaintId) }}><H5>{complaintList?.complaintId}</H5></td>
                        <td><H5>{complaintList?.departmentName}</H5></td>
                        <td><H5>{complaintList?.subDepartmentName}</H5></td>
                        <td><H5>{complaintList?.complaintType}</H5></td>
                        <td><H5>{complaintList?.personalDetails?.areaType}</H5></td>
                        <td><H5>{complaintList?.personalDetails?.areaType === "rural" ? complaintList?.personalDetails?.gramPanchayat : complaintList?.personalDetails?.city}</H5></td>
                        <td><H5>{complaintList?.personalDetails?.mobileNumber}</H5></td>
                        <td><H5>{new Date(complaintList?.updateDate).toLocaleDateString()}</H5></td>
                        <td><H5>{complaintList?.departmentStatus}</H5></td>
                      </tr>
                    ))}
                  </tbody>
                </>
                :
                <tbody>
                  <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}><td></td></tr>
                  <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><H3>Complaint Not Available</H3></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
          {showComplaintForm &&
            <ComplaintDetail selectedComplaint={selectedComplaint} fetchAllResolvedComplaints={fetchAllResolvedComplaints} />
          }
        </>
      }
    </div>
  )
}

export default CustomerCareResolvedComplaints;