import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useValidator from '../utilities/useValidator';
import Button from './Button';
import InputField from './InputField';
import H5 from '../text-components/H5';
import H6 from '../text-components/H6';
import { officeCommunication } from '../services/officeCommunication';
import swal from 'sweetalert';
import Loader from '../utilities/Loader';
import H4 from '../text-components/H4';
import { hideComplaintDetail } from '../redux-store/showComplaintDetailReducer';

const ComplaintDetail = ({ selectedComplaint, getComplaints, fetchAllResolvedComplaints, setShowForm }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputRef = useRef();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [validator, showValidationMessage] = useValidator();
    const [fileValidator, showFileValidationMessage] = useValidator();
    const [showLoader, setShowLoader] = useState(false);
    const [active, setActive] = useState("personal_detail");
    const [logActive, setLogActive] = useState(0);
    const [department, setDepartment] = useState([]);
    const [subDepartment, setSubDepartment] = useState([]);
    const [post, setPost] = useState([]);
    const [complaintDetails, setComplaintDetails] = useState({});
    const [filteredLogDetail, setFilteredLogDetail] = useState();
    const [isEscalated, setIsEscalated] = useState(false);

    const userDetail = useSelector((state) => state.userDetails);

    //get current url
    const fullUrl = window.location.href.split("/");
    const [show, setShow] = useState({ showAssignSection: false, showRatingSection: false, showReOpenSection: false, showNarrationSection: false, showRejectSection: false });
    const [data, setData] = useState(
        {
            narration: "",
            asignTo: "sameDepartment",
            rating: "",
            feedback: "",
            departmentId: "",
            subDepartmentId: "",
            postId: "",
            reOpenReason: "",
            rejectionReason: ""
        }
    );

    const [documents, setDocuments] = useState(
        {
            attachement: "",
            documentName: "",
        }
    );

    const [documentArray, setDocumentArray] = useState([]);
    //Get all departments
    const fetchAllDepartments = async () => {
        try {
            const responseFromServer = await officeCommunication.getDepartments();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDepartment(responseFromServer?.data?.department);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Get Department wise Sub-Departments
    const getDepartmentWiseSubDepartments = async (departmentId) => {
        try {
            if (departmentId) {
                const responseFromServer = await officeCommunication.getSubDepartmentByDepartment(departmentId);
                if (responseFromServer?.data?.status === "SUCCESS") {
                    setSubDepartment(responseFromServer?.data?.subdepartment);
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    swal({ text: responseFromServer?.data?.message, icon: "warning" });
                    navigate("/login");
                } else {
                    swal({ text: responseFromServer?.data?.message, icon: "warning" });
                }
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    //Get Sub-Department wise Posts
    const fetchSubDepartmentWisePosts = async (subDepartmentId) => {
        try {
            const responseFromServer = await officeCommunication.getPostBySubDepartment(subDepartmentId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                const filteredPost = responseFromServer?.data?.post?.filter((ele) => ((userDetail?.level !== ele?.level)))
                setPost(filteredPost);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setPost([]);
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }

    }

    //get complaint data on initial load
    async function fetchComplaintDetails() {
        try {
            if (selectedComplaint) {
                setShowLoader(true);
                const serverResponse = await officeCommunication.getComplaintById(selectedComplaint);
                if (serverResponse?.data?.status === "SUCCESS") {
                    fetchSubDepartmentWisePosts(serverResponse.data.complaint.subDepartmentId);
                    setComplaintDetails(serverResponse.data.complaint)
                    setFilteredLogDetail(serverResponse.data.complaint?.logs[0])
                    setData({
                        ...data, departmentId: serverResponse.data.complaint?.departmentId, subDepartmentId: serverResponse.data.complaint?.subDepartmentId
                    });
                    // serverResponse.data.complaint?.logs?.forEach((ele) => {
                    //     if (ele?.action === "Escalate") {
                    setIsEscalated(serverResponse.data.complaint?.isComplaintEscalated);
                    //     }
                    // })
                    setShowLoader(false);
                } else if (serverResponse?.data?.status === "JWT_INVALID") {
                    navigate("/login");
                    swal({ text: serverResponse?.data?.message, icon: "warning" });
                    setShowLoader(false);
                } else {
                    setComplaintDetails({});
                    setShowLoader(false);
                }
            }
        } catch (error) {
            setShowLoader(false);
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Log index wise filter detail
    const logsWiseDetail = (index) => {
        const filteredLog = complaintDetails?.logs[index];
        setFilteredLogDetail(filteredLog);
        setLogActive(index);
    }
    //preview file
    const previewFile = async (fileUrl) => {
        try {
            if (fileUrl) {
                const serverResponse = await officeCommunication.previewComplaintAttachment(fileUrl);
                const splitUrl = fileUrl.split(".");
                const extension = splitUrl[splitUrl.length - 1];
                //Create a Blob from the PDF Stream
                const file = new Blob([serverResponse.data], {
                    // type: "application/pdf"
                    type: extension === "pdf" ? "application/pdf" : "image/jpg"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
            } else {
                swal({ text: "File Not Found", icon: "warning" });
            }

        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Preview Attached Document
    const preveiwAttachedFile = (file) => {
        const fileUrl = URL.createObjectURL(file);
        window.open(fileUrl);
    }

    //Remove added document from document Array
    const removeDocument = (index) => {
        const newDocumentArray = documentArray?.filter((ele, id) => (index !== id));
        setDocumentArray(newDocumentArray);
    }

    //Add Multiple Documents
    const addDocuments = () => {
        //check Validation 
        if (!fileValidator.allValid()) {
            showFileValidationMessage(true);
            return false;
        }
        setDocumentArray([...documentArray, documents]);
        setDocuments({ documentName: "", attachement: "" })
        //Clearing input value
        inputRef.current.value = null
    }

    //Mark Resolve Complaint
    const resolveComplaint = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            if (documentArray.length === 0) {
                swal({ text: "Please Attached document", icon: "warning" });
                return false;
            }

            setShowLoader(true);

            const complaintData = {
                complaintId: complaintDetails?.complaintId,
                solution: data?.narration
            }



            let isFileAttached = false;
            let formData = new FormData();
            if (documentArray.length > 0) {
                let newDocumentArray = [];
                // add document name to files

                for (let index = 0; index < documentArray.length; index++) {
                    const element = documentArray[index];
                    const extension = element.attachement.name.split(".").pop();
                    element.attachement.documentName = `${element["documentName"]}.${extension}`;
                    newDocumentArray.push(element.attachement);
                }
                //  convert all documents to form data
                newDocumentArray.forEach((ele) => {
                    formData.append('attachment', ele, ele.documentName);
                });
                formData.append("complaintData", JSON.stringify(complaintData));
                isFileAttached = true
            } else {
                formData = complaintData
            }

            const serverResponse = await officeCommunication.resolveComplaint(isFileAttached, formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideComplaintDetail());
                getComplaints();
                if (setShowForm) {
                    setShowForm(false)
                }
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            }
        } catch (error) {
            setShowLoader(false);
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    //Close Complaint
    const closeComplaint = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            setShowLoader(true);
            const complaintData = {
                complaintId: complaintDetails?.complaintId,
                rating: data?.rating,
                feedback: data?.feedback
            }
            const serverResponse = await officeCommunication.closeComplaint(complaintData);
            if (serverResponse?.data?.status === "SUCCESS") {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideComplaintDetail());
                if (((fullUrl[5] === "resolved-complaints") && (userDetail?.userType === "customer care"))) {
                    fetchAllResolvedComplaints();
                } else {
                    getComplaints();
                }
                if (setShowForm) {
                    setShowForm(false)
                }
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            }
        } catch (error) {
            setShowLoader(false);
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Assign Complaint
    const assignComplaint = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            setShowLoader(true);
            const assignData = {
                complaintId: complaintDetails?.complaintId,
                assignTo: data?.asignTo,
                departmentId: data?.departmentId,
                subDepartmentId: data?.subDepartmentId,
                postId: data?.postId,
                comment: data?.narration
            }

            let isFileAttached = false;
            let formData = new FormData();
            if (documentArray.length > 0) {
                let newDocumentArray = [];
                // add document name to files

                for (let index = 0; index < documentArray.length; index++) {
                    const element = documentArray[index];
                    const extension = element.attachement.name.split(".").pop();
                    element.attachement.documentName = `${element["documentName"]}.${extension}`;
                    newDocumentArray.push(element.attachement);
                }
                //  convert all documents to form data
                newDocumentArray.forEach((ele) => {
                    formData.append('attachment', ele, ele.documentName);
                });
                isFileAttached = true
                formData.append("assignData", JSON.stringify(assignData));
            } else {
                formData = assignData
            }

            const serverResponse = await officeCommunication.assignComplaint(isFileAttached, formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideComplaintDetail());
                getComplaints();
                if (setShowForm) {
                    setShowForm(false)
                }
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            }
        } catch (error) {
            setShowLoader(false);
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    //Re-Open complaint
    const reopenComplaint = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            setShowLoader(true);
            const serverResponse = await officeCommunication.reopenComplaint(complaintDetails?.complaintId, data?.reOpenReason);
            if (serverResponse?.data?.status === "SUCCESS") {
                setShowLoader(false);
                if (((fullUrl[5] === "resolved-complaints") && (userDetail?.userType === "customer care"))) {
                    fetchAllResolvedComplaints();
                } else {
                    getComplaints();
                }
                if (setShowForm) {
                    setShowForm(false)
                }
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideComplaintDetail());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            }
        } catch (error) {
            setShowLoader(false);
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    //Reject Complaint 
    const rejectComplaint = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            setShowLoader(true);
            const serverResponse = await officeCommunication.rejectComplaint(complaintDetails?.complaintId, data?.rejectionReason);
            if (serverResponse?.data?.status === "SUCCESS") {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideComplaintDetail());
                getComplaints();
                if (setShowForm) {
                    setShowForm(false)
                }
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setShowLoader(false);
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    useEffect(() => {
        fetchComplaintDetails();
        fetchAllDepartments();
    }, []);

    return (
        <div className="complaint_detail_section" style={{ backgroundColor: `${theme === "WHITE" ? "rgba(0, 0, 0, 0.521)" : "#4444448e"}` }}>
            {showLoader === true ?
                <Loader />
                :

                <div className="dashboard_form" style={{ backgroundColor: `${theme === "WHITE" ? "#fff" : "rgb(48, 48, 48)"}`, boxShadow: `${theme === "WHITE" ? "none" : "0px 0px 8px #575757"}` }}>
                    <div className="form_tabs">
                        <div className={`tab ${active === "personal_detail" ? "personal_detail" : ""}`} onClick={() => setActive("personal_detail")}>
                            <H4>Personal Detail</H4>
                        </div>
                        <div className={`tab ${active === "complaint_detail" ? "complaint_detail" : ""}`} onClick={() => setActive("complaint_detail")}>
                            <H4>Complaint Detail</H4>
                        </div>
                        <div className='close_complaint_model' onClick={() => { dispatch(hideComplaintDetail()); setShowForm(false) }}>
                            <span style={{ color: `${theme === "WHITE" ? "#000" : "#fff"}` }}><i className="fa-solid fa-xmark"></i></span>
                        </div>
                    </div>
                    {isEscalated &&
                        <div className="escalated_msg">
                            <h6 style={{ color: theme === "WHITE" ? "red" : "rgb(231, 158, 63)" }}>*This Ticket has been Escalated to Collector Administrative</h6>
                        </div>
                    }
                    {active === "personal_detail" ?
                        <div className="personal_detail_form">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>First Name</H5>
                                    <InputField type="text" name="firstName" value={complaintDetails?.personalDetails?.firstName} disabled />
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>Last Name</H5>
                                    <InputField type="text" name="lastName" value={complaintDetails?.personalDetails?.lastName} disabled />
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>Email</H5>
                                    <InputField type="email" name="complaintType" value={complaintDetails?.personalDetails?.email} disabled />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>Mobile Number</H5>
                                    <InputField type="number" name="mobileNumber" value={complaintDetails?.personalDetails?.mobileNumber} disabled />
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>District</H5>
                                    <InputField type="text" name="district" value={complaintDetails?.personalDetails?.district} disabled />
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>Pin Code</H5>
                                    <InputField type="number" name="pinCode" value={complaintDetails?.personalDetails?.pinCode} disabled />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <H5>Area Type</H5>
                                    <div className="row mt-1">
                                        <div className="col-lg-6 col-md-6 input_field">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="areaType" value={complaintDetails?.personalDetails?.areaType} checked={complaintDetails?.personalDetails?.areaType === "urban" ? true : false} id="flexRadioDefault1" disabled />
                                                <label className="form-check-label">
                                                    <H5>Urban</H5>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 input_field">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="areaType" value={complaintDetails?.personalDetails?.areaType} checked={complaintDetails?.personalDetails?.areaType === "rural" ? true : false} id="flexRadioDefault1" disabled />
                                                <label className="form-check-label">
                                                    <H5>Rural</H5>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {complaintDetails?.personalDetails?.areaType === "urban" ?
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <H5>City</H5>
                                        <InputField type="text" name="district" value={complaintDetails?.personalDetails?.city} disabled />
                                    </div>
                                    :
                                    <>
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <H5>Tehsil</H5>
                                            <InputField type="text" name="tehsil" value={complaintDetails?.personalDetails?.panchayatSamiti} disabled />
                                        </div>
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <H5>Gram Panchayat</H5>
                                            <InputField type="text" name="tehsil" value={complaintDetails?.personalDetails?.gramPanchayat} disabled />
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="row">
                                <div className="col-lg-8 col-md-8 input_field">
                                    <H5>Landmark</H5>
                                    <textarea name="landmark" value={complaintDetails?.personalDetails?.landmark} className="form-control" disabled />
                                </div>
                            </div>
                            <div className="button_group">
                                <Button name="Next" onClick={() => setActive("complaint_detail")} />
                            </div>
                        </div>
                        :
                        <div className="complaint_info_form">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>Complaint Type</H5>
                                    <InputField type="text" name="complaintType" value={complaintDetails?.complaintType} disabled />
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>Department</H5>
                                    <InputField type="text" name="department" value={complaintDetails?.departmentName} disabled />
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>Sub Department</H5>
                                    <InputField type="text" name="subDepartment" value={complaintDetails?.subDepartmentName} disabled />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4 input_field">
                                    <H5>Post Level</H5>
                                    <InputField type="text" name="post" value={complaintDetails?.level ? complaintDetails?.level : ""} disabled />
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <H5>Area Type</H5>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-md-6 input_field">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="areaType" value={complaintDetails?.areaType} checked={complaintDetails?.areaType === "urban" ? true : false} id="flexRadioDefault1" disabled />
                                                <label className="form-check-label">
                                                    <H5>Urban</H5>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 input_field">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="areaType" value={complaintDetails?.areaType} checked={complaintDetails?.areaType === "rural" ? true : false} id="flexRadioDefault1" disabled />
                                                <label className="form-check-label">
                                                    <H5>Rural</H5>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {complaintDetails?.areaType === "urban" ?
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <H5>City</H5>
                                        <InputField type="text" name="district" value={complaintDetails?.city} disabled />
                                    </div>
                                    :
                                    <>
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <H5>Tehsil</H5>
                                            <InputField type="text" name="tehsil" value={complaintDetails?.panchayatSamiti} disabled />
                                        </div>
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <H5>Gram Panchayat</H5>
                                            <InputField type="text" name="tehsil" value={complaintDetails?.gramPanchayat} disabled />
                                        </div>
                                    </>
                                }
                                <div className="col-lg-8 col-md-8 input_field">
                                    <H5>Complaint Detail</H5>
                                    <textarea className='form-control complaint_detail_textarea' disabled>{complaintDetails?.complaintDescription}</textarea>
                                </div>
                                {
                                    complaintDetails.attachment &&
                                    <div className="col-lg-4 col-md-4 attachment_wraper">
                                        <div className="attachement">
                                            <H5>Attachment : </H5>
                                            <button title="Attachment uploaded by Applicant" onClick={() => previewFile(complaintDetails.attachment)}>View <i className="fa-regular fa-file-lines"></i></button>
                                        </div>
                                    </div>
                                }
                            </div>
                            {complaintDetails?.logs?.length > 0 &&
                                <div className="complaint_log_section">
                                    <h5>Complaint Logs</h5>
                                    <div className="complaint_log_main" style={{ border: `1px solid ${theme === "WHITE" ? "rgb(206, 206, 206)" : "#8887C9"}` }}>
                                        <div className="log_list" style={{ borderRight: `1px solid ${theme === "WHITE" ? "rgb(206, 206, 206)" : "#8887C9"}` }}>
                                            <div className="log_date">
                                                <h5 style={{ color: `${theme === "WHITE" ? "#8887c9" : "#fff"}` }}>Date & Time</h5>
                                            </div>

                                            {complaintDetails?.logs?.map((logList, index) => (
                                                <div className="log_date">
                                                    <span className={`${logActive !== index ? "" : "log_active"}`} style={{ color: `${theme === "WHITE" ? "rgb(95, 85, 83)" : "#fff"}` }} onClick={() => logsWiseDetail(index)} key={index}><span>{index + 1})</span>{new Date(logList?.actionDate).toDateString()}<span>{new Date(logList?.actionDate).toLocaleTimeString()}</span></span>
                                                </div>
                                            ))
                                            }
                                        </div>
                                        <div className="complaint_log_detail" style={{ backgroundColor: `${theme === "WHITE" ? "#e9ecef" : "rgb(48, 48, 48)"}` }}>
                                            <div className="log_status">
                                                <div className="log">
                                                    <H5>Action</H5>
                                                </div>
                                                <div className="log">
                                                    <H5>User Type</H5>
                                                </div>
                                                <div className="log">
                                                    <H5>Attachment</H5>
                                                </div>
                                            </div>
                                            <div className="log_status_data">
                                                <div className="log">
                                                    <H6>{filteredLogDetail?.action}</H6>
                                                </div>
                                                <div className="log">
                                                    <H6>{filteredLogDetail?.userType}</H6>
                                                    {filteredLogDetail?.userType?.toLowerCase() === "department user" &&
                                                        <>
                                                            <H6>Department Name - {filteredLogDetail?.departmentName}</H6>
                                                            <H6>Sub Department Name - {filteredLogDetail?.subDepartmentName}</H6>
                                                        </>
                                                    }
                                                </div>
                                                <div className="log attachment">
                                                    {filteredLogDetail?.documentArray?.length > 0 ?
                                                        <>
                                                            {filteredLogDetail?.documentArray?.map((documents, index) => (
                                                                <H6 key={index}>{documents?.documentName} - <span title="File Preview" onClick={() => previewFile(documents?.attachment)}><i className="fa-solid fa-paperclip"></i></span></H6>
                                                            ))}
                                                        </>
                                                        :
                                                        <H6>-</H6>
                                                    }
                                                </div>
                                            </div>
                                            <div className="log_comment">
                                                <H5>Narration [Comment] :</H5>
                                                <div className="comment_div" style={{ border: `1px solid ${theme === "WHITE" ? "rgb(206, 206, 206)" : "#8887C9"}` }}>
                                                    <H6>{filteredLogDetail?.comment}</H6>
                                                </div>
                                            </div>
                                            {complaintDetails?.departmentStatus === "closed" &&
                                                <div className="mt-3">
                                                    <H5>User Rating :</H5>
                                                    <div className="show_rating">
                                                        {complaintDetails?.rating >= 1 ?
                                                            <i className="fa-solid fa-star checked"></i>
                                                            :
                                                            <i className="fa-regular fa-star unchecked"></i>
                                                        }
                                                        {complaintDetails?.rating >= 2 ?
                                                            <i className="fa-solid fa-star checked"></i>
                                                            :
                                                            <i className="fa-regular fa-star unchecked"></i>
                                                        }
                                                        {complaintDetails?.rating >= 3 ?
                                                            <i className="fa-solid fa-star checked"></i>
                                                            :
                                                            <i className="fa-regular fa-star unchecked"></i>
                                                        }
                                                        {complaintDetails?.rating >= 4 ?
                                                            <i className="fa-solid fa-star checked"></i>
                                                            :
                                                            <i className="fa-regular fa-star unchecked"></i>
                                                        }
                                                        {complaintDetails?.rating === 5 ?
                                                            <i className="fa-solid fa-star checked"></i>
                                                            :
                                                            <i className="fa-regular fa-star unchecked"></i>
                                                        }
                                                    </div>
                                                </div >
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {show?.showNarrationSection &&
                                <>
                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-4">
                                            <H5>Document Name : </H5>
                                            <InputField type="text" name="documentName" value={documents.documentName} onChange={(event) => setDocuments({ ...documents, documentName: event.target.value })} placeholder="EX: Road Map" />
                                            {fileValidator.message("documentName", documents.documentName, "required", {
                                                messages: {
                                                    required: "Document Name is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <H5>Attachment : </H5>
                                            <input type="file" name="attachement" value={documents.attachement.filename} onChange={(event) => setDocuments({ ...documents, attachement: event.target.files[0] })} ref={inputRef} className="form-control" />
                                            {fileValidator.message("attachement", documents.attachement, "required", {
                                                messages: {
                                                    required: "File is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-lg-4 col-md-4 add_document_button">
                                            <Button name="Add" onClick={() => addDocuments()} />
                                        </div>
                                    </div>
                                    {documentArray?.map((item, index) => (
                                        <div className="row uploaded_document_list" key={index}>
                                            <div className="col-lg-1 col-md-1">
                                                <H5>{index + 1}</H5>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <H5>{item?.documentName}</H5>
                                            </div>
                                            <div className="col-lg-1 col-md-2">
                                                <span className="preview_icon" onClick={() => preveiwAttachedFile(item?.attachement)}><i className="fa-solid fa-paperclip" title="File Preview"></i></span>
                                            </div>
                                            <div className="col-lg-1 col-md-2">
                                                <span className="delete_icon" onClick={() => removeDocument(index)}><i className="fa-solid fa-trash" title="Delete"></i></span>
                                            </div>
                                        </div>
                                    ))}


                                    <div className="row">
                                        <div className="col-lg-8 col-md-8">
                                            <H5>Narration (Comment) :</H5>
                                            <textarea name="narration" onChange={(event) => setData({ ...data, narration: event.target.value })} className="form-control" placeholder="Enter Narration..."></textarea>
                                            {validator.message("narration", data.narration, "required", {
                                                messages: {
                                                    required: "Narration is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                            {show?.showAssignSection &&
                                <>
                                    <div className="row assign_section">
                                        <H5>Assign To:</H5>
                                        <div className="col-lg-4 col-md-4 mt-2 mb-2">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="sameDepartment" value="sameDepartment" onChange={(event) => setData({ ...data, asignTo: event.target.value })} checked={data.asignTo === "sameDepartment" ? true : false} id="flexRadioDefault1" />
                                                <label className="form-check-label">
                                                    <H5>Same Sub Department</H5>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 mt-2 mb-2">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="otherDepartment" value="otherDepartment" onChange={(event) => setData({ ...data, asignTo: event.target.value })} checked={data.asignTo === "otherDepartment" ? true : false} id="flexRadioDefault1" />
                                                <label className="form-check-label">
                                                    <H5>Change Department/Sub Department</H5>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-4 col-md-4">
                                            {data?.asignTo === "sameDepartment" &&
                                                <>
                                                    <select name="postId" onChange={(event) => setData({ ...data, postId: JSON.parse(event.target.value)?._id })} className="form-select">
                                                        <option value="">Select Post</option>
                                                        {post?.map((postList, index) => (
                                                            <option value={JSON.stringify(postList)} key={index}>{`${postList?.postName} (${postList?.level})`}</option>
                                                        ))}
                                                    </select>
                                                    {validator.message("postId", data.postId, "required", {
                                                        messages: {
                                                            required: "Post is required"
                                                        }
                                                    })}
                                                </>
                                            }
                                            {data.asignTo === "otherDepartment" &&
                                                <>
                                                    <select name="departmentId" onChange={(event) => { setData({ ...data, departmentId: JSON.parse(event.target.value)?._id }); getDepartmentWiseSubDepartments(JSON.parse(event.target.value)?._id) }} className="form-select">
                                                        <option value="">Select Department </option>
                                                        {department?.map((departmentList, index) => (
                                                            <option value={JSON.stringify(departmentList)} key={index}>{departmentList?.departmentName}</option>
                                                        ))}
                                                    </select>
                                                    {validator.message("departmentId", data.departmentId, "required", {
                                                        messages: {
                                                            required: "Department is required"
                                                        }
                                                    })}
                                                </>
                                            }
                                        </div>
                                        {data.asignTo === "otherDepartment" &&
                                            <div className="col-lg-4 col-md-4">
                                                <select name="subDepartmentId" onChange={(event) => setData({ ...data, subDepartmentId: JSON.parse(event.target.value)?._id })} className="form-select">
                                                    <option value="">Select Sub Department </option>
                                                    {subDepartment?.map((subDepartmentList, index) => (
                                                        <option value={JSON.stringify(subDepartmentList)} key={index}>{subDepartmentList?.subDepartmentName}</option>
                                                    ))}
                                                </select>
                                                {validator.message("subDepartmentId", data.subDepartmentId, "required", {
                                                    messages: {
                                                        required: "Sub Department is required"
                                                    }
                                                })}
                                            </div>
                                        }

                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-lg-4 col-md-4">
                                            <H5>Document Name : </H5>
                                            <InputField type="text" name="documentName" value={documents.documentName} onChange={(event) => setDocuments({ ...documents, documentName: event.target.value })} placeholder="EX: Road Map" />
                                            {fileValidator.message("documentName", documents.documentName, "required", {
                                                messages: {
                                                    required: "Document Name is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <H5>Attachment : </H5>
                                            <input type="file" name="attachement" value={documents.attachement.filename} onChange={(event) => setDocuments({ ...documents, attachement: event.target.files[0] })} ref={inputRef} className="form-control" />
                                            {fileValidator.message("attachement", documents.attachement, "required", {
                                                messages: {
                                                    required: "File is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-lg-4 col-md-4 add_document_button">
                                            <Button name="Add" onClick={() => addDocuments()} />
                                        </div>
                                    </div>
                                    {documentArray?.map((item, index) => (
                                        <div className="row uploaded_document_list" key={index}>
                                            <div className="col-lg-1 col-md-1">
                                                <H5>{index + 1}</H5>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <H5>{item?.documentName}</H5>
                                            </div>
                                            <div className="col-lg-1 col-md-2">
                                                <span className="preview_icon" onClick={() => preveiwAttachedFile(item?.attachement)}><i className="fa-solid fa-paperclip" title="File Preview"></i></span>
                                            </div>
                                            <div className="col-lg-1 col-md-2">
                                                <span className="delete_icon" onClick={() => removeDocument(index)}><i className="fa-solid fa-trash" title="Delete"></i></span>
                                            </div>
                                        </div>
                                    ))}


                                    <div className="row">
                                        <div className="col-lg-8 col-md-8">
                                            <H5>Narration (Comment) :</H5>
                                            <textarea name="narration" onChange={(event) => setData({ ...data, narration: event.target.value })} className="form-control" placeholder="Enter Narration..."></textarea>
                                            {validator.message("narration", data.narration, "required", {
                                                messages: {
                                                    required: "Narration is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                            {show?.showReOpenSection &&
                                <div className="row mb-4">
                                    <div className="col-lg-8 col-md-8">
                                        <H5>Why User Wants to Re-open?</H5>
                                        <textarea name="reOpenReason" onChange={(event) => setData({ ...data, reOpenReason: event.target.value })} className="form-control mt-1 mb-3" placeholder="Enter Reason..." />
                                        {validator.message("reOpenReason", data.reOpenReason, "required", {
                                            messages: {
                                                required: "Reason is required"
                                            }
                                        })}
                                        <Button name="Re-Open" onClick={reopenComplaint} />
                                    </div>
                                </div>
                            }
                            {show?.showRatingSection &&
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div className="col-lg-8 col-md-8">
                                            <H5>Give Us Rating :</H5>
                                            <div className="rating">
                                                <input type="radio" name="rating" value="5" id="5" onChange={(event) => setData({ ...data, rating: event.target.value })} /><label for="5">☆</label>
                                                <input type="radio" name="rating" value="4" id="4" onChange={(event) => setData({ ...data, rating: event.target.value })} /><label for="4">☆</label>
                                                <input type="radio" name="rating" value="3" id="3" onChange={(event) => setData({ ...data, rating: event.target.value })} /><label for="3">☆</label>
                                                <input type="radio" name="rating" value="2" id="2" onChange={(event) => setData({ ...data, rating: event.target.value })} /><label for="2">☆</label>
                                                <input type="radio" name="rating" value="1" id="1" onChange={(event) => setData({ ...data, rating: event.target.value })} /><label for="1">☆</label>
                                            </div>
                                            {validator.message("rating", data.rating, "required", {
                                                messages: {
                                                    required: "Rating is required"
                                                }
                                            })}
                                            <textarea name="feedback" value={data.feedback} onChange={(event) => setData({ ...data, feedback: event.target.value })} className="form-control mt-1 mb-2" style={{ color: `${theme === "WHITE" ? "#5F5553" : "#ffffff"}` }} placeholder="User Feedback..."></textarea>
                                            {validator.message("feedback", data.feedback, "required", {
                                                messages: {
                                                    required: "Feedback is required"
                                                }
                                            })}
                                            <Button name="Close" onClick={closeComplaint} />
                                        </div >
                                    </div >
                                </div >
                            }
                            {show?.showRejectSection &&
                                <div className="row mb-4">
                                    <div className="col-lg-8 col-md-8">
                                        <H5>Rejection Reason?</H5>
                                        <textarea name="rejectionReason" onChange={(event) => setData({ ...data, rejectionReason: event.target.value })} className="form-control mt-1 mb-1" placeholder="Enter Reason..." />
                                        {validator.message("rejectionReason", data.rejectionReason, "required", {
                                            messages: {
                                                required: "Reason is required"
                                            }
                                        })}
                                    </div>
                                </div>
                            }
                            <div className="button_group">
                                {["closed", "rejected"].includes(complaintDetails.complaintStatus) ? <></>
                                    :
                                    <>
                                        {(((["admin"].includes(userDetail?.userType)) && (["complaint-lookups", "accelated-complaints", "notifications"].includes(fullUrl[5])) && (complaintDetails.departmentStatus === "resolved")) || ((fullUrl[5] === "resolved-complaints") && (complaintDetails.departmentStatus === "resolved"))) &&
                                            <>
                                                {show?.showRatingSection === false &&
                                                    <Button name="Re-Open" onClick={() => setShow({ showReOpenSection: true })} />
                                                }
                                            </>
                                        }
                                        {(((fullUrl[5] === "department-complaints") && (["department user", "super user"].includes(userDetail?.userType)) && (complaintDetails.departmentStatus !== "resolved")) || ((userDetail?.userType === "admin") && (["complaint-lookups", "accelated-complaints", "notifications"].includes(fullUrl[5])) && (complaintDetails.departmentStatus !== "resolved"))) &&
                                            <>
                                                {show?.showAssignSection === false &&
                                                    <Button name="Resolve" onClick={() => { setShow({ showNarrationSection: true }) }} />
                                                }
                                            </>
                                        }
                                        {(((fullUrl[5] === "department-complaints") && (["department user", "super user"].includes(userDetail?.userType))) || (userDetail?.userType === "admin") && (["complaint-lookups", "accelated-complaints", "notifications"].includes(fullUrl[5]))) &&
                                            <>
                                                {show?.showNarrationSection === true &&
                                                    <Button name="Mark Resolve" onClick={() => { resolveComplaint(); }} />
                                                }
                                            </>
                                        }
                                        {((fullUrl[5] === "resolved-complaints") && (userDetail?.userType === "customer care") && (complaintDetails.departmentStatus === "resolved")) &&
                                            <>
                                                {show?.showRatingSection === false &&
                                                    <Button name="Close" onClick={() => setShow({ showRatingSection: true })} />
                                                }
                                            </>
                                        }
                                        {((["admin"].includes(userDetail?.userType)) && (["complaint-lookups", "accelated-complaints", "notifications"].includes(fullUrl[5]))) &&
                                            <>
                                                {show?.showRejectSection === false &&
                                                    <Button name="Reject" onClick={() => setShow({ showRejectSection: true })} />
                                                }
                                            </>
                                        }
                                        {((["admin"].includes(userDetail?.userType)) && (["complaint-lookups", "accelated-complaints", "notifications"].includes(fullUrl[5]))) &&
                                            <>
                                                {show?.showRejectSection === true &&
                                                    <Button name="Reject" onClick={() => { rejectComplaint() }} />
                                                }
                                            </>
                                        }
                                        {(((["admin", "customer care"].includes(userDetail?.userType)) && (["complaint-lookups", "accelated-complaints", "notifications"].includes(fullUrl[5])) && (complaintDetails.departmentStatus !== "resolved")) || ((fullUrl[5] === "department-complaints") && (complaintDetails.departmentStatus !== "resolved"))) &&
                                            <>
                                                {
                                                    show.showAssignSection === false &&
                                                    <Button name="ReAssign" onClick={() => setShow({ showAssignSection: true })} />
                                                }
                                            </>
                                        }

                                        {(((["admin", "customer care"].includes(userDetail?.userType)) && (["complaint-lookups", "accelated-complaints", "notifications"].includes(fullUrl[5]))) || (fullUrl[5] === "department-complaints")) &&
                                            <>
                                                {
                                                    (show.showAssignSection === true) &&
                                                    <Button name="Assign" onClick={assignComplaint} />
                                                }
                                            </>
                                        }
                                    </>}

                                <Button name="Cancel" onClick={() => { dispatch(hideComplaintDetail()); setShowForm(false) }} />
                            </div>
                        </div>
                    }

                </div >
            }
        </div >
    )
}

export default ComplaintDetail