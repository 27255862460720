import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import H4 from "../text-components/H4";
import H5 from "../text-components/H5";
import swal from 'sweetalert';
import { showLoader, hideLoader } from "../redux-store/loaderReducer";
import { officeCommunication } from "../services/officeCommunication";
import useValidator from "../utilities/useValidator";
import Loader from '../utilities/Loader';
import InputField from './InputField';

const UserProfile = () => {
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const userId = useSelector((state) => state.userDetails.userId);
    const [validator, showValidationMessage] = useValidator();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isdisabled, setIsDisabled] = useState(true);
    const [userDetails, setUserDetails] = useState({});
    const [imgBlob, setImgBlob] = useState("");
    const [profileImg, setProfileImg] = useState("");
    const [accessDepartmentList, setAccessDepartmentList] = useState([]);

    console.log(userDetails);
    //get login userDetails on initial load
    const getUserDetails = async () => {
        try {
            //call api
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getUserByUserId(userId)
            if (serverResponse?.data?.status === "SUCCESS") {
                setUserDetails(serverResponse?.data?.usersDetails);
                setAccessDepartmentList(serverResponse?.data?.usersDetails?.users);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setUserDetails({});
                setAccessDepartmentList([]);
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Update user details
    //get login userDetails on initial load
    const updateUser = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            //call api
            dispatch(showLoader());

            const serverResponse = await officeCommunication.editProfile(userDetails)
            if (serverResponse?.data?.status === "SUCCESS") {
                setIsDisabled(true);
                getUserDetails();
                setIsDisabled(true);
                swal({ text: serverResponse.data.message, icon: "success" });
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                swal({ text: serverResponse.data.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }


    //update user profile image
    const updateProfileImg = async () => {
        try {
            //call api
            dispatch(showLoader());
            const formData = new FormData();
            formData.append("profile", profileImg);
            const serverResponse = await officeCommunication.updateProfileImg(formData)
            if (serverResponse?.data?.status === "SUCCESS") {
                setProfileImg("");
                getProfileImage();
                swal({ text: serverResponse.data.message, icon: "success" });
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                swal({ text: serverResponse.data.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }
    async function getProfileImage() {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getUserProfileImage();
            if (serverResponse.data && serverResponse.data.type === "text/xml") {

                //Create a Blob from the PDF Stream
                const file = new Blob([serverResponse.data], {
                    type: "image/jpg"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                setImgBlob(pdfBlob);
            }
            dispatch(hideLoader());

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };

    useEffect(() => {
        getUserDetails();
        getProfileImage();
    }, []);
    return (
        <div className="user_profile_section">
            {loader === true ?
                <Loader />
                :
                <>
                    {userDetails?.userType === "super user" &&
                        <div className="user_profile" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                            <div className="icon_main" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                                {imgBlob ?
                                    <img className="img-fluid" src={imgBlob} alt="profile" />
                                    :
                                    <div>
                                        <i className="fa-solid fa-user"></i>
                                    </div>
                                }
                                <div className="edit_profile">
                                    <input type="file" name="file" id="profile" onChange={(event) => setProfileImg(event.target.files[0])} className="form-control d-none" />
                                    <label for="profile"><i className="fa-solid fa-pen-to-square"></i></label>
                                </div>
                            </div>
                            {profileImg &&
                                < div className="upload_profile">
                                    <H5>Profile Image</H5>
                                    <button className="button" onClick={() => updateProfileImg()} >Upload</button>
                                </div>
                            }
                            <div className="user_id">
                                <H4>User Id -{userId}</H4>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3">
                                    <H5>User Name</H5>
                                    <input type="text" name="firstName" value={userDetails?.userName} onChange={(event) => setUserDetails({ ...userDetails, userName: event.target.value })} className="form-control" placeholder="Enter First Name" disabled={isdisabled} />
                                    {validator.message("userName", userDetails?.userName, "required", {
                                        messages: {
                                            required: "userName is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <H5>E-mail Address</H5>
                                    <input type="email" name="email" value={userDetails?.email} onChange={(event) => setUserDetails({ ...userDetails, email: event.target.value })} className="form-control" placeholder="Enter Email" disabled={isdisabled} />
                                    {validator.message("email", userDetails.email, "required|email", {
                                        messages: {
                                            required: "Email is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <H5>Mobile Number</H5>
                                    <input type="number" name="mobileNumber" value={userDetails?.mobileNumber} onChange={(event) => setUserDetails({ ...userDetails, mobileNumber: event.target.value })} className="form-control" placeholder="Enter Mobile Number" disabled={isdisabled} />
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <H5>User Type</H5>
                                    <input type="email" name="email" value={userDetails?.userType} onChange={(event) => setUserDetails({ ...userDetails, email: event.target.value })} className="form-control" placeholder="Enter Email" disabled />
                                </div>
                            </div>

                            <div className="row mt-4 mb-2">
                                <div className="col-lg-3 col-md-3">
                                    <H5><b>user Id</b></H5>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <H5><b>Department</b></H5>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <H5><b>Sub Department</b></H5>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <H5><b>Post</b></H5>
                                </div>
                            </div>
                            <div className="list">
                                {accessDepartmentList?.map((users, index) => (
                                    <div className="row mb-1" key={index}>
                                        <div className="col-lg-3 col-md-3">
                                            <InputField value={users?.userId} disabled />
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <InputField value={users?.departmentName} disabled />
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <InputField value={users?.subDepartmentName} disabled />
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <InputField value={users?.postName} disabled />
                                        </div>
                                    </div>
                                ))}
                            </div>


                            <div className="button_group mt-4">
                                {isdisabled === true ?
                                    <button className="button" onClick={() => setIsDisabled(false)}>Edit</button>
                                    :
                                    <button className="button" onClick={() => updateUser()} >Update</button>
                                }
                                <button className="button" onClick={() => navigate(-1)}>Cancel</button>
                            </div>
                        </div>
                    }
                    {["department user", "admin"].includes(userDetails?.userType) &&
                        <div className="user_profile" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                            <div className="icon_main" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                                {imgBlob ?
                                    <img className="img-fluid" src={imgBlob} alt="profile" />
                                    :
                                    <div>
                                        <i className="fa-solid fa-user"></i>
                                    </div>
                                }
                                <div className="edit_profile">
                                    <input type="file" name="file" id="profile" onChange={(event) => setProfileImg(event.target.files[0])} className="form-control d-none" />
                                    <label for="profile"><i className="fa-solid fa-pen-to-square"></i></label>
                                </div>
                            </div>
                            {profileImg &&
                                < div className="upload_profile">
                                    <H5>Profile Image</H5>
                                    <button className="button" onClick={() => updateProfileImg()} >Upload</button>
                                </div>
                            }
                            <div className="user_id">
                                <H4>User Id -{userId}</H4>
                            </div>
                            <div className="row profile_input_row">
                                <div className="col-lg-6 col-md-6">
                                    <H5>User Name</H5>
                                    <input type="text" name="firstName" value={userDetails?.userName} onChange={(event) => setUserDetails({ ...userDetails, userName: event.target.value })} className="form-control" placeholder="Enter First Name" disabled={isdisabled} />
                                    {validator.message("userName", userDetails?.userName, "required", {
                                        messages: {
                                            required: "userName is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <H5>E-mail Address</H5>
                                    <input type="email" name="email" value={userDetails?.email} onChange={(event) => setUserDetails({ ...userDetails, email: event.target.value })} className="form-control" placeholder="Enter Email" disabled={isdisabled} />
                                    {validator.message("email", userDetails.email, "required|email", {
                                        messages: {
                                            required: "Email is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="row profile_input_row">
                                <div className="col-lg-6 col-md-6">
                                    <H5>Mobile Number</H5>
                                    <input type="number" name="mobileNumber" value={userDetails?.mobileNumber} onChange={(event) => setUserDetails({ ...userDetails, mobileNumber: event.target.value })} className="form-control" placeholder="Enter Mobile Number" disabled={isdisabled} />
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <H5>User Type</H5>
                                    <input type="email" name="email" value={userDetails?.userType} onChange={(event) => setUserDetails({ ...userDetails, email: event.target.value })} className="form-control" placeholder="Enter Email" disabled />
                                </div>
                            </div>
                            {userDetails?.userType === "department user" &&
                                <>
                                    <div className="row profile_input_row">
                                        <div className="col-lg-6 col-md-6">
                                            <H5>Department</H5>
                                            <input type="text" name="department" value={userDetails?.departmentName} className="form-control" disabled />
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <H5>Sub Department</H5>
                                            <input type="text" name="subDepartment" value={userDetails?.subDepartmentName} className="form-control" disabled />
                                        </div>
                                    </div>
                                    <div className="row profile_input_row">
                                        <div className="col-lg-6 col-md-6">
                                            <H5>Post</H5>
                                            <input type="text" name="post" value={userDetails?.postName} className="form-control" disabled />
                                        </div>
                                    </div>
                                </>

                            }

                            <div className="button_group">
                                {isdisabled === true ?
                                    <button className="button" onClick={() => setIsDisabled(false)}>Edit</button>
                                    :
                                    <button className="button" onClick={() => updateUser()} >Update</button>
                                }
                                <button className="button" onClick={() => navigate(-1)}>Cancel</button>
                            </div>
                        </div>
                    }
                </>
            }
        </div >
    )
}

export default UserProfile