import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import H3 from '../../../../text-components/H3';
import H5 from '../../../../text-components/H5';
import { showComplaintDetail } from "../../../../redux-store/showComplaintDetailReducer";
import ComplaintDetail from '../../../../common-components/ComplaintDetail';
import swal from 'sweetalert';
import { hideLoader, showLoader } from '../../../../redux-store/loaderReducer';
import { useEffect } from 'react';
import { officeCommunication } from '../../../../services/officeCommunication';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Loader from '../../../../utilities/Loader';

const Notifications = () => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const showComplaintForm = useSelector((state) => state.showComplaintDetailReducer.formState);
    const [toggleOptions, setToggleOptions] = useState(false);
    const [notificationId, setNotificationId] = useState("");
    const [selectedComplaint, setSelectedComplaint] = useState("");
    const [notificationArry, setNotificationArry] = useState([]);
    const navigate = useNavigate();

    const setNotificationMarkAsSeen = useOutletContext();

    //Mark as Read Notification
    const markAsRead = async (notificationId) => {
        dispatch(showLoader());
        try {
            const serverResponse = await officeCommunication.notificationMarkRead(notificationId);
            if (serverResponse?.data?.status === "SUCCESS") {
                setNotificationMarkAsSeen(true);
                setToggleOptions(false);
                adminNotification();
                swal({ text: serverResponse?.data?.message, icon: "success" })
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse?.data?.message, icon: "warning" })
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" })
        }

    }

    //Delete Notification
    const deleteNotification = async (notificationId) => {
        dispatch(showLoader());
        try {
            const serverResponse = await officeCommunication.notificationDelete(notificationId);
            if (serverResponse?.data?.status === "SUCCESS") {
                adminNotification();
                swal({ text: serverResponse?.data?.message, icon: "success" })
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse?.data?.message, icon: "warning" })
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" })
        }
    }

    // get admin notification in intial load
    const adminNotification = async () => {
        dispatch(showLoader());
        try {
            const serverResponse = await officeCommunication.getAdminNotification();
            if (serverResponse?.data?.status === "SUCCESS") {
                setNotificationArry(serverResponse?.data?.notification);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setNotificationArry([]);
                dispatch(hideLoader());
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" })
        }
    }

    useEffect(() => {
        adminNotification();
    }, [])

    return (
        <div className="panel" onClick={() => setToggleOptions(!toggleOptions)}>
            {loader === true ? <Loader /> : <>
                <div className="search_header">
                    <H3>Notifications</H3>
                </div>
                {notificationArry?.length > 0 ?
                    <>
                        <div className="table_main">
                            {
                                notificationArry.map((notificationData, index) => {
                                    return (
                                        <div className="notification_card" style={{ backgroundColor: `${(notificationData?.isSeen === false && theme === "WHITE") ? "#F1F1FF" : "transparent"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }} key={index}>
                                            <div className="row m-0">
                                                <div className="col-lg-1 col-md-1 bell_icon" style={{ color: `${notificationData?.isSeen === false ? "#8887C9" : "#B5B5B5"}` }}>
                                                    <i className="fa-solid fa-bell"></i>
                                                </div>
                                                <div className="col-lg-10 col-md-10 content">
                                                    <H5>Complaint Id - <span onClick={() => { dispatch(showComplaintDetail()); setSelectedComplaint(notificationData?.complaintId) }} title="Click to See Complaint Detail">{notificationData?.complaintId}</span>,  Department - {notificationData?.departmentName}, Sub-Department - {notificationData?.subDepartmentName} has been Escalated to you.</H5>
                                                </div>
                                                <div className="col-lg-1 col-md-1 option_icon">
                                                    <span onClick={() => {setToggleOptions(!toggleOptions); setNotificationId(notificationData?._id)}} title="Options"><i className="fa-solid fa-ellipsis-vertical"></i></span>
                                                </div>
                                            </div>
                                            {(toggleOptions && (notificationId === notificationData?._id)) &&
                                                <div className="notification_options" style={{ backgroundColor: `${theme === "WHITE" ? "#fff" : "#3A3939"}` }}>
                                                    {notificationData?.isSeen === false &&
                                                        <div className="tab" onClick={() => markAsRead(notificationData?._id)}>
                                                            <H5>Mark as read</H5>
                                                        </div>
                                                    }
                                                    <div className="tab" onClick={() => deleteNotification(notificationData?._id)}>
                                                        <H5>Delete</H5>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </> :
                    <div className='empty_notification'>
                        <H3>Notification is not available</H3>
                    </div>

                }
                {showComplaintForm &&
                    <ComplaintDetail selectedComplaint={selectedComplaint} />
                }

            </>}
        </div>
    )
}

export default Notifications