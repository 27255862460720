export const socialMediaLink = [
    {
        "id": 1,
        "icon": "fa-brands fa-twitter",
        "link": "https://twitter.com/InfoChandrapur"
    },
    {
        "id": 2,
        "icon": "fa-brands fa-facebook-f",
        "link": "https://www.facebook.com/InfoChandrapur"
    },
    {
        "id": 3,
        "icon": "fa-brands fa-blogger-b",
        "link": "https://diochanda1.blogspot.com/"
    },
    {
        "id": 4,
        "icon": "fa-brands fa-instagram",
        "link": "https://instagram.com/collector.chanda"
    },
]