import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InputField from '../../../../common-components/InputField';
import H4 from '../../../../text-components/H4';
import H5 from '../../../../text-components/H5';
import useValidator from '../../../../utilities/useValidator';
import swal from 'sweetalert';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from "../../../../redux-store/loaderReducer";
import Loader from "../../../../utilities/Loader";
import { city } from '../../../../utilities/cityArray';
import { gramPanchayat } from "../../../../utilities/gramPanchayatArray"

const RaiseComplaint = () => {
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchUrl] = useSearchParams();
    const [validator, showValidationMessage] = useValidator();
    const [department, setDepartment] = useState([]);
    const [subDepartment, setSubDepartment] = useState([]);
    const [post, setPost] = useState([]);
    const [active, setActive] = useState("personal_detail");
    const [gramPanchayatList, setGramPanchayatList] = useState([]);
    const [personalDetail, setPersonalDetail] = useState(
        {
            firstName: "",
            lastName: "",
            email: "",
            district: "Chandrapur",
            areaType: "",
            city: "",
            panchayatSamiti: "",
            gramPanchayat: "",
            pinCode: "",
            landmark: "",
            mobileNumber: ""
        }
    );

    const [complaintData, setComplainData] = useState({
        complaintType: "",
        departmentId: "",
        subDepartmentId: "",
        postId: "",
        areaType: "",
        panchayatSamiti: "",
        gramPanchayat: "",
        attachement: "",
        complaintDescription: ""
    });

    //Get all departments on initial load
    const fetchAllDepartments = async () => {
        try {
            const responseFromServer = await officeCommunication.getDepartments();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDepartment(responseFromServer?.data?.department);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Get department wise sub-departments
    const getDepartmentWiseSubDepartments = async (departmentId) => {
        try {
            if (departmentId) {
                const responseFromServer = await officeCommunication.getSubDepartmentByDepartment(departmentId)
                if (responseFromServer?.data?.status === "SUCCESS") {
                    setSubDepartment(responseFromServer?.data?.subdepartment);
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    navigate("/login");
                    swal({ text: responseFromServer?.data?.message, icon: "warning" });
                } else {
                    swal({ text: responseFromServer?.data?.message, icon: "warning" });
                }
            } else {
                return false;
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Get sub-department wise posts
    const getSubDepartementWisePosts = async (postId) => {
        try {
            if (postId) {
                const responseFromServer = await officeCommunication.getPostBySubDepartment(postId);
                if (responseFromServer?.data?.status === "SUCCESS") {
                    setPost(responseFromServer?.data?.post);
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    navigate("/login");
                    swal({ text: responseFromServer?.data?.message, icon: "warning" });
                } else {
                    swal({ text: responseFromServer?.data?.message, icon: "warning" });
                }
            } else {
                return false;
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Get Panchayat Samiti wise Gram Panchayat List
    const panchayatSamitiWiseGramPanchayat = (panchayatSamiti) => {
        const filteredGrampanchayat = gramPanchayat?.filter((ele) => (ele?.panchayatSamiti === panchayatSamiti))
        setGramPanchayatList(filteredGrampanchayat[0]?.gramPanchayat);
    }
    //Save Personal Detail
    const savePersonalDetail = () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            setActive("complaint_detail");
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    }


    //Submit Complaint
    const SubmitComplaint = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            //Convert File into Form Data
            const formData = new FormData();
            let isFileAttached = false;
            if (complaintData?.attachement) {
                formData.append("attachment", complaintData?.attachement);
                isFileAttached = true;
            }

            const dataToSend = { personalDetail, complaintData }
            let serverResponse;
            if (isFileAttached === true) {
                formData.append("complaintData", JSON.stringify(dataToSend));
                serverResponse = await officeCommunication.raiseComplaint(isFileAttached, formData)
            } else {
                serverResponse = await officeCommunication.raiseComplaint(isFileAttached, dataToSend)

            }
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "success" });
                setPersonalDetail({
                    firstName: "",
                    lastName: "",
                    email: "",
                    district: "Chandrapur",
                    areaType: "",
                    city: "",
                    panchayatSamiti: "",
                    gramPanchayat: "",
                    pinCode: "",
                    landmark: "",
                    mobileNumber: ""
                });
                setComplainData({
                    complaintType: "",
                    departmentId: "",
                    subDepartmentId: "",
                    postId: "",
                    areaType: "",
                    panchayatSamiti: "",
                    gramPanchayat: "",
                    attachement: "",
                    complaintDescription: ""
                })
                setActive("personal_detail");
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }

    }

    //Preview File
    const filePreview = () => {
        if (complaintData?.attachement) {
            const previewDocument = URL.createObjectURL(complaintData?.attachement);
            window.open(previewDocument);
        }
    }

    useEffect(() => {
        fetchAllDepartments();
    }, []);

    return (
        <div className="raise_complaint_section">
            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                    <div className="form_tabs">
                        <div className={`tab ${active === "personal_detail" ? "personal_detail" : ""}`} onClick={() => setActive("personal_detail")}>
                            <H4>Personal Detail</H4>
                        </div>
                        <div className={`tab ${active === "complaint_detail" ? "complaint_detail" : ""}`} onClick={() => setActive("complaint_detail")}>
                            <H4>Complaint Detail</H4>
                        </div>
                    </div>
                    {active === "personal_detail" &&
                        <div className="personal_detail_form">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 input_field">
                                    <InputField type="text" name="firstName" value={personalDetail?.firstName} onChange={(event) => setPersonalDetail({ ...personalDetail, firstName: event.target.value })} placeholder="First Name*" />
                                    {validator.message("firstName", personalDetail.firstName, "required", {
                                        messages: {
                                            required: "First Name is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <InputField type="text" name="lastName" value={personalDetail?.lastName} onChange={(event) => setPersonalDetail({ ...personalDetail, lastName: event.target.value })} placeholder="Last Name*" />
                                    {validator.message("lastName", personalDetail.lastName, "required", {
                                        messages: {
                                            required: "Last Name is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <InputField type="email" name="email" value={personalDetail?.email} onChange={(event) => setPersonalDetail({ ...personalDetail, email: event.target.value })} placeholder="Email" />
                                    {/* {validator.message("email", personalDetail.email, "email", {
                                        messages: {
                                            required: "Email is required"
                                        }
                                    })} */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 input_field">
                                    <InputField type="number" name="mobileNumber" value={personalDetail.mobileNumber} onChange={(e) => setPersonalDetail({ ...personalDetail, mobileNumber: e.target.value })} placeholder="Mobile Number*" />
                                    {validator.message("mobileNumber", personalDetail.mobileNumber, "required|numeric|min:10|max:10", {
                                        messages: {
                                            required: "Mobile Number is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <InputField type="text" name="district" value={personalDetail?.district} onChange={(event) => setPersonalDetail({ ...personalDetail, district: event.target.value })} placeholder="District" disabled />
                                </div>
                                <div className="col-lg-4 col-md-4 input_field">
                                    <InputField type="number" name="pinCode" value={personalDetail?.pinCode} onChange={(event) => setPersonalDetail({ ...personalDetail, pinCode: event.target.value })} placeholder="Pin Code*" />
                                    {validator.message("pinCode", personalDetail.pinCode, "required|numeric|min:6|max:6", {
                                        messages: {
                                            required: "Pin Code is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-md-2 input_field">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="areaType" value="urban" checked={personalDetail?.areaType === "urban" ? true : false} onChange={(event) => setPersonalDetail({ ...personalDetail, areaType: event.target.value })} id="flexRadioDefault1" />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            <H5>Urban</H5>
                                        </label>
                                    </div>
                                    {validator.message("areaType", personalDetail.areaType, "required", {
                                        messages: {
                                            required: "Area Type is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-2 col-md-2 input_field">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="areaType" value="rural" checked={personalDetail?.areaType === "rural" ? true : false} onChange={(event) => setPersonalDetail({ ...personalDetail, areaType: event.target.value })} id="flexRadioDefault1" />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            <H5>Rural</H5>
                                        </label>
                                    </div>
                                </div>
                                {personalDetail?.areaType === "urban" &&
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <select name="city" onChange={(event) => setPersonalDetail({ ...personalDetail, city: event.target.value })} className="form-select">
                                            <option value="">Select City*</option>
                                            {city?.map((cityName, index) => (
                                                <option value={cityName} selected={cityName === personalDetail.city ? true : false} key={index}>{cityName}</option>
                                            ))}
                                        </select>
                                        {validator.message("city", personalDetail.city, "required", {
                                            messages: {
                                                required: "City is required"
                                            }
                                        })}
                                    </div>
                                }
                                {personalDetail?.areaType === "rural" &&
                                    <>
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <select name="panchayatSamiti" onChange={(event) => { setPersonalDetail({ ...personalDetail, panchayatSamiti: event.target.value }); panchayatSamitiWiseGramPanchayat(event.target.value) }} className="form-select">
                                                <option value="">Select Tehsil*</option>
                                                {gramPanchayat?.map((panchayatSamitiList, index) => (
                                                    <option value={panchayatSamitiList?.panchayatSamiti} selected={panchayatSamitiList?.panchayatSamiti === personalDetail.panchayatSamiti ? true : false} key={index}>{panchayatSamitiList?.panchayatSamiti}</option>
                                                ))}
                                            </select>
                                            {validator.message("panchayatSamiti", personalDetail.panchayatSamiti, "required", {
                                                messages: {
                                                    required: "Panchayat Samiti is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <select name="grampanchayat" onChange={(event) => setPersonalDetail({ ...personalDetail, gramPanchayat: event.target.value })} className="form-select">
                                                <option value="">Select Grampanchayat*</option>
                                                {gramPanchayatList?.map((gramPanchayat, index) => (
                                                    <option value={gramPanchayat} selected={gramPanchayat === personalDetail.gramPanchayat ? true : false} key={index}>{gramPanchayat}</option>
                                                ))}
                                            </select>
                                            {validator.message("grampanchayat", personalDetail.gramPanchayat, "required", {
                                                messages: {
                                                    required: "Gram Panchayat is required"
                                                }
                                            })}
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="row">
                                <div className="col-lg-8 col-md-8 input_field">
                                    <textarea name="landmark" value={personalDetail?.landmark} className="form-control" onChange={(event) => setPersonalDetail({ ...personalDetail, landmark: event.target.value })} placeholder="Landmark*" style={{ color: `${theme === "WHITE" ? "#8887c9" : "#fff"}` }} />
                                    {validator.message("landmark", personalDetail.landmark, "required", {
                                        messages: {
                                            required: "Landmark is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="button_group">
                                <button className="button" onClick={savePersonalDetail}>Save & Next</button>
                            </div>
                        </div>
                    }
                    {active === "complaint_detail" &&
                        <div className="complaint_detail_form">
                            <div className="row mb-lg-4 mb-md-4 mb-0">
                                <div className="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-2">
                                    <select name="complaintType" onChange={(event) => setComplainData({ ...complaintData, complaintType: event.target.value })} className="form-select" >
                                        <option value="">Complaint Type*</option>
                                        <option value="Administrative" selected={complaintData?.complaintType === "Administrative" ? true : false}>Administrative</option>
                                    </select>
                                    {validator.message("complaintType", complaintData?.complaintType, "required", {
                                        messages: {
                                            required: "Complaint Type is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-4 col-md-4 mb-lg-0 mb-md-0 mb-2">
                                    <select name="department" onChange={(event) => { setComplainData({ ...complaintData, departmentId: JSON.parse(event.target.value)._id }); getDepartmentWiseSubDepartments(JSON.parse(event.target.value)._id) }} className="form-select" >
                                        <option value=""> Select Department*</option>
                                        {department?.map((departmentList, index) => (
                                            <option value={JSON.stringify(departmentList)} selected={departmentList?._id === complaintData?.departmentId} key={index}>{departmentList?.departmentName}</option>
                                        ))}
                                    </select>
                                    {validator.message("department", complaintData?.departmentId, "required", {
                                        messages: {
                                            required: "Department is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-4 col-md-4 mb-lg-0 mb-md-0 mb-2">
                                    <select name="subDepartment" onChange={(event) => { setComplainData({ ...complaintData, subDepartmentId: JSON.parse(event.target.value)._id }); getSubDepartementWisePosts(JSON.parse(event.target.value)._id) }} className="form-select" >
                                        <option value="">Sub Department Name*</option>
                                        {subDepartment?.map((subDepartmentList, index) => (
                                            <option value={JSON.stringify(subDepartmentList)} selected={subDepartmentList?._id === complaintData?.subDepartmentId} key={index}>{subDepartmentList?.subDepartmentName}</option>
                                        ))}
                                    </select>
                                    {validator.message("subDepartment", complaintData?.subDepartmentId, "required", {
                                        messages: {
                                            required: "Sub Department is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-2 attachement_field">
                                    <select name="post" onChange={(event) => setComplainData({ ...complaintData, postId: JSON.parse(event.target.value)._id })} className="form-select" >
                                        <option value="">Select Post*</option>
                                        {post?.map((postList, index) => (
                                            <option value={JSON.stringify(postList)} selected={postList?._id === complaintData?.postId} key={index}>{`${postList?.postName} (${postList?.level})`}</option>
                                        ))}
                                    </select>
                                    {validator.message("post", complaintData?.postId, "required", {
                                        messages: {
                                            required: "Post is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-2 col-md-2 input_field">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="areaType" value="urban" checked={complaintData?.areaType === "urban" ? true : false} onChange={(event) => setComplainData({ ...complaintData, areaType: event.target.value })} id="flexRadioDefault1" />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            <H5>Urban</H5>
                                        </label>
                                    </div>
                                    {validator.message("areaType", complaintData.areaType, "required", {
                                        messages: {
                                            required: "Area Type is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-2 col-md-2 input_field">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="areaType" value="rural" checked={complaintData?.areaType === "rural" ? true : false} onChange={(event) => setComplainData({ ...complaintData, areaType: event.target.value })} id="flexRadioDefault1" />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            <H5>Rural</H5>
                                        </label>
                                    </div>
                                </div>
                                {complaintData?.areaType === "urban" &&
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <select name="city" onChange={(event) => setComplainData({ ...complaintData, city: event.target.value })} className="form-select">
                                            <option value="">Select City*</option>
                                            {city?.map((cityName, index) => (
                                                <option value={cityName} selected={cityName === complaintData.city ? true : false} key={index}>{cityName}</option>
                                            ))}
                                        </select>
                                        {validator.message("city", complaintData.city, "required", {
                                            messages: {
                                                required: "City is required"
                                            }
                                        })}
                                    </div>
                                }
                                {complaintData?.areaType === "rural" &&
                                    <>
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <select name="panchayatSamiti" onChange={(event) => { setComplainData({ ...complaintData, panchayatSamiti: event.target.value }); panchayatSamitiWiseGramPanchayat(event.target.value) }} className="form-select">
                                                <option value="">Select Tehsil*</option>
                                                {gramPanchayat?.map((panchayatSamitiList, index) => (
                                                    <option value={panchayatSamitiList?.panchayatSamiti} selected={panchayatSamitiList?.panchayatSamiti === complaintData.panchayatSamiti ? true : false} key={index}>{panchayatSamitiList?.panchayatSamiti}</option>
                                                ))}
                                            </select>
                                            {validator.message("panchayatSamiti", complaintData.panchayatSamiti, "required", {
                                                messages: {
                                                    required: "Tehsil is required"
                                                }
                                            })}
                                        </div>
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <select name="grampanchayat" onChange={(event) => setComplainData({ ...complaintData, gramPanchayat: event.target.value })} className="form-select">
                                                <option value="">Select Grampanchayat*</option>
                                                {gramPanchayatList?.map((gramPanchayat, index) => (
                                                    <option value={gramPanchayat} selected={gramPanchayat === complaintData.gramPanchayat ? true : false} key={index}>{gramPanchayat}</option>
                                                ))}
                                            </select>
                                            {validator.message("grampanchayat", complaintData.gramPanchayat, "required", {
                                                messages: {
                                                    required: "Gram Panchayat is required"
                                                }
                                            })}
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="row">
                                <div className="col-lg-8 col-md-8 mb-lg-0 mb-md-0 mb-2">
                                    <textarea name="complaintDescription" value={complaintData?.complaintDescription} onChange={(event) => setComplainData({ ...complaintData, complaintDescription: event.target.value })} className="form-control" placeholder="Complaint Detail*" style={{ color: `${theme === "WHITE" ? "#8887c9" : "#fff"}` }} />
                                    {validator.message("complaintDescription", complaintData?.complaintDescription, "required|min:100", {
                                        messages: {
                                            required: "Complaint Description is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-2 attachement_field">
                                    <InputField type="file" name="attachement" onChange={(event) => setComplainData({ ...complaintData, attachement: event.target.files[0] })} placeholder="Attach" />
                                    <p style={{ color: "rgb(231, 158, 63)" }}>Allowed Format(pdf, jpg, jpeg, png & Size must be less than 2MB) </p>
                                    <h6>Upload the document while physical raising the complaint.</h6>
                                    {![undefined, null, ""].includes(complaintData?.attachement) &&
                                        <span onClick={filePreview} title="Attached Document Preview"><i className="fa-solid fa-paperclip preview"></i></span>
                                    }
                                </div>
                            </div>
                            <div className="button_group">
                                <button className="button" onClick={SubmitComplaint} >Submit Complaint</button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default RaiseComplaint;