import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import H3Public from '../../../text-components/H3Public';
import PPublic from '../../../text-components/PPublic';
import { scrollToTop } from '../../../utilities/scrollToTopFunction';

const ContactUs = () => {

  const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const scrollPage = useRef();
  const scroll = useSelector((state) => state.scrollToTop.scroll);

  useEffect(() => {
      scrollToTop(scrollPage);
  }, [scroll]);


  return (
    <>
      <div className="contact_main" ref={scrollPage}>
        <div className="container">
          <div className="row" style={{ backgroundColor: `${theme === "WHITE" ? "#5E7082" : "#1E1E20"}` }}>

            <div className="col-12 map_emabed">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3750.082450280695!2d79.29777271418344!3d19.963034528679557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd2d5cffffffff9%3A0xcef7904ad28646c9!2sOffice%20of%20District%20Collector%2C%20Chandrapur!5e0!3m2!1sen!2sin!4v1672988059403!5m2!1sen!2sin" width="100%" style={{ border: "0", margin: "auto", height: "60vh" }} loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='collector office address'></iframe>
            </div>

            <div className="col-12 location_content_box" >
              <div className='contact_details_box row'>
                <div className="col-md-4">
                  <H3Public>Phone</H3Public>
                  <a href="tel:18002338691"><PPublic>Toll Free : 1800-233-8691</PPublic></a>
                </div>
                <div className="col-md-4">
                  <H3Public>Email</H3Public>
                  <a href="mailto:vandemataramchanda@gmail.com"> <PPublic>vandemataramchanda@gmail.com</PPublic> </a>
                </div>
                <div className="col-md-4">
                  <H3Public>Address</H3Public>
                  <PPublic>X77X+6X8, Railway Station Road, near District Court,
                    Chauhan Colony, Chandrapur, Maharashtra 442401</PPublic>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default ContactUs