import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import InputField from '../../../../common-components/InputField';
import H3 from '../../../../text-components/H3';
import useValidator from '../../../../utilities/useValidator';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../../utilities/Loader';
import H5 from '../../../../text-components/H5';

const CreateSuperUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const [hideShowPassword, setHideShowPassword] = useState({ password: true, confirmPassword: true });
    const [validator, showValidationMessage] = useValidator();
    const [listValidator, showListValidationMessage] = useValidator();
    const [departments, setDepartments] = useState([]);
    const [subDepartments, setSubDepartments] = useState([]);
    const [posts, setPosts] = useState([]);
    const [users, setUsers] = useState([]);

    //get all input box values
    const [userDetails, setUserDetails] = useState(
        {
            userName: "",
            mobileNumber: "",
            email: "",
            file: "",
            password: "",
        }
    );

    const [usersData, setUsersData] = useState(
        {
            departmentName: "",
            departmentId: "",
            subDepartmentName: "",
            subDepartmentId: "",
            postName: "",
            userId: ""
        }
    );

    const [userList, setUserList] = useState([]);
    const [userIdArray, setUserIdArray] = useState([]);

    //fetch Department details on initial load
    async function fetchDepartment() {
        try {
            dispatch(showLoader());
            //call API 
            const serverResponse = await officeCommunication.getDepartments();
            if (serverResponse.data.status === "SUCCESS") {
                setDepartments(serverResponse.data.department);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setDepartments([]);
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    };

    //get department wise subdepartment
    const getDepartmentWiseSubDepartment = async (departmentId) => {
        try {
            if (departmentId === "") {
                return;
            } else {

                const serverResponse = await officeCommunication.getSubDepartmentByDepartment(departmentId);
                if (serverResponse?.data?.status === "SUCCESS") {
                    setSubDepartments(serverResponse?.data?.subdepartment);
                } else if (serverResponse?.data?.status === "JWT_INVALID") {
                    navigate("/login");
                    swal({ text: serverResponse?.data?.message, icon: "warning" });
                } else {
                    setSubDepartments([]);
                    swal({ text: serverResponse?.data?.message, icon: "warning" });
                }
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    };


    //get sub department wise post
    const getSubDepartmentWisePost = async (subDepartmentId) => {
        try {
            if (subDepartmentId === "") {
                return;
            } else {
                const serverResponse = await officeCommunication.getPostBySubDepartment(subDepartmentId);
                if (serverResponse?.data?.status === "SUCCESS") {
                    setPosts(serverResponse?.data?.post);
                } else if (serverResponse?.data?.status === "JWT_INVALID") {
                    navigate("/login");
                    swal({ text: serverResponse?.data?.message, icon: "warning" });
                } else {
                    setPosts([]);
                    swal({ text: serverResponse?.data?.message, icon: "warning" });
                }
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    };

    //get Post wise Users
    const getPostWiseUsers = async (postId) => {
        try {
            const responseFromServer = await officeCommunication.postwiseUsers(usersData?.departmentId, usersData?.subDepartmentId, postId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setUsers(responseFromServer?.data?.users);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                setUsers([]);
            }
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    }

    //Add Multiple Users
    const addUsers = () => {
        try {

            //check Validation 
            if (!listValidator.allValid()) {
                showListValidationMessage(true);
                return false;
            }
            setUserList([...userList, usersData]);
            setUserIdArray([...userIdArray, usersData.userId]);
            setUsersData({
                departmentName: "",
                subDepartmentName: "",
                postName: "",
                userId: ""
            });
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //Remove User
    const removeUser = (userId) => {
        const filteredUserList = userList?.filter((ele) => userId !== ele?.userId);
        setUserList(filteredUserList);
        const filteredUserId = userIdArray?.filter((ele) => userId !== ele);
        setUserIdArray(filteredUserId);
    }

    //Create User 
    const createSuperUser = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            if (userList?.length < 2) {
                swal({ text: "Minimum 2 Users Required to Create Super User", icon: "warning" });
                return;
            }
            dispatch(showLoader());
            const formData = new FormData();
            formData.append("profile", userDetails.file);

            const dataToSend = {
                userName: userDetails?.userName,
                mobileNumber: Number(userDetails?.mobileNumber),
                email: userDetails?.email,
                password: userDetails?.password,
                usersToAdd: userIdArray
            }
            formData.append("userData", JSON.stringify(dataToSend));
            const serverResponse = await officeCommunication.createSuperUser(formData);
            if (serverResponse?.data?.status === "SUCCESS") {
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideLoader());
                navigate("/dashboard/super-user-management")
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
                navigate("/login");
            } else {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    // File Preview
    const previewFile = async () => {
        try {
            const previewDocument = URL.createObjectURL(userDetails.file);
            window.open(previewDocument);

        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchDepartment();
    }, []);

    return (
        <div className="dashboard_form_section super_user_management_form">
            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                    <div className="from_title">
                        <H3>Create Super User</H3>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 input_field">
                            <InputField type="text" name="userName" value={userDetails.userName} onChange={(event) => setUserDetails({ ...userDetails, userName: event.target.value })} placeholder="Enter User Name" />
                            {validator.message("userName", userDetails.userName, "required", {
                                messages: {
                                    required: "UserName is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-4 input_field">
                            <InputField type="number" name="mobileNumber" value={userDetails.mobileNumber} onChange={(event) => setUserDetails({ ...userDetails, mobileNumber: event.target.value })} placeholder="Enter Mobile Number" />
                            {validator.message("mobileNumber", userDetails.mobileNumber, "required|numeric|min:10|max:10", {
                                messages: {
                                    required: "Mobile Number is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-4 input_field">
                            <InputField type="email" name="email" value={userDetails.email} onChange={(event) => setUserDetails({ ...userDetails, email: event.target.value })} placeholder="Enter Email" />
                            {validator.message("email", userDetails.email, "required|email", {
                                messages: {
                                    required: "Email is required"
                                }
                            })}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-4 input_field">
                            <InputField type={hideShowPassword.password ? "password" : "text"} name="password" value={userDetails.password} onChange={(event) => setUserDetails({ ...userDetails, password: event.target.value })} placeholder="Enter Password" />
                            <div className="show_pass_icon" onClick={() => setHideShowPassword({ ...hideShowPassword, password: !hideShowPassword.password })}>
                                <FontAwesomeIcon icon={!hideShowPassword.password ? faEyeSlash : faEye} />
                            </div>
                            {validator.message("password", userDetails.password, "required|min:8", {
                                messages: {
                                    required: "Password is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-4 input_field">
                            <InputField type={hideShowPassword.confirmPassword ? "password" : "text"} name="confirmPassword" value={userDetails.confirmPassword} onChange={(event) => setUserDetails({ ...userDetails, confirmPassword: event.target.value })} placeholder="Confirm Password" />
                            <div className="show_pass_icon" onClick={() => setHideShowPassword({ ...hideShowPassword, confirmPassword: !hideShowPassword.confirmPassword })}>
                                <FontAwesomeIcon icon={!hideShowPassword.confirmPassword ? faEyeSlash : faEye} />
                            </div>
                            {validator.message("confirmPassword", userDetails.confirmPassword, `required|in:${userDetails.password}`, {
                                messages: {
                                    required: "Confirm Password is required",
                                    in: "Password must be Match"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-4 input_field file_box">
                            <input type="file" name="file" id="file" onChange={(event) => setUserDetails({ ...userDetails, file: event.target.files[0] })} className="form-control d-none" />
                            <div className="profile_pic">
                                <div className="choose_file">
                                    <label for="file">Choose File</label>
                                </div>
                                <div className="file_label">
                                    <label for="file">{userDetails?.file === "" ? "Upload Profile Picture" : `${userDetails?.file?.name}`}</label>
                                </div>
                            </div>
                            {validator.message("file", userDetails.file, "required", {
                                messages: {
                                    required: "File is required"
                                }
                            })}
                            {
                                userDetails?.file &&
                                <div className='attachment_icon' onClick={() => previewFile()} >
                                    <i className="fa-solid fa-paperclip"></i>
                                </div>
                            }
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 input_field">
                            <select name="department" onChange={(event) => { setUsersData({ ...usersData, departmentName: JSON.parse(event.target.value).departmentName, departmentId: JSON.parse(event.target.value)._id }); getDepartmentWiseSubDepartment(JSON.parse(event.target.value)._id) }} className="form-select">
                                <option value={JSON.stringify({ _id: "", departmentName: "" })}>Select Department</option>
                                {departments.map((department, index) => {
                                    return (

                                        <option value={JSON.stringify(department)} selected={department.departmentName === usersData.departmentName} key={index}>{department.departmentName}</option>
                                    )
                                })}
                            </select>
                            {listValidator.message("department", usersData.departmentName, "required", {
                                messages: {
                                    required: "Department is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-4 input_field">
                            <select name="subDepartment" onChange={(event) => { setUsersData({ ...usersData, subDepartmentName: JSON.parse(event.target.value).subDepartmentName, subDepartmentId: JSON.parse(event.target.value)._id }); getSubDepartmentWisePost(JSON.parse(event.target.value)._id) }} className="form-select">
                                <option value={JSON.stringify({ _id: "", subDepartmentName: "" })}>Select Sub Department</option>
                                {subDepartments.map((subDepartment, index) => {
                                    return (
                                        <option value={JSON.stringify(subDepartment)} selected={subDepartment.subDepartmentName === usersData.subDepartmentName} key={index}>{subDepartment.subDepartmentName}</option>
                                    )
                                })}
                            </select>
                            {listValidator.message("subDepartment", usersData.subDepartmentName, "required", {
                                messages: {
                                    required: "Sub Department is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-4 input_field">
                            <select name="post" onChange={(event) => { setUsersData({ ...usersData, postName: JSON.parse(event.target.value).postName }); getPostWiseUsers(JSON.parse(event.target.value)._id) }} className="form-select">
                                <option value={JSON.stringify({ _id: "", postName: "" })}>Select post</option>
                                {posts.map((postDetails, index) => {
                                    return (
                                        <option value={JSON.stringify(postDetails)} selected={postDetails.postName === usersData.postName} key={index}>{postDetails.postName} ({postDetails.level})</option>
                                    )
                                })}
                            </select>
                            {listValidator.message("post", usersData.postName, "required", {
                                messages: {
                                    required: "Post is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 input_field">
                            <select name="userId" value={usersData?.userId} onChange={(event) => setUsersData({ ...usersData, userId: event.target.value })} className="form-select">
                                <option value="">Select User ID</option>
                                {users?.map((userList, index) => (
                                    <option value={userList?.userId} key={index}>{userList?.userId}</option>
                                ))}
                            </select>
                            {listValidator.message("userId", usersData.userId, "required", {
                                messages: {
                                    required: "User Id is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <button className="button" onClick={() => addUsers()}>Add</button>
                        </div>
                    </div>
                    {userList?.length !== 0 &&
                        <div className="added_user_list">
                            <div className="row mb-2">
                                <div className="col-lg-3 col-md-4">
                                    <H5>Department</H5>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <H5>Sub Department</H5>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <H5>Post</H5>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <H5>User Id</H5>
                                </div>
                            </div>
                            {userList?.map((users, index) => (
                                <div className="row mb-2" key={index}>
                                    <div className="col-lg-3 col-md-4">
                                        <InputField value={users?.departmentName} disabled />
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <InputField value={users?.subDepartmentName} disabled />
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <InputField value={users?.postName} disabled />
                                    </div>
                                    <div className="col-lg-3 col-md-4 mt-lg-0 mt-md-2 last_child">
                                        <InputField value={users?.userId} disabled />
                                        <div className="delete_icon" onClick={() => removeUser(users?.userId)}>
                                            <i className="fa-solid fa-trash-can"></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    <div className="button_group">
                        <button className="button" onClick={() => createSuperUser()}>Create</button>
                        <button className="button" onClick={() => navigate("/dashboard/super-user-management")}>Cancel</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default CreateSuperUser