import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import H5 from '../../../text-components/H5'
import HeaderText from '../../../text-components/HeaderText'
import TrackComplaintStatus from './TrackComplaintStatus';
import { showLoader, hideLoader } from '../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import { publicCommunication } from '../../../services/publicCommunication';


const ComplaintList = () => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [searchUrl] = useSearchParams();
    const navigate = useNavigate();
    const [trackComplaintStatus, setTrackComplaintStatus] = useState(false);
    const [complaints, setComplaints] = useState([]);
    const [selectedComplaintId, setSelectedComplaintId] = useState("")

    //get all complaint against the verify mobile no 
    async function getUserComplaints() {
        try {
            if (["", null, undefined].includes(searchUrl.get("mobile"))) {
                navigate("/");
                return;
            }
            dispatch(showLoader());
            const serverResponse = await publicCommunication.fetchAllComplaints(Number(searchUrl.get("mobile")));
            if (serverResponse.data.status === "SUCCESS") {
                setComplaints(serverResponse.data.complaint);
                dispatch(hideLoader());
            } else {
                setComplaints([]);
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    useEffect(() => {
        getUserComplaints();
    }, []);

    return (
        <div className="public_table_section">
            <div className="outer_wrapper">
                <div className="wrapper">
                    <div className="table_header">
                        <h6>Complaint List</h6>
                        <button onClick={() => navigate("/")}>Go Back</button>
                    </div>
                    <div className="public_table_main">
                        <table>
                            <thead>
                                <tr>
                                    <th><HeaderText>Serial Number</HeaderText></th>
                                    <th><HeaderText>Complaint Id</HeaderText></th>
                                    <th><HeaderText>Department</HeaderText></th>
                                    <th><HeaderText>Sub Department</HeaderText></th>
                                    <th><HeaderText>Complaint Type</HeaderText></th>
                                    <th><HeaderText>Area Type</HeaderText></th>
                                    <th><HeaderText>City / Grampanchayat</HeaderText></th>
                                    <th><HeaderText>Creation Date</HeaderText></th>
                                    <th><HeaderText>Last Updated Date</HeaderText></th>
                                    <th><HeaderText>Status</HeaderText></th>
                                </tr>
                            </thead>
                            {complaints.length > 0 ?
                                <>
                                    <tbody>
                                        {complaints?.map((complaint, index) => {
                                            return (
                                                <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }}>
                                                    <td><H5>{index + 1}</H5></td>
                                                    <td className="link" onClick={() => { setTrackComplaintStatus(true); setSelectedComplaintId(complaint.complaintId) }}><H5>{complaint.complaintId}</H5></td>
                                                    <td><H5>{complaint.departmentName}</H5></td>
                                                    <td><H5>{complaint.subDepartmentName}</H5></td>
                                                    <td><H5>{complaint.complaintType}</H5></td>
                                                    <td><H5>{complaint.personalDetails.areaType}</H5></td>
                                                    <td> {complaint.personalDetails.areaType === "urban" ? <H5>{complaint.personalDetails.city}</H5> : <H5>{complaint.personalDetails.gramPanchayat}</H5>}</td>
                                                    <td><H5>{new Date(complaint.creationDate).toLocaleDateString()}</H5></td>
                                                    <td><H5>{new Date(complaint.updateDate).toLocaleDateString()}</H5></td>
                                                    <td><H5>{complaint.complaintStatus}</H5></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </>
                                :
                                <tbody>
                                    <tr><td></td></tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><H5>You didn't raise Any complaint with this mobile Number</H5></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>

                            }

                        </table>
                    </div>
                </div>
            </div>
            {trackComplaintStatus &&
                <TrackComplaintStatus setTrackComplaintStatus={setTrackComplaintStatus} selectedComplaintId={selectedComplaintId} />
            }
        </div>
    )
}

export default ComplaintList