import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import H3 from '../../../../text-components/H3';
import useValidator from "../../../../utilities/useValidator";
import InputField from '../../../../common-components/InputField';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from '../../../../utilities/Loader';

const UpdateDepartment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);

    const [validator, showValidationMessage] = useValidator();
    //get query params
    const [searchUrl] = useSearchParams();

    //get all input box values
    const [departmentDetails, setDepartmentDetails] = useState(
        {
            departmentName: "",
            location: ""
        }
    )


    //get department details on initial load
    const getDepartmentDetails = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getDepartmentById(searchUrl.get("id"));
            if (serverResponse?.data?.status === "SUCCESS") {
                setDepartmentDetails(serverResponse?.data?.departmentDetails);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setDepartmentDetails({});
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };


    //Update Department
    const updateDepartment = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            dispatch(showLoader());
            const dataToSend = {
                departmentName: departmentDetails.departmentName,
                location: departmentDetails.location,
                departmentId: departmentDetails._id
            }
            const serverResponse = await officeCommunication.updateDepartment(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                swal({ text: serverResponse?.data?.message, icon: "success" });
                dispatch(hideLoader());
                navigate("/dashboard/department-management")
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }

    }

    useEffect(() => {
        getDepartmentDetails();
    }, [])


    return (
        <div className="dashboard_form_section">
            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                    <div className="from_title">
                        <H3>Update Department</H3>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <InputField type="text" name="department" value={departmentDetails.departmentName} onChange={(event) => setDepartmentDetails({ ...departmentDetails, departmentName: event.target.value  })} placeholder="Enter Department Name" />
                            {validator.message("department", departmentDetails.departmentName, "required", {
                                messages: {
                                    required: "Department is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <InputField type="text" name="location" value={departmentDetails.location} onChange={(event) => setDepartmentDetails({ ...departmentDetails, location: event.target.value  })} placeholder="Enter Location" />
                            {validator.message("location", departmentDetails.location, "required", {
                                messages: {
                                    required: "Location is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="button_group">
                        <button className="button" onClick={() => updateDepartment()}>Update</button>
                        <button className="button" onClick={() => navigate("/dashboard/department-management")}>Cancel</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default UpdateDepartment