import React, { useEffect, useRef } from 'react'
import { useSelector } from "react-redux";
import H3Public from '../../../text-components/H3Public';
import PPublic from '../../../text-components/PPublic';

const PrivacyPolicy = () => {
    
    const scrollPage = useRef();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const scroll = useSelector((state) => state.scrollToTop.scroll);
  
    const scrollToTop = () => {
      scrollPage?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  
    useEffect(() => {
      scrollToTop();
    }, [scroll]);

    return (
        <>
            <div className="about_page_main" ref={scrollPage}>

                <div className="about_page_content_main">

                    <div className="about_page_content_inner_main container-fluid" style={{ backgroundColor: `${theme === "WHITE" ? "#E1ECF3" : "#CBCBCB"}` }}>

                        <div className="about_page_content_heading" style={{ color: `${theme === "WHITE" ? "#394D61" : "#000"}` }} >
                            <h4>Privacy & Policy</h4>
                        </div>

                        <div className="about_page_content_heading_line">
                            <div className="underline_thik" style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#858282"}` }}></div>
                            <div className="underline_thin"></div>
                        </div>

                        <div className="row about_page_content_box">

                            <div className="col p-0 content_main">
                                <div>
                                    <H3Public>Privacy Policy</H3Public>
                                    <PPublic>As a general rule, this website does not collect Personal Information about you when you visit the site. You can generally visit the site without revealing Personal Information, unless you choose to provide such information.</PPublic>
                                    <H3Public>Copyright Policy</H3Public>
                                    <PPublic> This contents of this website may not be reproduced partially or fully, without due permission from Social Justice & Special Assistance Department, If referred to as a part of another publication, the source must be appropriately acknowledged. The contents of this website can not be used in any misleading or objectionable context.</PPublic>
                                    <H3Public>Hyperlinking Policy</H3Public>
                                    <PPublic>At many places in this website, you shall find links to other websites/portals. This links have been placed for your convenience. Social Justice & Special Assistance Department is not responsible for the contents and reliability of the linked websites and does not necessarily endorse the views expressed in them. Mere presence of the link or its listing on this website should not be assumed as endorsement of any kind. We cannot guarantee that these links will work all the time and we have no control over availability of linked pages.</PPublic>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>
        </>)
}

export default PrivacyPolicy