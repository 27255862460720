import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardTopHeader from './DashboardTopHeader';
import SideNav from './SideNav';
import ProfileNav from './ProfileNav';

const Dashboard = () => {
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [toggleProfile, setToggleProfile] = useState(false);
    const sideNavToggle = useSelector((state) => state.sideNavToggle);
    const [notificationMarkAsSeen, setNotificationMarkAsSeen] = useState(false);

    return (
        <div className="dashboard_section">
            <SideNav setToggleProfile={setToggleProfile} notificationMarkAsSeen={notificationMarkAsSeen} />
            <div className="panel_section" style={{ width: `${sideNavToggle.panelWidth}%`, backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#444444"}` }}>
                <DashboardTopHeader setToggleProfile={setToggleProfile} toggleProfile={toggleProfile} />
                <div className="routes_view" onClick={() => setToggleProfile(false)}>
                    {toggleProfile &&
                        <ProfileNav />
                    }
                    <Outlet context={setNotificationMarkAsSeen}></Outlet>
                </div>
            </div>
        </div>
    )
}

export default Dashboard