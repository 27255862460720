import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import H3Public from '../../../text-components/H3Public';
import { gallaryImg } from "../../../utilities/gallaryImg";

const GallarySection = () => {

  const [active, setActive] = useState("all");

  const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);



  const [img, setImg] = useState("/Home/Gallary/Corporation1.png");
  const [showImgBox, setShowImgBox] = useState(false);
  const [filteredGallery, setFilteredGallery] = useState([]);

  const gallryView = (imgUrl) => {
    setImg(imgUrl);
    setShowImgBox(true);
  }

  //Filter image gallery
  const filterImagesGallery = (imgType) => {
    setActive(imgType)
    if (imgType.toLowerCase() === "transport" || imgType.toLowerCase() === "medical" || imgType.toLowerCase() === "corporation") {
      const filteredImg = gallaryImg?.filter((ele) => {
        if (ele.type.toLowerCase() === imgType.toLowerCase()) {
          return ele;
        }
      });
      setFilteredGallery(filteredImg);
    } else {
      setFilteredGallery(gallaryImg);
    }
  }

  useEffect(() => {
    setFilteredGallery(gallaryImg);
  }, []);

  return (
    <>
      <div className="gallary_section"
        style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#1E1E20"}` }}
      >

        <div className="container-fluid">

          <div className="row gallary_main">

            <div className="col-lg-3 col-md-3">
              <div className="gallary_heading">
                <H3Public>Gallary</H3Public>
              </div>

              <div className="gallary_heading_line">
                <div className="underline_thik"
                  style={{ backgroundColor: `${theme === "WHITE" ? "#122C46" : "#413838"}` }}
                ></div>
                <div className="underline_thin"></div>
              </div>
            </div>

            <div className="col-lg-9 col-md-9">
              <div className='gallary_lable'>
                <ul>
                  <li className={active === "all" ? "all" : "" } onClick={() => { filterImagesGallery("all") }}> <H3Public>All</H3Public> </li>
                  <li className={active === "transport" ? "transport" : ""} onClick={() => { filterImagesGallery("transport") }}> <H3Public>Transport</H3Public></li>
                  <li className={active  === "medical" ? "medical" : ""} onClick={() => { filterImagesGallery("medical") }}>  <H3Public>Medical</H3Public></li>
                  <li className={active === "corporation" ? "corporation" : ""} onClick={() => { filterImagesGallery("corporation") }}>  <H3Public>Corporation</H3Public></li>
                </ul>
              </div>
            </div>

          </div>

          <div className="row gallary_row">
            {filteredGallery?.map((imgDetails, index) => {
              const { attribute, imgUrl } = imgDetails;
              return (
                <div className="col-md-4 gallary_box" key={index}>

                  <div className='gallary_img_box'>
                    <img id="galleryImg" src={imgUrl} alt={attribute} onClick={() => { gallryView(imgUrl, attribute) }} />
                  </div>

                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>

                </div>

              )
            })}



          </div>

        </div>
        {showImgBox &&
          <div className="modal_box">
            <span className="close" onClick={() => setShowImgBox(false)}>&times;</span>
            <img src={img} className="modal-content img-fluid" id="img01" alt="Medical First" />
          </div>
        }

      </div>
    </>
  )
}

export default GallarySection