import React from "react"
import { sliderOneLink, sliderTwoLink } from "../../../utilities/importantLink";
import { useSelector } from "react-redux";

const ImpLinkNew = () => {

    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);



    return (
        <>
            <div className="imp_link_main">

                <div className="imp_link_inner_main container-fluid" style={{ backgroundColor: `${theme === "WHITE" ? "#E1ECF3" : "#CBCBCB"}` }}>

                    <div className="imp_link_heading" style={{ color: `${theme === "WHITE" ? "#394D61" : "#000"}` }}>
                        <h4>Important Links</h4>
                    </div>

                    <div className="imp_link_heading_line">
                        <div className="underline_thik" style={{ backgroundColor: `${theme === "WHITE" ? "#A6CAE2" : "#858282"}` }}></div>
                        <div className="underline_thin"></div>
                    </div>

                    {/* <div className="row card_main_div">
                        {
                            importantLink.map((data, index) => {
                                const { name, link, img } = data;
                                return (
                                    <div className="col-md-6 " key={index}>
                                        <div className="imp_link_div">
                                            <div><img src={img} alt={name} /></div>
                                            <div><a href={link} target="_blank" rel="noopener noreferrer">{name}</a></div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                    <div className="row card_main_div">
                        <div className="col-6">
                            <div className="wraper">
                                <div className="first_slider">
                                    {sliderOneLink?.map((linkDetail, index) => (
                                        <div className="imp_link_div" key={index}>
                                            <div><img src={linkDetail?.img} alt={linkDetail?.name} /></div>
                                            <div className="title"><a href={linkDetail?.link} target="_blank" rel="noopener noreferrer">{linkDetail?.name}</a></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="wraper">
                                <div className="second_slider">
                                    {sliderTwoLink?.map((linkDetail, index) => (
                                        <div className="imp_link_div" key={index}>
                                            <div><img src={linkDetail?.img} alt={linkDetail?.name} /></div>
                                            <div className="title"><a href={linkDetail?.link} target="_blank" rel="noopener noreferrer">{linkDetail?.name}</a></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}
export default ImpLinkNew