import React, { useState, useRef, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useValidator from "../../../../utilities/useValidator";
import Button from '../../../../common-components/Button';
import InputField from '../../../../common-components/InputField';
import H3 from '../../../../text-components/H3';
import swal from 'sweetalert';
import H5 from '../../../../text-components/H5';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import { officeCommunication } from '../../../../services/officeCommunication';
import { publicCommunication } from '../../../../services/publicCommunication';



const MobileVerification = () => {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const Inp1Ref = useRef();
  const Inp2Ref = useRef();
  const Inp3Ref = useRef();
  const Inp4Ref = useRef();
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  const [validator, showValidationMessage] = useValidator();
  const [showOTPVerification, setShowOTPverification] = useState(false)
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    box1: "",
    box2: "",
    box3: "",
    box4: "",
    OTPValue: "",
    otpErrorMessage: "OTP mismatch",
    showOTPErrorMessage: false
  });

  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [disabled, setDisable] = useState(false);
  const [disabledOtp, setDisableOtp] = useState(false);
  const [time, setTime] = useState({ m: 4, s: 59 });


  //send OTP on mobile no 
  const sendMessage = async () => {
    try {

      //check Validation 
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }

      dispatch(showLoader());
      //call API 
      const serverResponse = await officeCommunication.sendOTP(mobile);
      if (serverResponse.data.status === "SUCCESS") {
        setShowOTPverification(true)
        setDisable(true)
        setDisableOtp(true)
        setTimeout(() => {
          setIsActive(false);
          setDisable(false)
          setDisableOtp(true)
          setTime((second) => { return { s: 59, m: 4 } });
        }, 300000)
        setIsActive(true);
        setIsPaused(false);

        swal({ text: serverResponse?.data?.message, icon: "success" });
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "warning" });

      };

    } catch (error) {
      swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
      dispatch(hideLoader());
    }
  };

  //Resend OTP on mobile no 
  const resendOTP = async () => {
    try {

      //check Validation 
      if (!validator.allValid()) {
        showValidationMessage(true);
        return false;
      }
      dispatch(showLoader());

      //Call API
      const serverResponse = await publicCommunication.resendOTP(Number(mobile));
      if (serverResponse.data.status === "SUCCESS") {
        setShowOTPverification(true)
        setDisable(true)
        setTimeout(() => {
          setIsActive(false);
          setDisable(false)
          setTime((second) => { return { s: 59, m: 4 } });
        }, 300000)
        setIsActive(true);
        setIsPaused(false);

        swal({ text: serverResponse?.data?.message, icon: "success" });
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "warning" });
      }
    } catch (error) {
      swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
      dispatch(hideLoader());
    }
  };


  const onlyNumberKey = (val) => {
    const rgx = /^[0-9]*$/;
    return val.match(rgx);
  };



  async function verifyOTP() {
    try {
      //check the length of otp
      if (state.OTPValue.length !== 4) {
        setState({ otpErrorMessage: "Enter 4 digit otp" });
        setState({ showOTPErrorMessage: true });
        return false;
      }

      dispatch(showLoader());
      //call API 
      const serverResponse = await officeCommunication.verifyOTP(mobile, Number(state.OTPValue));
      if (serverResponse.data.status === "SUCCESS") {
        navigate(`/dashboard/complaint/raise-complaint?mobileNumber=${mobile}`);
        dispatch(hideLoader());
      } else if (serverResponse.data.status === "EXPIRED") {
        setDisable(false)
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "warning" });
      } else {
        dispatch(hideLoader());
        swal({ text: serverResponse?.data?.message, icon: "warning" });
      }
    } catch (error) {
      swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
      dispatch(hideLoader());
    }
  }

  useEffect(() => {
    let interval = null;
    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        if (time.s === 0) {
          setTime((second) => { return { m: second.m - 1, s: 59 } });

        } else {
          setTime((second) => { return { ...second, s: (second.s - 1) } });
        }
      }, 1000)
    } else {
      clearInterval(interval)
    }
    return () => {
      clearInterval(interval)
    }
  }, [isActive, isPaused, time]);


  useEffect(() => {
    if (state.box1 || state.box2 || state.box3 || state.box4) {
      let OTPValue = `${state.box1}${state.box2}${state.box3}${state.box4}`;
      setState({
        OTPValue: OTPValue,
      });
    }
  }, [state.box1, state.box2, state.box3, state.box4]);



  return (
    <div className="dashboard_form_section mobile_verification">
      <div className="dashboard_form" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
        <div className="from_title">
          <H3>Mobile Verification</H3>
        </div>
        <div className="row">
          <div className="col-12 input_field">
            <InputField type="number" name="mobileNumber" onChange={(event) => setMobile(event.target.value)} placeholder="Enter Mobile Number" disabled={disabledOtp} />
            <Button name="Send" onClick={() => sendMessage()} disabled={disabledOtp} />
            {validator.message("mobileNumber", mobile, "required|numeric|min:10|max:10", {
              messages: {
                required: "Mobile Number is required"
              }
            })}
          </div>
          {showOTPVerification &&
            <div className="otp_verification_main">
              <H5>Enter 4 digit OTP we have sent on {mobile}</H5>
              <div className="otp_verification">
                <input type="number"
                  ref={Inp1Ref}
                  value={state.box1}
                  onChange={(e) => {
                    if (e.target.value) {
                      if (onlyNumberKey(e.target.value)) {
                        if (state.box1) {
                          let arr = String(e.target.value)
                            .split("")
                            .map((num) => {
                              return Number(num);
                            });
                          setState({ box2: arr[1] });
                          Inp2Ref.current.focus();
                        } else {
                          setState({ box1: e.target.value });
                          Inp2Ref.current.focus();
                        }
                      } else {
                        setState({ box1: "" });
                      }
                    } else {
                      setState({ box1: "" });
                    }
                  }}
                />
                <input type="number"
                  ref={Inp2Ref}
                  value={state.box2}
                  onKeyDown={(e) => {
                    if (!state.box2) {
                      if (e.key === "Backspace") {
                        Inp1Ref.current.focus();
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value) {

                      if (onlyNumberKey(e.target.value)) {
                        if (state.box2) {
                          let arr = String(e.target.value)
                            .split("")
                            .map((num) => {
                              return Number(num);
                            });
                          setState({ box3: arr[1] });
                          Inp3Ref.current.focus();
                        } else {
                          setState({ box2: e.target.value });
                          Inp3Ref.current.focus();
                        }
                      }
                    } else {
                      setState({ box2: "" });
                      if (state.box2) {
                        Inp1Ref.current.focus();
                      }
                    }
                  }} />
                <input type="number"
                  ref={Inp3Ref}
                  value={state.box3}
                  onKeyDown={(e) => {
                    if (!state.box3) {
                      if (e.key === "Backspace") {
                        Inp2Ref.current.focus();
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value) {
                      if (onlyNumberKey(e.target.value)) {
                        if (state.box3) {
                          let arr = String(e.target.value)
                            .split("")
                            .map((num) => {
                              return Number(num);
                            });
                          setState({ box4: arr[1] });
                          Inp4Ref.current.focus();
                        } else {
                          setState({ box3: e.target.value });
                          Inp4Ref.current.focus();
                        }
                      }
                    } else {
                      setState({ box3: "" });
                      if (state.box3) {
                        Inp2Ref.current.focus();
                      }
                    }
                  }}
                />
                <input type="number"
                  ref={Inp4Ref}
                  value={state.box4}
                  onKeyDown={(e) => {
                    if (!state.box4) {
                      if (e.key === "Backspace") {
                        Inp4Ref.current.focus();
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value) {
                      if (onlyNumberKey(e.target.value)) {
                        if (!state.box4) {
                          setState({ box4: e.target.value });
                        }
                      }
                    } else {
                      setState({ box4: "" });
                      if (state.box4) {
                        Inp3Ref.current.focus();
                      }
                    }
                  }}
                />
              </div>
              {state.showOTPErrorMessage && <span className="mb-2"><H5>{state.otpErrorMessage}</H5></span>}
              {
                (disabled === true) &&
                <H5>{`Resend OTP in ${time.m}:${time.s} seconds`}</H5>
              }
              {
                (disabled === false) &&
                <H5>{`Did not received OTP? `}
                  <span onClick={() => { resendOTP(); }}>Resend OTP</span>
                </H5>
              }
              <Button name="Verify" onClick={verifyOTP} />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default MobileVerification;