import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import H3 from '../../../../text-components/H3';
import H5 from '../../../../text-components/H5';
import HeaderText from '../../../../text-components/HeaderText';
import ReactPaginate from "react-paginate";
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import Loader from '../../../../utilities/Loader';
import swal from 'sweetalert';
import SuperUserAssignedDepartments from './SuperUserAssignedDepartments';
import * as FileSaver from "file-saver";

const SuperUserList = () => {

    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = useState("");
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [superUserList, setSuperUserList] = useState([]);
    const [showSuperUserAccessDepartment, setSuperUserAccessDepartment] = useState({ modal: false, userId: "" });

    //Onclick set current page
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (value) {
            getUserBySearch(value, selectedPage + 1);
        } else {
            fetchSuperUsers(selectedPage + 1);
        }
    };

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString === "") {
            //call API 
            const serverResponse = await officeCommunication.getAllSuperUsers();
            if (serverResponse.data.status === "SUCCESS") {
                setSuperUserList(serverResponse.data.users);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setSuperUserList([]);
                setPageCount(0);
            }
        } else {
            console.log("inside search", searchString);
            getUserBySearch(searchString)
        }
    }

    //fetch user list by searchValue 
    async function getUserBySearch(searchString, page) {
        try {
            //call API 
            const serverResponse = await officeCommunication.getSuperUserBySearch(searchString, page);
            if (serverResponse.data.status === "SUCCESS") {
                setSuperUserList(serverResponse.data.users);
                setPageCount(serverResponse?.data?.totalPages);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setSuperUserList([]);
                setPageCount(0);
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    }

    //get All Super Users
    const fetchSuperUsers = async (page) => {
        try {
            dispatch(showLoader());
            const responseFromServer = await officeCommunication.getAllSuperUsers(page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setSuperUserList(responseFromServer?.data?.users);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                setSuperUserList([]);
                setPageCount(0);
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }


    //Export data into excel
    const exportData = async () => {
        try {
            swal({
                // title: "Are you sure?",
                text: "Are you sure you want to export?",
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    dispatch(showLoader());
                    const serverResponse = await officeCommunication.exportSuperUserList(value);
                    if (serverResponse.data) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([serverResponse.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        FileSaver.saveAs(file, "Super_user_List.xlsx");
                    } else {
                        swal({ text: "Failed to export data in excel", icon: "warning" });
                    }
                    dispatch(hideLoader());

                } else {
                    return;
                }
            });
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }

    useEffect(() => {
        fetchSuperUsers(currentPage);
    }, []);

    return (
        <div className="panel">
            {loader === true ?
                <Loader />
                :
                <>
                    <div className="search_header">
                        <H3>Super User Management</H3>
                        <div className="search_input">
                            <input type="text" className="form-control" placeholder="Search by user Name, User ID" title="Search by User Name, User ID, Mobile Number" onChange={(e) => { searchFilter(e.target.value.trim()); setValue(e.target.value.trim()) }} style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </div>
                        <button className="button" onClick={() => navigate("/dashboard/super-user-management/create-super-user")}>Create Super User</button>
                        <button className="button ms-1" onClick={() => exportData()}  > exportData<i className="fa-regular fa-file-lines" /></button>

                    </div>
                    <div className="table_main user_list_table">
                        <table>
                            <thead>
                                <tr>
                                    <th><HeaderText>Serial Number</HeaderText></th>
                                    <th><HeaderText>UserId</HeaderText></th>
                                    <th><HeaderText>User Name</HeaderText></th>
                                    <th><HeaderText>User Type</HeaderText></th>
                                    <th><HeaderText>Mobile Number</HeaderText></th>
                                    <th><HeaderText>Date</HeaderText></th>
                                    <th><HeaderText>Action</HeaderText></th>
                                </tr>
                            </thead>
                            {superUserList?.length > 0 ?
                                <tbody>
                                    {superUserList?.map((user, index) => (
                                        <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }} key={index}>
                                            <td><H5>{index + 1}</H5></td>
                                            <td className="link" onClick={() => setSuperUserAccessDepartment({ modal: true, userId: user?.userId })}><H5>{user?.userId}</H5></td>
                                            <td><H5>{user?.userName}</H5></td>
                                            <td><H5>{user?.userType}</H5></td>
                                            <td><H5>{user?.mobileNumber}</H5></td>
                                            <td><H5>{new Date(user?.creationDate).toLocaleDateString()}</H5></td>
                                            <td onClick={() => navigate(`/dashboard/super-user-management/update-super-user?userId=${user?.userId}`)}><i className="fa-solid fa-pen-to-square"></i></td>
                                        </tr>
                                    ))}
                                </tbody>
                                :
                                <tbody>
                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}><td></td><td></td><td></td><td></td><td></td></tr>
                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><H3>User Not Available</H3></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    {pageCount > 1 &&
                        <div className='pagination_div' style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}` }}>
                            <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                forcePage={currentPage}
                                activeClassName={"active"} />
                        </div>
                    }
                </>
            }
            {showSuperUserAccessDepartment?.modal &&
                <SuperUserAssignedDepartments setSuperUserAccessDepartment={setSuperUserAccessDepartment} showSuperUserAccessDepartment={showSuperUserAccessDepartment} />
            }
        </div>
    )
}

export default SuperUserList