import { configureStore } from "@reduxjs/toolkit";
import loader from "./loaderReducer";
import fontSizeMultiplier from "./fontSizeMultiplier";
import darkWhiteThemeToggle from "./darkWhiteThemeToggle";
import sideNavToggle from "./sideNavToggle";
import showComplaintDetailReducer from "./showComplaintDetailReducer";
import userDetails from "../redux-store/user-reducer";
import scrollToTop from "./scrollToTop";

const store = configureStore({
    reducer: {
        loader: loader,
        fontSizeMultiplier: fontSizeMultiplier,
        darkWhiteThemeToggle: darkWhiteThemeToggle,
        sideNavToggle: sideNavToggle,
        showComplaintDetailReducer: showComplaintDetailReducer,
        userDetails: userDetails,
        scrollToTop: scrollToTop
    }
});

export default store;