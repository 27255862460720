export const faqArray = [
    {
        "id": 1,
        "question": "How To Raise Complaint?",
        "answer": ["Click on the 'Raise complaint online' button/Call on Toll Free number.", "Enter your mobile number then click send OTP button. 4-digit OTP will get sent to your mobile number.", "Enter 4-digit OTP then verify it.", "Complaint Form opens on your screen with two tabs.", "Personal Details and Complaint Details.", "Fill all the details in both the Form and click on Submit Button.", "Complaint will get successfully Submitted."],
    },
    {
        "id": 2,
        "question": "How To Track Complaint?",
        "answer": ["Click on the Track your Complaint button.", "Complaint track form appears on your screen. Enter your registered mobile number.", "4-digits OTP will get sent on your registered mobile number, enter that OTP, and click on the verify button.", "Complaint List is displayed on your screen.", "Click on the complaint ID to track your complaint status.", "When we click on the complaint ID, the Complaint Status Modal display on your screen."],
    },
    {
        "id": 3,
        "question": "How we can identify the departments, sub-departments, and where they are found?",
        "answer": ["On Public Portal Department option are available on navbar. Click on Department tab are appearing on the screen."],
    },
    {
        "id": 4,
        "question": "What if I do not receive OTP on my mobile?",
        "answer": ["If an OTP is not received within 5 minutes of filing the request, a button 'Resend OTP' will be enabled on the screen. A fresh OTP can then be requested by clicking on this button."],
    },
    {
        "id": 5,
        "question": "Can I edit/modify my grievance after it has been submitted?",
        "answer": ["Once submitted, no modifications/change can be made to the Complaint. However, a fresh Complaint can be submitted."],
    },
    {
        "id": 6,
        "question": "What should I do if I have submitted my Complaint to the wrong Department?",
        "answer": ["You need to Raise a fresh Complaint selecting the correct Department."],
    },
    {
        "id": 7,
        "question": "I have a question that was not answered in this FAQ. What should I do?",
        "answer": ["Please call on toll free number."],
    },
]