import swal from "sweetalert";
import axios from "axios";


const nodeEnvironment = process.env.REACT_APP_NODE_ENV;
const developmentURL = "http://localhost:3013";
const serverUrl = process.env.REACT_APP_NODE_URL;
const machine_IP = "http://192.168.29.237:3013";

function getServerUrl() {

    if (nodeEnvironment === "development") {
        return developmentURL;
    }

    if (nodeEnvironment === "machine_IP") {
        return machine_IP;
    }

    if (nodeEnvironment === "server") {
        return serverUrl;
    }

    return serverUrl;
};


export const publicCommunication = {
    webSiteVisitors: () => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/website-visitor`, {}, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    fetchCounts: () => {
        try {
            return axios.get(`${getServerUrl()}/public-portal/get-count`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    fetchDepartment: () => {
        try {
            return axios.get(`${getServerUrl()}/public-portal/get-department-list-with-subdepartment`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    sendOTP: (mobileNumber) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/mobile-verification`, { mobileNumber }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    sendOTPToTrackComplaint: (mobileNumber) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/send-otp-for-track-complaint`, { mobileNumber }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    sendOTPToForgetPassword: (mobileNumber) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/send-otp-for-forget-password`, { mobileNumber }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    resendOTP: (mobileNumber) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/resend-otp`, { mobileNumber }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    resendOTPToTrackComplaint: (mobileNumber) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/resend-otp-for-track-complaint`, { mobileNumber }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    resendOTPToForgetPassword: (mobileNumber) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/resend-otp-for-forget-password`, { mobileNumber }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    verifyOTP: (mobileNumber, otp) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/otp-verify`, { mobileNumber, otp }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getAllDepartment: () => {
        try {
            return axios.get(`${getServerUrl()}/public-portal/get-all-department`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getDepartmentWiseSubDepartment: (departmentId) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/get-department-wise-subdepartment`, { departmentId }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    raiseComplaint: (isFileAttached, formData) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/raise-complaint?isFileAttached=${isFileAttached}`, formData, {
                headers: {
                    "Content-Type": isFileAttached ? "multipart/form-data" : "application/json",
                    // "complaintData": JSON.stringify(complaintData)
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    fetchAllComplaints: (mobileNumber) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/track-complaint`, { mobileNumber }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    getComplaintById: (complaintId) => {
        try {
            return axios.post(`${getServerUrl()}/public-portal/get-complaint-by-id`, { complaintId }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
    previewComplaintAttachment: (fileUrl) => {
        try {
            return axios.get(`${getServerUrl()}/public-portal/get-file?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                },
                responseType: "blob"

            });
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    },
};

