import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import H3Public from "../text-components/H3Public";
import PPublic from "../text-components/PPublic";
import { socialMediaLink } from "../utilities/socialMediaLink";
import {scrollTop} from "../redux-store/scrollToTop";

const PublicFooter = () => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <>
            <footer className="footer_main"
                style={{ backgroundColor: `${theme === "WHITE" ? "#5E7082" : "#1E1E20"}` }}
            >
                <div >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading_line">
                                    <div className="underline_thik"
                                        style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#080808"}` }}
                                    ></div>
                                    <div className="underline_thin"
                                        style={{ backgroundColor: `${theme === "WHITE" ? "#6E8194" : "#464545"}` }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer_link_main">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6 col-8">
                                <ul>

                                    <li className="footer_heading"><H3Public>Contact us</H3Public></li>

                                    <li className="footer_link">
                                        <i className="fa-solid fa-envelope"></i>
                                        <a href="mailto:vandemataramchanda@gmail.com"> <PPublic>vandemataramchanda@gmail.com</PPublic> </a>
                                    </li>

                                    <li className="footer_link">
                                        <i className="fa-solid fa-square-phone"></i>
                                        <a href="tel:18002338691"> <PPublic>Toll Free : 1800-233-8691</PPublic></a>
                                    </li>

                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6 col-4">
                                <ul>

                                    <li className="footer_heading"><H3Public>Links</H3Public></li>

                                    <li className="footer_link">
                                        <NavLink to="/public/about-us" onClick={() => dispatch(scrollTop("scroll_about_us"))}><PPublic>About Us</PPublic></NavLink>
                                    </li>

                                    <li className="footer_link">
                                        <NavLink to="/public/term-and-conditions" onClick={() => dispatch(scrollTop("scroll_term_and_conditions"))}><PPublic>Terms & Conditions</PPublic></NavLink>
                                    </li>

                                    <li className="footer_link">
                                        <NavLink to="/public/privacy-policy" onClick={() => dispatch(scrollTop("scroll_privacy_policy"))}><PPublic>Privacy Policy</PPublic></NavLink>
                                    </li>

                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6 col-6">
                                <ul>

                                    <li className="footer_heading"><H3Public>Important Links</H3Public></li>

                                    <li className="footer_link">
                                        <a href="https://www.india.gov.in/" target="_blank" rel="noopener noreferrer">
                                            <PPublic>National portal of India</PPublic>
                                        </a>
                                    </li>

                                    <li className="footer_link">
                                        <a href="https://www.maharashtra.gov.in/1125/Home" target="_blank" rel="noopener noreferrer">
                                            <PPublic>Government of Maharashtra</PPublic>
                                        </a>
                                    </li>

                                    <li className="footer_link">
                                        <a href="https://zpchandrapur.maharashtra.gov.in/en" target="_blank" rel="noopener noreferrer">
                                            <PPublic>Zilha Parishad Chandrapur</PPublic>
                                        </a>
                                    </li>

                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6 col-6">
                                <ul>

                                    <li className="footer_heading">
                                        <img src="darklogo.png" alt="Vande Mantram Logo" />
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6">
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <ul>
                                    <li className="footer_heading"><H3Public>Address</H3Public></li>

                                    <li className="footer_link">
                                        <PPublic>X77X+6X8, Railway Station Road,
                                            near District Court, Chauhan Colony,
                                            Chandrapur, Maharashtra 442401</PPublic>
                                    </li>


                                </ul>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="social_media_heading">
                    <h3>Follow us on:</h3>
                </div>

                <div className="social_media_main">
                    <div className="social_media_wrapper">
                        {socialMediaLink?.map((linkDetails, index) => {
                            const { link, icon, id } = linkDetails;
                            return (
                                <div key={id}>
                                    <a href={link} target="_blank" rel="noopener noreferrer" >
                                        <div className="icon_main"
                                            style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#080808"}` }}
                                        >
                                            <i className={icon}></i>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="footer_copyright"
                    style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#131313"}` }}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 left">
                                <p>© Copyrights Vande Mantram Chandrapur 2022. All rights reserved.</p>
                            </div>
                            <div className="col-lg-6 right">
                                <p>Design & Developed by - All Indian IT Services LLP</p>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    );
}

export default PublicFooter;