import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PublicHeader from "../common-components/PublicHeader";
import PublicFooter from "../common-components/PublicFooter";
import HomePage from "../pages/public/home/HomePage";
import AboutUs from "../pages/public/about/AboutUs";
import ContactUs from "../pages/public/contactus/ContactUs";
import Department from "../pages/public/department/Department";
import FrequentlyAskedQuestions from "../pages/public/faq/FrequentlyAskedQuestions";
import { publicCommunication } from "../services/publicCommunication";
import swal from "sweetalert";
import MobileNumberVerification from "../pages/public/home/MobileNumberVerification";
import TrackComplaintLogin from "../pages/public/home/TrackComplaintLogin";
import TrackComplaintStatus from "../pages/public/home/TrackComplaintStatus";
import RaiseNewComplaintForm from "../pages/public/home/RaiseNewComplaintForm";
import Response from "../pages/public/home/Response";
import ComplaintList from "../pages/public/home/ComplaintList";
import TermAndCondition from "../pages/public/footerpages/TermAndCondition";
import PrivacyPolicy from "../pages/public/footerpages/PrivacyPolicy";


const PublicRoutes = () => {
    const [showModals, setShowModals] = useState({ mobileVerification: false, trackComplaint: false, mobile: "" });
    const [raiseComplaint, setRaiseComplaint] = useState(false);
    const [response, setResponse] = useState(false);
    const [viewStatus, setViewStatus] = useState(false);
    const [complaintId, setComplaintId] = useState("");

    //Get current url
    const fullUrl = window.location.href.split("/");

    //increase the website visitor counter
    async function webSiteVisitorCounter() {
        try {
            await publicCommunication.webSiteVisitors();
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    };

    useEffect(() => {
        webSiteVisitorCounter();
    }, [])

    return (
        <>
            <section className="homepage_bg_color">
                {fullUrl[5] === "complaint-list" ?
                    <></>
                    :
                    <PublicHeader setShowModals={setShowModals} />
                }
                <Routes>
                    <Route path="/" element={<HomePage setShowModals={setShowModals} />} />
                    <Route path="/homepage" element={<HomePage setShowModals={setShowModals} />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/frequently-asked-questions" element={<FrequentlyAskedQuestions />} />
                    <Route path="/department" element={<Department />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/complaint-list" element={<ComplaintList />} />
                    <Route path="/term-and-conditions" element={<TermAndCondition />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                {fullUrl[5] === "complaint-list" ?
                    <></>
                    :
                    <PublicFooter />
                }
                {showModals.mobileVerification &&
                    <MobileNumberVerification setShowModals={setShowModals} setRaiseComplaint={setRaiseComplaint} />
                }
                {raiseComplaint &&
                    <RaiseNewComplaintForm setRaiseComplaint={setRaiseComplaint} setResponse={setResponse} mobile={showModals.mobile} setComplaintId={setComplaintId} />
                }
                {response &&
                    <Response setResponse={setResponse} complaintId={complaintId} />
                }
                {showModals.trackComplaint &&
                    <TrackComplaintLogin setShowModals={setShowModals} setViewStatus={setViewStatus} />
                }
                {viewStatus &&
                    <TrackComplaintStatus setViewStatus={setViewStatus} />
                }
            </section>
        </>
    );
}
export default PublicRoutes;