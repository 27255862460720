import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../common-components/Button';
import H3 from '../../../../text-components/H3';
import H5 from '../../../../text-components/H5';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, getElementsAtEvent } from 'react-chartjs-2';
import StatuswiseComplaintListModal from '../../admin/reports/StatuswiseComplaintListModal';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import { officeCommunication } from '../../../../services/officeCommunication';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import swal from 'sweetalert';
ChartJS.register(ArcElement, Tooltip, Legend);

const DepartmentReport = () => {
    const dispatch = useDispatch();
    const printGraph = useRef();
    const chartRef = useRef();
    const scrollToList = useRef();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const departmentId = useSelector((state) => state.userDetails.departmentId);

    const [pieChartData, setPieChartData] = useState([0, 0, 0, 0, 0]);
    const navigate = useNavigate();
    // click on piechart data will be set in state
    const [labelData, setLabelData] = useState({
        complaintStatus: "",
        startDate: "",
        endDate: "",
        departmentId: departmentId,
        showComplaintList: false,
    });

    //Print Graph
    const printSelectedGraph = useReactToPrint({
        content: () => printGraph.current,
    })

    //Scroll to last Complaint List
    const scrollToComplaintList = () => {
        scrollToList?.current?.lastChild?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    //Get Graph Data
    const getGraphData = (event) => {
        const indexNumber = getElementsAtEvent(chartRef.current, event)[0].index;
        setLabelData({ ...labelData, complaintStatus: departmentData.labels[indexNumber], showComplaintList: true });
        scrollToComplaintList();
    }


    //get department pie chart details on initial load
    const getPieChartData = async (startDate, endDate) => {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getAllDepartmentPieChartDetails(startDate, endDate);
            if (serverResponse?.data?.status === "SUCCESS") {
                const statusWiseCount = serverResponse.data.statusWiseCount;
                let data = [statusWiseCount.new, statusWiseCount.reassigned, statusWiseCount.reopen, statusWiseCount.resolved, statusWiseCount.closed];
                setPieChartData(data);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                dispatch(hideLoader());
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    };


    useEffect(() => {
        getPieChartData(labelData.startDate, labelData.endDate);
    }, [labelData]);

    useEffect(() => {
        if (labelData.complaintStatus) {
            scrollToComplaintList();
        }
    }, [labelData.showComplaintList]);

    const departmentData = {
        labels: ['New', 'Reassigned', 'Re-Opened', 'Resolved', 'Closed'],
        datasets: [
            {
                label: 'Complaints',
                data: pieChartData,
                backgroundColor: [`${theme === "WHITE" ? "#25316D" : "#6678d4"}`, '#FFC23C', '#9B0000', `${theme === "WHITE" ? "#54B435" : "#70db4d"}`, `${theme === "WHITE" ? "#006400" : "#05a105"}`],
            },
        ],
    };


    return (
        <div className="panel department_report">
            <div className="lookups_search_header">
                <H3>Reports</H3>
                <div className="date_filter">
                    <H5>From Date:</H5>
                    <input type="date" className="form-control" onChange={(e) => setLabelData({ ...labelData, startDate: e.target.value })} style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                </div>
                <div className="date_filter">
                    <H5>To Date:</H5>
                    <input type="date" className="form-control" onChange={(e) => setLabelData({ ...labelData, endDate: e.target.value })} style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                </div>
                <Button name="Print" onClick={printSelectedGraph} icon={<i className="fa-solid fa-print"></i>} />
            </div>
            <div className="grpah_section deaprtment_graph" ref={printGraph}>
                <Pie ref={chartRef} data={departmentData}
                    options={
                        {
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: true,
                                    labels: {
                                        render: 'value',
                                        color: `${theme === "WHITE" ? "grey" : "skyblue"}`,
                                    },
                                }
                            },
                        }}
                    onClick={getGraphData}
                />
            </div>
            {labelData.showComplaintList &&
                <div ref={scrollToList}>
                    <StatuswiseComplaintListModal setLabelData={setLabelData} labelData={labelData} />
                </div>
            }
        </div>
    )
}

export default DepartmentReport