import { createSlice } from "@reduxjs/toolkit";

const sideNavToggle = createSlice({
    name: "sideNavToggleSlice",
    initialState: {
        sideNavWidth: 5,
        panelWidth: 95
    },
    reducers: {
        navToggle: (state, action) => {
            state.sideNavWidth = action.payload === 80 ? 20 : 5;
            state.panelWidth = action.payload;
            return state
        }
    }
});

export const { navToggle } = sideNavToggle.actions;
export default sideNavToggle.reducer;