export const gramPanchayat = [
    {
        panchayatSamiti: "Ballarpur",
        gramPanchayat: ["katvali", "kinhi", "aamdi", "korthi", "makta", "daheli", "hadasti", "lavari", "itoli", "gilbili", "kavadjai", "palasgaon", "kalmana", "manora", "nandgaon pode", "bamni", "kothari", "visapur"]

    },
    {
        panchayatSamiti: "Chandrapur",
        gramPanchayat: ["nimbala", "shivani chor", "vadha", "aarvat", "chhota nagpur", "kolsa", "belsani", "chaknimbala", "sidur", "gondsavari", "vaygao mo", "pimpalkhut", "sonegaon", "devada", "pandharkavada", "sinala", "lohara", "ajaypur", "yarur", "mhatardevi", "varvat", "dhanora", "sakharvahi", "usgaon", "mohurli", "vendli", "nagala m", "pipri", "chorgaon", "marda", "chichpalli", "junona", "vichoda rai", "shengaon", "morva", "padmapur", "borda", "payli bh", "datala", "kosara", "aambora", "padoli", "nagala si", "tadali", "nakoda", "durgapur", "urjanagar"]

    },
    {
        panchayatSamiti: "Rajura",
        gramPanchayat: ["kavadgondi", "povani", "muthra", "nalfadi", "nokari kh", "manoli kh", "bhurkunda bk", "kohpara",
            "kolgaon", "kavitpeth", "isapur", "antargaon", "murti", "dhidasi", "bhendala", "panchala", "dhanora", "sakharvahi", "bhedoda", "manoli bk", "bharkunda kh", "sindhi", "jamni", "sumthana", "chanakha", "sonapur", "waroda", "satri", "goyegaon", "pellora", "aaheri", "kalmana", "marda", "chincholi kh", "koshtala", "charli", "yergavhan", "khamona", "sonurli", "chandanvahi", "kadholi bk", "sirsi", "pandharpauni", "bamanvada", "sakhari", "chincholi bk", "varur road", "sondo", "aarvi", "dongargaon", "mangi bk", "temburvahi", "lakkadkot", "vihirgaon", "bhendvi", "hardona kh", "pachgaon", "subbai", "govari", "devala", "rampur", "sasti", "virur st.", "chunala", "dhoptala"]
    },
    {
        panchayatSamiti: "Korpana",
        gramPanchayat: ["sheraj kh", "sawalhira", "belgaon", "wanoja", "hirapur", "kolshi kh", "sangoda", "nokari  pal",
            "mangalhira", "pipari", "loni", "virur", "kukudsath", "kothoda bk", "nimni", "sheraj bk", "durgadi", "katlabodi", "khairgaon", "pimpalgaon", "bharosa", "matha", "gadegao", "kanhalgaon", "pardi", "jewara", "talodhi", "bhoyegaon", "chanai bk", "dhanoli", "wadgaon", "piparda", "thutra", "kolshi bk", "rupapeth", "wansadi", "nandgaon", "antargaon", "sonurli", "parsoda", "mandwa", "kavthala", "khirdi", "yergavhan", "bakhardi", "lakhmapur", "kadholi", "naranda", "uparvahi", "bibi", "aavalpur", "nanda"]
    },

    {
        panchayatSamiti: "Jiwati",
        gramPanchayat: ["Shedwahi  Bhari", "Sorekasa", "Pittiguda No. 2", "Yermi Yesapur", "Kekezari", "Pittiguda No. 1",
            "Marai Patan", "Bhoksapur", "Dewalaguda", "Dampur Mohda", "Shedwahi Lambori", "Dhanakdevi", "Markalmeta", "Punaguda", "Yellapur", "Nandappa", "Kodepur", "Aambezari", "Dhonda Arjuni", "Aasapur", "Nokewada", "Rahpalli Kh.", "Paramdoli", "Chikhli Bk.", "Khadki Raypur", "Gudshela", "Markagondi", "Khadki Hirapur", "Patan", "Bhari", "Chikhli Hh.", "lambori", "Tekamandva", "Shengaon", "Pudiyal Mohda", "Kumbezari"]
    },

    {
        panchayatSamiti: "Gondpipri",
        gramPanchayat: ["vihirgaon", "parsodi", "podsa", "supgaon", "panora", "dubarpeth", "chekborgaon", "tarda", "dhamangaon", "nandgaon", "ghadoli", "kudesaoli", "adegaon", "ganeshpipari", "chekpargaon", "hiwara", "cheklikhitwada", "kirmiri", "dongargaon", "darur", "wadkuli", "chekpipari", "chekghadoli", "chekberdi", "kanhalgaon", "kosambi", "cheknandgaon", "sakmur", "gojoli", "somanpalli", "furdiheti", "watrana", "wedgaon", "wejgaon", "sonapur desh", "salezari", "borgaon", "kharalpeth", "nandwardhan", "chekdarur", "tarsa bk.", "aksapur", "dhanapur", "wadholi", "lathi", "vitthawada", "karanji", "dhaba", "tohogaon", "bha.talodhi"
        ]
    },
    {
        panchayatSamiti: "Panchayat Pombhurna",
        gramPanchayat: ["chekaashta", "chek futana", "borda zullurvar", "kasargatta", "chakthana", "chekballarpur", "pipari deshpande", "chek aambedhanora", "jam tukum", "satara bhosle", "aashta", "chekthanewasna", "mohala rai", "jamkhurd", "dewada bk", "bhimni", "jungaon", "dighori", "borda borkar", "umri potdar", "futana", "thergaon", "ghatkul", "velva", "ghanoti tukum", "navegaon more", "ghosari", "chintaldhaba", "chekhattibodi", "kemara", "devada kh"]
    },

    {
        panchayatSamiti: "Mul",
        gramPanchayat: ["Bhagwanpur", "Babrala", "Akapur", "Govardhan", "Maregaon", "Katwan", "Kosambi", "Uthalpeth", "Bondola Khurd", "Morwahi", "Bondola  Bhuj", "Bhavrala", "Sintala", "Khalvaspeth", "Naleshwar", "Murmadi", "Dabgaon", "Chakdugala", "Chitegaon", "Janala", "Tolewahi", "Gangalwadi", "Tadala", "Haldi", "Bhadurni", "Chimda", "Virai", "Rajgad", "Pipri Dixit", "Ushrala", "Yergaon", "Borchandali", "Chandapur", "Nandgaon", "Fiskuti", "Tekadi", "Navegaon Bhu", "Sushi", "Gadisurla", "Kelzar", "junasurla", "Chiroli", "Chikhli", "Bhejgaon", "Dongargaon", "Chichala", "Bembal", "Maroda", "Rajoli"]

    },

    {
        panchayatSamiti: "Saoli",
        gramPanchayat: ["thergaon", "vagholi butti", "kasargaon", "navegaon tu.", "aakapur", "gevara kh", "kadholi", "mangarmendha", "chikhali", "dongargaon", "belgaon", "kargaon", "karoli", "donala", "palebarsa", "mundala",
            "chargaon", "usarparchak", "mehabuj", "gevara buj", "vihirgaon", "pardi", "jibgaon", "bormala", "khedi",
            "usegaon", "chandli buj", "pendhri makta", "saykheda", "sakhari", "chakpiranji", "kapsi", "upari",
            "jam buj", "bothli", "londholi", "sonapur", "kondekhal", "chichbodi", "hirapur", "ghodevahi",
            "Ni. Pethgaon", "keroda", "haramba", "niphandra", "mokhala", "gaydongri", "kavathi", "nimgaon",
            "samda", "antargaon", "pathari", "vyahad kh", "vyahad bk"]

    },

    {
        panchayatSamiti: "Sindewahi",
        gramPanchayat: ["karva", "kanhalgaon", "pavanachak", "chikmara", "chargaon badge", "singadzari", "samda khu.",
            "sirkada", "ghot", "khatgaon", "kachhepar", "waneri", "aalesur", "kinhi", "vakal", "pavanpar", "mendhamal", "kalamgaon tu.", "gadmaushi", "bhendala", "murmali", "maregaon tu.", "nachanbhatti", "dongargaon", "tekri", "lonkhairi tu.",
            "umarvahi", "ladbori", "gadbori", "ramala", "pendhari koke", "tambegadi mendha", "kukadheti", "minghari", "nandgaon",
            "jamsala", "kalamgaon ganna", "antargaon", "wirvha", "navegaon lon", "saradpar", "pethgaon", "delanvadi", "vasera",
            "gungewahi", "shivani", "mohadi", "palasgaon jat", "ratnapur", "navargaon"]
    },

    {
        panchayatSamiti: "Nagbhir",
        gramPanchayat: ["kitali mendha", "panjarepar", "sonapur buj", "khadki", "vaijapur", "balapur kh", "sonuli buj",
            "chikalgaon", "kothulana", "ushralamendha", "kosambi gavali", "gangasagar heti", "chargaon chak", "navegaon hude", "navkhada", "vasala medha", "vilam", "kotegaon", "mangrul", "panholi", "chikmara", "kirmiti", "devpayli", "mangli arab", "jankapur", "owala", "aakapur", "mhasali", "mohali mo", "mendha kir", "bikali",
            "valani", "bond", "Kordha", "chindhi chak", "palasgaon kh", "yenoli mal", "pendhri barad", "navegaon pandav",
            "kojbi mal", "kitali bor", "minthur", "aalewahi", "dhorpa", "paharni", "maushi", "kanhalgaon", "balapur buj", "kanpa", "mindala", "pardi thavre", "nanded", "govindpur", "sawargaon", "girgaon", "vadhona", "talodhi"
        ]
    },

    {
        panchayatSamiti: "Bramhpuri",
        gramPanchayat: ["pachgaon", "kanhalgaon", "bodhegaon", "bhaleshawar", "ballarpur", "bodadha", "chakbothali",
            "maher", "borgaon", "mararmendha", "kitali", "aaksapur", "kalamgaon", "Zilbodi", "surbodi", "hardoli",
            "chandli", "kosambi kh", "mangli", "mui", "kothulana", "kudesaoli", "kahali", "chichkheda", "sawalgaon",
            "kolari", "ladaj", "javarabodi", "lakhapur", "sondri", "kinhi", "sonegaon", "chandgaon", "ranmochan", "kaleta",
            "chichgaon", "dighori", "nilaj", "gogaon", "adyal jani", "ekara", "waigaon", "vandra", "paradgaon", "chincholi",
            "betala", "torgaon kh", "saygaon", "belgaon jani", "chorati", "ranbothali", "khandala", "bhuj", "rampuri", "kharkada", "chikhalgaon", "talodi kh", "gangalwadi", "nandgaon jani", "tulanmendha", "torgaon bk", "rui", "udapur", "jugnala", "maldongri", "baradkinhi", "nanhori", "chaugan", "halda", "mudza", "khedmakta", "aavalgaon", "mendki", "arher navargaon", "pimpalgaon"]
    },

    {
        panchayatSamiti: "Chimur",
        gramPanchayat: ["jamani", "navegaon  ram", "hiwara", "borgaon buti", "kesalabodi", "chichala ku.", "kanhalgaon", "jamgaon ko.", "lawari", "chakjambhulvihira", "siraspur", "kitali  m", "khapari dhrmu", "sonegaon wan", "chicholi", "jawarabodi", "manemohali", "vihirgaon tu.", "kitali tu.", "peth bhansuli", "kalamgaon", "chakjatepar", "rengabodi", "shiwara", "kawadshi dak", "yerkheda", "puyardand", "amarpuri", "wakarla",
            "kawathala", "panjarepar", "adegaon desh", "paradpar", "usegaon", "satara", "ambezari", "gadpipari", "chichala shastri", "kaparla kh.", "wahangaon", "bodadha", "bhiwkund", "wadsi", "mahalgaon kalu", "kolari", "wagheda", "vihirgaon", "metepar", "sawarla", "kawadshi desh", "mahadwadi", "pimpalgaon",
            "gadgaon", "nandara", "sathgaon", "tekepar", "kotgaon", "mhasali", "lohara", "bothali  wah", "khangaon",
            "hirapur", "piparda", "murpar tu.", "gondeda", "navegaon peth", "malewada", "amdi begade", "kswada mo.",
            "khairi", "khambada", "kolara", "mangalgaon", "shiwapur bandar", "sawari  bid", "madnapur", "khutala", "bothali  la", "talodhi na", "masal bk.", "kajalsar", "jambhulghat", "motegaon", "doma", "sawargaon", "palasgaon", "navtala", "amboli", "sirpur", "khadsangi", "shankarpur", "neri"]

    },

    {
        panchayatSamiti: "Warora",
        gramPanchayat: ["poha", "nimsala", "borgaon mo", "gunjala", "vathoda", "tadgavhan", "sa.ye. Block", "vadgaon",
            "aatmurdi", "aamdi", "ralegaon", "mesa", "bhendala", "mahalgaon kh", "jamgaon kh", "wadadha tu", "karnji",
            "mahalgaon bk", "marda", "mahadoli", "pardi", "jamni", "bodkha", "khapri", "wanoja", "yekona", "sonegaon",
            "ukharda", "chargaon bk", "aashi", "niljai", "waghnakh", "arjuni tu", "yevati", "bori", "dadapur", "tumgaon",
            "mangli dev", "jamkhula", "jamgaon bk", "fattapur", "parsoda", "aanjangaon", "tulana", "borgaon shi", "panzurni", "kosarsar", "dongargaon", "barvha", "majra rai", "dahegaon", "sakhra", "shebal", "mohbala", "pijdura", "khemjai", "kharvad", "bhatala", "borgaon de", "chargaon kh", "vandhali", "charurkhati", "aanadvan", "sumthana", "mokhada", "soit", "yensa", "chikni", "jalaka", "pachgaon tha", "khambada", "chinora", "yekarjurna", "vaygaon bho", "aabamakta", "salori", "temurda", "nagari", "madheli", "borda",
            "shegaon bk"]
    },

    {
        panchayatSamiti: "Bhadrawati",
        gramPanchayat: ["sawari", "gunjala", "rantalodhi", "chicholi", "pevara tu", "kondhegaon ma", "visapur rai", "chaprala", "chargaon", "kachrala", "kokevada ma", "bhamdeli", "kansa si", "mangaon", "dhorvasa", "naglon", "aashti ka", "chiradevi", "khokri", "panvadala", "tekadi di", "chalbardi ko",
            "bijoni", "jena", "masal vi", "kunala", "chek tirvanja", "dongargaon khadi", "pipri de", "palasgaon si", "deualvada", "chalbardi rai", "takli",
            "gorja", "ghotnibala", "kadholi", "Wagheda se", "belgao bhu", "katval tu", "tirvanja mo", "gudgao wade", "pirli", "viloda", "sagra", "vislon",
            "shegao kh", "mohbala", "parodhi", "bhatali", "vadala", "dhanoli", "chekbaranj", "mursa", "vaygaon tu", "manora si", "pawana rai", "kokevada tu",
            "mangli rai", "chora", "baranj mo", "mudholi", "kondha", "aashta", "patala", "nandori bk", "kuchna", "ghodpeth", "chandankheda", "majri"]
    }
];