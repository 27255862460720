import React, { useEffect, useState } from 'react';
import Button from '../../../../common-components/Button'
import H3 from '../../../../text-components/H3'
import H5 from '../../../../text-components/H5';
import HeaderText from '../../../../text-components/HeaderText';
import { officeCommunication } from '../../../../services/officeCommunication';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import { useDispatch, useSelector } from 'react-redux'; import * as FileSaver from "file-saver";
import ReactPaginate from 'react-paginate';
import ComplaintDetail from '../../../../common-components/ComplaintDetail';


const AccelatedComplaintListModal = ({ labelData, setLabelData }) => {
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [filteredComplaints, setFilteredComplaints] = useState([]);
    const [selectedComplaint, setSelectedComplaint] = useState("");
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(false);
    const limit = process.env.REACT_APP_LIMIT

    //Onclick set current page
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchEscalatedComplaintsBySearch(labelData?.departmentId, searchValue, selectedPage + 1);
        } else {
            fetchEscalatedComplaintsByDepartmentId(labelData?.departmentId, selectedPage + 1);
        }
    };

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString === "") {
            const responseFromServer = await officeCommunication.getEscalatedComplaintsByDepartmentId(labelData?.departmentId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredComplaints(responseFromServer?.data?.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredComplaints([]);
                setPageCount(0);
            }
        } else {
            fetchEscalatedComplaintsBySearch(labelData?.departmentId, searchString, currentPage + 1);
        }
    }

    //Export Data to Excel
    const exportData = async () => {
        try {
            const totalComplaints = `${pageCount === 1 ? pageCount * limit : (pageCount - 1) * limit}`
            swal({
                text: pageCount > 1 ? `Complaints are more than ${totalComplaints}, Are you sure you want to export?` : "Are you sure you want to export?",
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    dispatch(showLoader());
                    const serverResponse = await officeCommunication.exportEscalatedComplaints();
                    if (serverResponse.data) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([serverResponse.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        FileSaver.saveAs(file, "Escalated Complaints.xlsx");
                    } else {
                        swal({ text: "Failed to export data in excel", icon: "warning" });
                    }
                    dispatch(hideLoader());

                } else {
                    return;
                }
            });
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }

    //Get Escalated Complaints By Search
    const fetchEscalatedComplaintsBySearch = async (departmentId, searchString, page) => {
        try {
            const responseFromServer = await officeCommunication.getEscalatedComplaintsBySearch(departmentId, searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredComplaints(responseFromServer?.data?.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredComplaints([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }


    //Get complaint list by department id on initial load
    const fetchEscalatedComplaintsByDepartmentId = async (selectedPage) => {
        try {
            const responseFromServer = await officeCommunication.getEscalatedComplaintsByDepartmentId(labelData?.departmentId, selectedPage);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredComplaints(responseFromServer?.data?.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredComplaints([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }


    useEffect(() => {
        fetchEscalatedComplaintsByDepartmentId(currentPage + 1);
    }, [labelData]);


    return (
        <div className="statuswise_complaint_list_modal" style={{ backgroundColor: `${theme === "WHITE" ? "#fff" : "#444444"}` }}>
            <div className="search_header statuswise_complaints_header">
                <H3>Escalated Complaints</H3>
                <div className="search_input">
                    <input type="text" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search by Department..." title="Search by Department, Sub-Department, Complaint Type, Area Type" style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                    <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                <div className="button_group">
                    {filteredComplaints?.length > 0 &&
                        <Button name="Export to Excel" onClick={() => exportData()} icon={<i className="fa-regular fa-file-lines"></i>} />
                    }
                    <Button name="Cancel" onClick={() => setLabelData({ ...labelData, showComplaintList: false })} />
                </div>

            </div>
            <div className="table_main">
                <table>
                    <thead>
                        <tr>
                            <th><HeaderText>Serial Number</HeaderText></th>
                            <th><HeaderText>Complaint Id</HeaderText></th>
                            <th><HeaderText>Department</HeaderText></th>
                            <th><HeaderText>Sub Department</HeaderText></th>
                            <th><HeaderText>Complaint Type</HeaderText></th>
                            <th><HeaderText>Area Type</HeaderText></th>
                            <th><HeaderText>City / Grampanchayat</HeaderText></th>
                            <th><HeaderText>Date</HeaderText></th>
                        </tr>
                    </thead>
                    {filteredComplaints?.length > 0 ?
                        <>
                            <tbody>
                                {filteredComplaints?.map((complaint, index) => {
                                    return (
                                        <tr key={index} style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }}>
                                            <td><H5>{(limit * (currentPage)) + (index + 1)}</H5></td>
                                            <td className="link" onClick={() => { setShowForm(true); setSelectedComplaint(complaint.complaintId) }} ><H5>{complaint.complaintId}</H5></td>
                                            <td><H5>{complaint.departmentName}</H5></td>
                                            <td><H5>{complaint.subDepartmentName}</H5></td>
                                            <td><H5>{complaint.complaintType}</H5></td>
                                            <td><H5>{complaint.personalDetails.areaType}</H5></td>
                                            <td>{complaint.personalDetails.areaType === "urban"
                                                ?
                                                <H5>{complaint.personalDetails.city}</H5>
                                                :
                                                <H5>{complaint.personalDetails.gramPanchayat}</H5>

                                            }
                                            </td>
                                            <td><H5>{new Date(complaint.updateDate).toLocaleDateString()}</H5></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </>
                        :
                        <tbody>
                            <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}><td></td></tr>
                            <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><H3>Complaint Not Available</H3></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    }
                </table>
            </div>
            {showForm === false &&
                <>
                    {pageCount > 1 &&
                        <div className='grap_pagination_div' style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}` }}>
                            <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                forcePage={currentPage}
                                activeClassName={"active"} />
                        </div>
                    }
                </>
            }
            {showForm &&
                <ComplaintDetail selectedComplaint={selectedComplaint} setShowForm={setShowForm} getComplaints={fetchEscalatedComplaintsByDepartmentId} />
            }
        </div>
    )
}

export default AccelatedComplaintListModal