import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from './Button';
import { useState } from 'react';
import useValidator from '../utilities/useValidator';
import swal from 'sweetalert';
import { officeCommunication } from '../services/officeCommunication';
import { hideLoader, showLoader } from '../redux-store/loaderReducer';
import FileSaver from 'file-saver';
import Loader from '../utilities/Loader';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const ExportToExcelModal = ({ setExportToExcelModalState, pageCount, complaintStatus }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const [validator, showValidationMessage] = useValidator();
    const limit = process.env.REACT_APP_LIMIT ?? 20
    const [departmentList, setDepartmentList] = useState([]);
    const [subDepartmentList, setSubDepartmentList] = useState([]);
    const [filterData, setFilterData] = useState({
        filterBy: "",
        departmentId: "",
        subDepartmentId: ""
    });

    //fetch department list on initial list
    const fetchAccessWiseDepartmentList = async () => {
        try {
            const responseFromServer = await officeCommunication.getAccessWiseDepartmentList();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDepartmentList(responseFromServer?.data?.result);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
            } else {
                setDepartmentList([]);
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //fetch department wise sub department list
    const departmentWiseSubDepartments = async (departmentId) => {
        try {
            if (filterData?.filterBy === "subdepartment") {
                const responseFromServer = await officeCommunication.getSubDepartmentByDepartment(departmentId);
                if (responseFromServer?.data?.status === "SUCCESS") {
                    setSubDepartmentList(responseFromServer?.data?.subdepartment);
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    navigate("/login");
                    swal({ text: responseFromServer?.data?.message, icon: "warning" });
                } else {
                    setSubDepartmentList([]);
                }
            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }
    //Export data to excel
    const exportExcel = (exportType) => {
        try {
            let dataToSend = {};
            if (exportType === "export") {
                if (!validator.allValid()) {
                    showValidationMessage(true);
                    return false;
                }
                dataToSend = {
                    departmentId: filterData?.departmentId,
                    subDepartmentId: filterData?.subDepartmentId,
                    filterBy:filterData?.filterBy,
                    complaintStatus,
                    isExport: true
                }
            } else {
                dataToSend = {
                    complaintStatus,
                    isExport: false
                }
            }

            const totalComplaints = `${pageCount === 1 ? pageCount * limit : (pageCount - 1) * limit}`
            swal({
                // title: "Are you sure?",
                text: pageCount > 1 ? `Complaints are more than ${totalComplaints}, Are you sure you want to export?` : "Are you sure you want to export?",
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    dispatch(showLoader());
                    const serverResponse = await officeCommunication.exportComplaintLookupComplaints(dataToSend);
                    if (serverResponse.data) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([serverResponse.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        FileSaver.saveAs(file, "AllComplaint.xlsx");
                        setExportToExcelModalState(false);
                    } else {
                        dispatch(hideLoader());
                        swal({ text: serverResponse?.data?.message, icon: "warning" });
                    }
                    dispatch(hideLoader());
                } else {
                    dispatch(hideLoader());
                    return;
                }
            });
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    useEffect(() => {
        fetchAccessWiseDepartmentList();
    }, []);

    return (
        <div className="exportToExcel_modal_wrapper" style={{ backgroundColor: `${theme === "WHITE" ? "rgba(0, 0, 0, 0.521)" : "#4444448e"}` }}>
            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form" style={{ backgroundColor: `${theme === "WHITE" ? "#fff" : "rgb(48, 48, 48)"}`, boxShadow: `${theme === "WHITE" ? "none" : "0px 0px 8px #575757"}` }}>
                    <div className="row">
                        {filterData?.filterBy === "" &&
                            <div className="col-lg-3 col-md-3 text-center input_field">
                                <label style={{ color: `${theme === "WHITE" ? "#000" : "#fff"}` }}>Filter By</label>
                            </div>
                        }
                        <div className="col-lg-6 col-md-6 input_field">
                            <select name="fitlerBy" onChange={(event) => setFilterData({ ...filterData, filterBy: event.target.value })} className="form-select">
                                <option value="">Select Filter Type</option>
                                <option value="department">Department</option>
                                <option value="subdepartment">Sub-Department</option>
                            </select>
                            {validator.message("fitlerBy", filterData.filterBy, "required", {
                                messages: {
                                    required: "Filter Type is required"
                                }
                            })}
                        </div>
                        {filterData?.filterBy !== "" &&
                            <>
                                <div className="col-lg-6 col-md-6 text-center input_field">
                                    <select name="departmentId" onChange={(event) => { setFilterData({ ...filterData, departmentId: event.target.value }); departmentWiseSubDepartments(event.target.value) }} className="form-select">
                                        <option value="">Select Department</option>
                                        {departmentList?.map((departmentDetail, index) => {
                                            return (
                                                <option value={departmentDetail?._id} key={index}>{departmentDetail?.departmentName}</option>
                                            );
                                        })}
                                    </select>
                                    {validator.message("departmentId", filterData.departmentId, "required", {
                                        messages: {
                                            required: "Department is required"
                                        }
                                    })}
                                </div>
                                {filterData?.filterBy === "subdepartment" &&
                                    <div className="col-lg-6 col-md-6 input_field">
                                        <select name="subDepartmentId" onChange={(event) => setFilterData({ ...filterData, subDepartmentId: event.target.value })} className="form-select">
                                            <option value="">Select Sub-Department</option>
                                            {subDepartmentList?.map((subDepartmentDetail, index) => {
                                                return (
                                                    <option value={subDepartmentDetail?._id} key={index}>{subDepartmentDetail?.subDepartmentName}</option>
                                                );
                                            })}
                                        </select>
                                        {validator.message("subDepartmentId", filterData.subDepartmentId, "required", {
                                            messages: {
                                                required: "Sub Department is required"
                                            }
                                        })}
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div className="button_group">
                        <Button name="Skip" onClick={() => exportExcel("skip")} />
                        <Button name="Export" onClick={() => exportExcel("export")} />
                        <Button name="Cancel" onClick={() => setExportToExcelModalState(false)} />
                    </div>
                </div>
            }
        </div>
    )
}

export default ExportToExcelModal