import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import H3 from "../../../../text-components/H3";
import H5 from "../../../../text-components/H5";
import HeaderText from "../../../../text-components/HeaderText";
import ComplaintDetail from '../../../../common-components/ComplaintDetail';
import { showComplaintDetail } from "../../../../redux-store/showComplaintDetailReducer";
import Loader from "../../../../utilities/Loader";
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import { officeCommunication } from "../../../../services/officeCommunication";
import ReactPaginate from "react-paginate";




const Complaints = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const showComplaintForm = useSelector((state) => state.showComplaintDetailReducer.formState);
    const loader = useSelector((state) => state.loader.showHideLoader);

    const [filterComplaints, setFilterComplaints] = useState([]);
    const [selectedComplaint, setSelectedComplaint] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const limit = process.env.REACT_APP_LIMIT

    //Onclick set current page
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            getComplaintsBySearch(searchValue, selectedPage + 1);
        }
        getComplaints(selectedPage + 1);

    };

    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString === "") {
            const serverResponse = await officeCommunication.getAllNewAndReopenComplaint();
            if (serverResponse?.data?.status === "SUCCESS") {
                setFilterComplaints(serverResponse?.data?.complaint);
                setPageCount(serverResponse?.data?.totalPages)
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            } else {
                setFilterComplaints([]);
            }
        } else {
            getComplaintsBySearch(searchString);
        }
    }

    //get complaints by search
    const getComplaintsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await officeCommunication.getComplaintsForDepartmentUserBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilterComplaints(responseFromServer?.data?.complaint);
                setPageCount(responseFromServer?.data?.totalPages)
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilterComplaints([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    }

    //get new and reopen complaints on initial load
    const getComplaints = async (selectedPage) => {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getAllNewAndReopenComplaint(selectedPage);
            if (serverResponse?.data?.status === "SUCCESS") {
                setFilterComplaints(serverResponse?.data?.complaint);
                setPageCount(serverResponse?.data?.totalPages)
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setFilterComplaints([]);
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };

    useEffect(() => {
        getComplaints()
    }, [])
    return (


        <div className="panel">
            {loader === true ?
                <Loader />
                :
                <>
                    <div className="search_header">
                        <H3>Complaints</H3>
                        <div className="search_input">
                            <input type="text" className="form-control" placeholder="Search by complaintId ,mobileNumber and status" onChange={(e) => { searchFilter(e.target.value.trim()); setSearchValue(e.target.value.trim()) }} title="Search by Sub Departement, Departement, location" style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </div>
                        {showComplaintForm === false &&
                            <>
                                {pageCount > 1 &&
                                    <div className='pagination_div' style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}` }}>
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div className="table_main">
                        <table>
                            <thead>
                                <tr>
                                    <th><HeaderText>Serial Number</HeaderText></th>
                                    <th><HeaderText>Complaint Id</HeaderText></th>
                                    <th><HeaderText>User Name</HeaderText></th>
                                    <th><HeaderText>Complaint Type</HeaderText></th>
                                    <th><HeaderText>Area Type</HeaderText></th>
                                    <th><HeaderText>City /Grampanchayat</HeaderText></th>
                                    <th><HeaderText>Mobile Number</HeaderText></th>
                                    <th><HeaderText>Date</HeaderText></th>
                                    <th><HeaderText>Status</HeaderText></th>
                                </tr>
                            </thead>
                            {filterComplaints.length > 0 ?
                                <>
                                    <tbody>
                                        {filterComplaints.map((complaint, index) => {
                                            return (
                                                <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }} key={index}>
                                                    <td><H5>{(limit * (currentPage)) + (index + 1)}</H5></td>
                                                    <td className="link" onClick={() => { dispatch(showComplaintDetail()); setSelectedComplaint(complaint.complaintId) }}><H5>{complaint.complaintId}</H5></td>
                                                    <td><H5>{`${complaint?.personalDetails?.firstName}  ${complaint?.personalDetails?.lastName}`}</H5></td>
                                                    <td><H5>{complaint?.complaintType}</H5></td>
                                                    <td><H5>{complaint?.personalDetails?.areaType}</H5></td>
                                                    <td><H5>{complaint?.personalDetails?.city !== "" ? complaint?.personalDetails?.city : complaint?.personalDetails?.gramPanchayat}</H5></td>
                                                    <td><H5>{complaint?.personalDetails?.mobileNumber}</H5></td>
                                                    <td><H5>{new Date(complaint?.creationDate).toLocaleDateString()}</H5></td>
                                                    <td><H5>{complaint?.departmentStatus}</H5></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </>
                                :
                                <tbody>
                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}><td></td></tr>
                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><H3>Complaint Not Available</H3></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    {showComplaintForm &&
                        <ComplaintDetail selectedComplaint={selectedComplaint} getComplaints={getComplaints} />
                    }
                </>
            }
        </div>

    )
}

export default Complaints