import { createSlice } from "@reduxjs/toolkit";

const fontSizeMultiplier = createSlice({
    name: "fontSizeMultiplierSlice",
    initialState: {
        fontSize: 100
    },
    reducers: {
        increaseFont: (state, action) => {
            if (state.fontSize < 105) {
                state.fontSize = state.fontSize + 5;
                return state
            } else {
                return state
            }
        },
        defaultFont: (state, action) => {
            state.fontSize = state.fontSize = 100;
            return state
        },
        decreaseFont: (state, action) => {
            if (state.fontSize > 95 && state.fontSize <= 105) {
                state.fontSize = state.fontSize - 5;
                return state
            } else {
                return state
            }
        }
    }
});

export const { increaseFont, defaultFont, decreaseFont } = fontSizeMultiplier.actions;
export default fontSizeMultiplier.reducer;