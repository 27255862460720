import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { hideLoader, showLoader } from '../redux-store/loaderReducer';
import { hideComplaintDetail } from '../redux-store/showComplaintDetailReducer';
import { officeCommunication } from '../services/officeCommunication';


const SideNav = ({ setToggleProfile, notificationMarkAsSeen }) => {
    const sideNavToggle = useSelector((state) => state.sideNavToggle);
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const modalState = useSelector((state) => state.showComplaintDetailReducer.formState);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const [windowWidth, setWindowWidth] = useState();
    const dispatch = useDispatch();
    const [notificationCount, setNotificationCount] = useState(0);
    const [adminNotificationCount, setAdminNotificationCount] = useState(0);
    const navigate = useNavigate();

    // get notification count in intial load
    const countNotification = async () => {
        dispatch(showLoader());
        try {
            const serverResponse = await officeCommunication.getCountNotification();
            if (serverResponse?.data?.status === "SUCCESS") {
                setNotificationCount(serverResponse?.data?.notificationCount);
                setAdminNotificationCount(serverResponse?.data?.notificationTabCount);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                console.log("jwt");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setNotificationCount(0);
                setAdminNotificationCount(0)
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }


    //Get Window Inner Width on Initial load
    useEffect(() => {
        setWindowWidth(window.innerWidth);
        countNotification()
    }, [modalState, notificationMarkAsSeen]);

    return (
        <div className="side_nav" style={{ width: `${sideNavToggle.sideNavWidth}%`, backgroundColor: `${theme === "WHITE" ? "#8887C9" : "#444444"}`, boxShadow: `${theme === "WHITE" ? "5px 5px 5px 0px #e4e4e4" : "5px 5px 5px 0px #575757"}` }} onClick={() => setToggleProfile(false)} >
            <div className="logo" style={{ height: `${loginUserDetails.userType.toLowerCase() === "admin" ? 18 : 25}%` }}>
                <img className="img-fluid" src={theme === "WHITE" ? "light-theme.png" : "dark-theme.png"} alt="logo" style={{ width: `${sideNavToggle.sideNavWidth === 5 ? "100" : "35"}%`, marginTop: `${loginUserDetails.userType.toLowerCase() === "admin" ? 0 : 15}px` }} />
            </div>
            {loginUserDetails.userType.toLowerCase() === "admin" ?
                <>
                    <NavLink to="/dashboard/department-management">
                        <div className="tab" style={{ margin: "5px 0px 10px 0px" }}>
                            <div className="icon" style={{
                                margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                                margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 10px 0 10px"}`,
                            }} title="Department Management"><i className="fa-solid fa-building-user"></i></div>
                            <div className="title"><span>Department Management</span></div>
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard/sub-department-management">
                        <div className="tab" style={{ margin: "5px 0px 10px 0px" }}>
                            <div className="icon" style={{
                                margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                                margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 15px 0 10px"}`,
                            }} title="Sub Department Management"><i className="fa-regular fa-address-card"></i></div>
                            <div className="title"><span>Sub Department Management</span></div>
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard/post-name-management">
                        <div className="tab" style={{ margin: "5px 0px 10px 0px" }}>
                            <div className="icon" style={{
                                margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                                margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 18px 0 10px"}`,
                            }} title="Post Name Management"><i className="fa-solid fa-list"></i></div>
                            <div className="title"><span>Post Name Management</span></div>
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard/user-management">
                        <div className="tab user_management_tab" style={{ margin: "5px 0px 10px 0px" }}>
                            <div className="icon" style={{
                                margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                                margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 14px 0 10px"}`,
                            }} title="User Management"><i className="fa-solid fa-users"></i></div>
                            <div className="title"><span>User Management</span></div>
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard/super-user-management">
                        <div className="tab" style={{ margin: "5px 0px 10px 0px" }}>
                            <div className="icon" style={{
                                margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                                margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 14px 0 10px"}`,
                            }} title="Super User Management"><i className="fa-solid fa-user-shield"></i></div>
                            <div className="title"><span>Super User Management</span></div>
                        </div>
                    </NavLink>
                </>
                :
                <></>
            }

            {loginUserDetails.userType.toLowerCase() === "customer care" ?
                <>
                    <NavLink to="/dashboard/resolved-complaints" onClick={() => dispatch(hideComplaintDetail())} >
                        <div className="tab" style={{ margin: "10px 0px 25px 0px" }}>
                            <div className="icon" style={{
                                margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                                margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 22px 0 10px"}`,
                            }} title="Resolved Complaints"><i className="fa-regular fa-file-lines"></i>
                                {/* <i className="fa-solid fa-circle-exclamation tab_secondary_icon"></i> */}
                                {(notificationCount === 0 || notificationCount === undefined) ?
                                    <></>
                                    :
                                    <div className='count_div'>
                                        <h6>{notificationCount}</h6>
                                    </div>
                                }
                            </div>
                            <div className="title"><span>Resolved Complaints</span></div>
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard/complaint/raise-complaint">
                        <div className="tab" style={{ margin: "10px 0px 25px 0px" }}>
                            <div className="icon" style={{
                                margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                                margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 15px 0 10px"}`,
                            }} title="Raise Complaint"><i className="fa-solid fa-file-circle-plus"></i></div>
                            <div className="title"><span>Raise Complaint</span></div>
                        </div>
                    </NavLink>
                </>
                :
                <></>
            }

            {["department user", "super user"].includes(loginUserDetails.userType.toLowerCase()) ?
                <NavLink to="/dashboard/department-complaints" onClick={() => dispatch(hideComplaintDetail())}>
                    <div className="tab" style={{ margin: "10px 0px 25px 0px" }}>
                        <div className="icon" style={{
                            margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                            margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 23px 0 10px"}`,
                        }} title="Complaints"><i className="fa-regular fa-file-lines"></i>
                            {/* <i className="fa-solid fa-circle-exclamation tab_secondary_icon"></i> */}
                            {(notificationCount === 0 || notificationCount === undefined) ?
                                <></>
                                :
                                <div className='count_div'>
                                    <h6>{notificationCount}</h6>
                                </div>
                            }
                        </div>
                        <div className="title"><span>Complaints</span></div>
                    </div>
                </NavLink>
                :
                <></>
            }

            {loginUserDetails.userType.toLowerCase() === "admin" ?
                <NavLink to="/dashboard/notifications" onClick={() => dispatch(hideComplaintDetail())}>
                    <div className="tab" style={{ margin: "5px 0px 10px 0px" }}>
                        <div className="icon" style={{
                            margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                            margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 23px 0 10px"}`,
                        }} title="Notifications"><i className="fa-solid fa-bell"></i>
                            {/* <i className="fa-solid fa-circle tab_secondary_icon"></i> */}
                            {(adminNotificationCount === 0 || adminNotificationCount === undefined) ?
                                <></>
                                :
                                <div className='count_div'>
                                    <h6>{adminNotificationCount}</h6>
                                </div>
                            }
                        </div>
                        <div className="title"><span>Notifications</span></div>
                    </div>
                </NavLink>
                :
                <></>
            }

            {loginUserDetails.userType.toLowerCase() === "admin" ?
                <NavLink to="/dashboard/accelated-complaints" onClick={() => dispatch(hideComplaintDetail())}>
                    <div className="tab" style={{ margin: "5px 0px 10px 0px" }}>
                        <div className="icon" style={{
                            margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                            margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 23px 0 10px"}`,
                        }} title="Escalated Complaints"><i className="fa-regular fa-file-lines"></i>
                            {/* <i className="fa-solid fa-circle-exclamation tab_secondary_icon"></i> */}
                            {/* {(notificationCount === 0 || notificationCount == undefined) ?
                                <></>
                                :
                                <div className='count_div'>
                                    <h6>{notificationCount}</h6>
                                </div>
                            } */}
                        </div>
                        <div className="title"><span>Escalated Complaints</span></div>
                    </div>
                </NavLink>
                :
                <></>
            }

            <NavLink to="/dashboard/complaint-lookups" onClick={() => dispatch(hideComplaintDetail())}>
                <div className="tab" style={{ margin: `${loginUserDetails.userType.toLowerCase() === "admin" ? "5px 0px 10px 0px" : "10px 0px 25px 0px"}` }}>
                    <div className="icon" style={{
                        margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                        margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 15px 0 10px"}`,
                    }} title="Complaint Lookups"><i className="fa-solid fa-user-clock"></i>
                        {/* <i className="fa-solid fa-circle-exclamation tab_secondary_icon"></i> */}
                    </div>
                    <div className="title"><span>Complaint Lookups</span></div>
                </div>
            </NavLink>

            {["customer care", "admin"].includes(loginUserDetails.userType.toLowerCase()) ?
                <NavLink to="/dashboard/report" onClick={() => dispatch(hideComplaintDetail())}>
                    <div className="tab user_management_tab" style={{ margin: `${loginUserDetails.userType.toLowerCase() === "admin" ? "5px 0px 10px 0px" : "10px 0px 25px 0px"}` }}>
                        <div className="icon" style={{
                            margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                            margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 20px 0 10px"}`,
                        }} title="Dashboard/Report"><i className="fa-solid fa-gauge"></i></div>
                        <div className="title"><span>Dashboard/Report</span></div>
                    </div>
                </NavLink>
                :
                <></>
            }

            {["department user", "super user"].includes(loginUserDetails.userType.toLowerCase()) ?
                <NavLink to="/dashboard/department-report" onClick={() => dispatch(hideComplaintDetail())}>
                    <div className="tab" style={{ margin: "10px 0px 25px 0px" }}>
                        <div className="icon" style={{
                            margin: `${(windowWidth >= 768) && (windowWidth <= 1024) && (sideNavToggle.sideNavWidth === 5) ? "0 31px 0 3px" : "0 10px 0 10px"}`,
                            margin: `${(windowWidth >= 1025) && (windowWidth <= 2000) && (sideNavToggle.sideNavWidth === 5) ? "0 50px 0 18px" : "0 20px 0 10px"}`,
                        }} title="Dashboard/Report"><i className="fa-solid fa-gauge"></i></div>
                        <div className="title"><span>Dashboard/Report</span></div>
                    </div>
                </NavLink>
                :
                <></>
            }

        </div>
    )
}

export default SideNav