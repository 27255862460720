import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import H3Public from '../../../text-components/H3Public'
import PPublic from '../../../text-components/PPublic';
import { faqArray } from '../../../utilities/faqArray';
import { scrollToTop } from '../../../utilities/scrollToTopFunction';

const FrequentlyAskedQuestions = () => {
  const [answerShow, setAnswerShow] = useState("");
  const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const scrollPage = useRef();
  const scroll = useSelector((state) => state.scrollToTop.scroll);

  useEffect(() => {
      scrollToTop(scrollPage)
  }, [scroll]);


  return (
    <>
      <div className="faq_main" ref={scrollPage}>
        <div className="faq_heading_main" style={{ color: `${theme === "WHITE" ? "#5E7082" : "#000"}` }}>
          <H3Public>Frequently Asked Questions</H3Public>
        </div>

        <div className="que_ans_main">

          {faqArray.map((ans, index) => {
            const { id, question } = ans;
            return (
              <div key={index}>
                <div className="que_ans_text_box">
                  <div className="que_main">

                    <div className="que_mark_icon">
                      <i className="fa-solid fa-circle-question"></i>
                    </div>

                    <div className="que">
                      <H3Public>
                        {question}
                      </H3Public>
                    </div>

                    <div className="que_drop_icon" >
                      {answerShow !== id ?
                        <img src="/FAQ/down_arrow.png" alt="" onClick={() => setAnswerShow(id)} />
                        :
                        <img src="/FAQ/up_arrow.png" alt="" onClick={() => setAnswerShow(!id)} />
                      }
                    </div>

                  </div>
                  {answerShow !== id ?
                    <></> :
                    <div className="ans_main">
                      <div className="ans_sapace"></div>
                      <div className="ans_text_box">
                        {ans?.answer?.map((answer, index) => (
                          <>
                            {ans?.answer?.length > 1 ?
                              <div className="answer">
                                <PPublic><b>{`Step ${index + 1}:`}</b></PPublic>
                                <PPublic>
                                  {answer}
                                </PPublic>
                              </div>
                              :
                              <PPublic>
                                {answer}
                              </PPublic>
                            }
                          </>
                        ))}
                      </div>
                    </div>
                  }
                </div>
              </div>)
          })}



        </div>

      </div>
    </>
  )
}

export default FrequentlyAskedQuestions