import React from 'react'

const Response = ({ setResponse, complaintId }) => {
    return (
        <div className="public_form_section">
            <div className="form_main">
                <div className="form_header">
                    <h6>Response </h6>
                    <span onClick={() => setResponse(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
                <div className="form response_form">
                    <h6>Complaint has been submitted successfully</h6>
                    <h6>Complaint ID - <b>{complaintId}</b></h6>
                    <button onClick={() => setResponse(false)}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default Response;