import React from "react"
import { useSelector } from "react-redux";
import H3Public from "../../../text-components/H3Public";


const AdministrationSection = () => {

    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);



    return (
        <>
            <section className="admin_main">

                <div className="admin_heading">
                    <H3Public>District Administration</H3Public>
                </div>

                <div className="heading_line">
                    <div className="underline_thik"></div>
                    <div className="underline_thin"></div>
                </div>

                <div className="container-fluid">
                    <div className="row">

                        <div className=" col-lg-12 card_width">
                            <div className="admin_card_main">

                                <div className="row">
                                    <div className="col-md-5 col-lg-5 img_box_main">

                                        <div className="img_box_outer">
                                            <div className="img_box">
                                                <img src="/Home/ShriVinayGowda.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="admin_name">
                                            <h4>Shri Vinay Gowda GC</h4>
                                        </div>

                                    </div>

                                    <div className="col-md-7 col-lg-7 content_bg"
                                        style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#1E1E20"}` }}
                                    >
                                        <div className="admin_content_main">
                                            <h4>IAS Collector</h4>
                                            <p>Shri. Vinay Gowda G. C. (IAS - 2015 batch)  is the District Collector of Chandrapur. He has B.Tech (Computer Science) degree from Karnataka. He assumed the charge of the office of Collector from 14 Oct.  2022. Prior to this he served as Z.P. CEO (Satara), SDO (Nandurbar).</p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <div className=" col-lg-6 card_width">
                            <div className="admin_card_main">

                                <div className="row">
                                    <div className="col-md-5 col-lg-5 img_box_main">

                                        <div className="img_box_outer">
                                            <div className="img_box">
                                                <img src="/Home/SudhirMungantiwar.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="admin_name">
                                            <h4>Shri. Sudhir Mungantiwar</h4>
                                        </div>

                                    </div>

                                    <div className="col-md-7 col-lg-7 content_bg"
                                        style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#1E1E20"}` }}
                                    >
                                        <div className="admin_content_main">
                                            <h4>Cabinet Minister, Government of Maharashtra</h4>
                                            <p>
                                                Cabinet Minister of Forest, Cultural and Fishery department in Government of Maharashtra, also Guardian Minister of Chandrapur and Gondia District.
                                            </p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div> */}

                    </div>
                </div>

            </section>
        </>
    );
}

export default AdministrationSection;