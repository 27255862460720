import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import H3Public from "../text-components/H3Public";
import {scrollTop} from "../redux-store/scrollToTop";

const PublicNavbar = () => {

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);


    return (
        <>
            <div className="navbar_main" style={{ backgroundColor: `${theme === "WHITE" ? "#5E7082" : "#5B5050"}` }}>
                <div className="navbar_tab">
                    <NavLink to="/public/homepage" onClick={() => dispatch(scrollTop("home"))}><H3Public>Home</H3Public></NavLink>
                    <NavLink to="/public/about-us" onClick={() => dispatch(scrollTop("about"))}><H3Public>About Us</H3Public></NavLink>
                    <NavLink to="/public/department" onClick={() => dispatch(scrollTop("department"))}><H3Public>Departments</H3Public></NavLink>
                    <NavLink to="/public/frequently-asked-questions" onClick={() => dispatch(scrollTop("faq"))}><H3Public>FAQs</H3Public></NavLink>
                    <NavLink to="/public/contact-us" onClick={() => dispatch(scrollTop("contact"))}><H3Public>Contact Us</H3Public></NavLink>
                </div>
            </div>
        </>
    );
}

export default PublicNavbar;