import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import H3 from '../../../../text-components/H3';
import H5 from '../../../../text-components/H5';
import HeaderText from '../../../../text-components/HeaderText';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from '../../../../utilities/Loader';
import ReactPaginate from 'react-paginate';


const SubDepartmentList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const [filteredSubDepartments, setFilteredSubDepartments] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_LIMIT

    //Onclick set current page
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage)
        if (searchValue) {
            fetchSubDepartmentsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchSubDepartment(selectedPage + 1);
        }
    };



    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString === "") {
            //call API 
            const responseFromServer = await officeCommunication.getSubDepartments();
            if (responseFromServer.data.status === "SUCCESS") {
                setFilteredSubDepartments(responseFromServer.data.subdepartment);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredSubDepartments([]);
                setPageCount(0);
            }
        } else {
            fetchSubDepartmentsBySearch(searchString);
        }
    }

    //Fetch sub department by SearchValue
    const fetchSubDepartmentsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await officeCommunication.getSubDepartmentBySearch(searchString, page);
            if (responseFromServer.data.status === "SUCCESS") {
                setFilteredSubDepartments(responseFromServer.data.subdepartment);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredSubDepartments([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }
    //fetch sub Department details on initial load
    async function fetchSubDepartment(selectedPage) {
        try {
            dispatch(showLoader());
            //call API 
            const serverResponse = await officeCommunication.getSubDepartments(selectedPage);
            if (serverResponse.data.status === "SUCCESS") {
                setFilteredSubDepartments(serverResponse.data.subdepartment);
                setPageCount(serverResponse?.data?.totalPages);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredSubDepartments([]);
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }

    useEffect(() => {
        fetchSubDepartment();
    }, [])

    return (
        <div className="panel">
            {loader === true ?
                <Loader />
                :
                <>
                    <div className="search_header">
                        <H3>Sub Department Management</H3>
                        <div className="search_input">
                            <input type="text" className="form-control" placeholder="Search by Sub Dept..." title="Search by Sub Departement, Departement, location" onChange={(e) => { searchFilter(e.target.value.trim()); setSearchValue(e.target.value.trim()) }} style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </div>
                        <button className="button" onClick={() => navigate("/dashboard/sub-department-management/create-sub-department")}>Create Sub Department</button>
                        {pageCount > 1 &&
                            <div className='pagination_div' style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}` }}>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                    </div>
                    <div className="table_main">
                        <table>
                            <thead>
                                <tr>
                                    <th><HeaderText>Serial Number</HeaderText></th>
                                    <th><HeaderText>Sub Department</HeaderText></th>
                                    <th><HeaderText>Department</HeaderText></th>
                                    <th><HeaderText>Location</HeaderText></th>
                                    <th><HeaderText>Date</HeaderText></th>
                                    <th><HeaderText>Action</HeaderText></th>
                                </tr>
                            </thead>
                            {
                                filteredSubDepartments.length > 0 ?
                                    <>
                                        <tbody>
                                            {filteredSubDepartments.map((subDepartment, index) => {
                                                return (
                                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }} key={index}>
                                                        <td><H5>{(limit * (currentPage)) + (index + 1)}</H5></td>
                                                        <td><H5>{subDepartment.subDepartmentName}</H5></td>
                                                        <td><H5>{subDepartment.departmentName}</H5></td>
                                                        <td><H5>{subDepartment.location}</H5></td>
                                                        <td><H5>{new Date(subDepartment.creationDate).toLocaleDateString()}</H5></td>
                                                        <td onClick={() => navigate(`/dashboard/sub-department-management/update-sub-department?id=${subDepartment._id}`)}><i className="fa-solid fa-pen-to-square"></i></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </>
                                    :
                                    <tbody>
                                        <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}><td></td><td></td><td></td><td></td><td></td></tr>
                                        <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}>
                                            <td></td>
                                            <td></td>
                                            <td><H3>Sub Department Not Available</H3></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                            }

                        </table>
                    </div>
                </>
            }
        </div>
    );
}

export default SubDepartmentList;