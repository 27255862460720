import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './common-components/App';
import "./css/style.css";
import "./css/mediaQuery.css";
import "./css/publicMediaQuery.css"
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux-store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
