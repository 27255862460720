import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { increaseFont, decreaseFont, defaultFont } from "../redux-store/fontSizeMultiplier";
import PublicNavbar from "./PublicNavbar";
import { darkWhiteTheme } from '../redux-store/darkWhiteThemeToggle';
import H4Public from "../text-components/H4Public";

const PublicHeader = ({ setShowModals }) => {
    const dispatch = useDispatch();

    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);

    // Dark White theme Toggle
    const darkWhiteToggle = () => {
        dispatch(darkWhiteTheme(theme === "WHITE" ? "DARK" : "WHITE"));
    }

    // Google Translate API
    const googleTranslateElementInit = (() => {
        new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: "en,hi,mr" }, 'google_translate_element');
    });
    let load = 0
    useEffect(() => {
        if (load === 0) {
            load += 1;
            const script = document.createElement("script");
            script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            script.async = true;
            document.body.appendChild(script);
            window.googleTranslateElementInit = googleTranslateElementInit;
        }
    }, []);

    return (
        <>
            <header className="public_header_main">
                <div className="ribbon_main" style={{ backgroundColor: `${theme === "WHITE" ? "#60676E" : "#131313"}` }}>

                    <div className="ribbon_details">
                        <i className="fa-solid fa-envelope"></i>
                        <a href="mailto:vandemataramchanda@gmail.com"> <H4Public>vandemataramchanda@gmail.com</H4Public> </a>
                        <i className="fa-solid fa-square-phone"></i>
                        <a href="tel:18002338691"> <H4Public>Toll Free : 1800-233-8691</H4Public></a>
                    </div>

                    <div className="ribbon_action_main">

                        <div className="font_size">
                            <span onClick={() => dispatch(increaseFont())}><H4Public>A +</H4Public></span>
                            <span onClick={() => dispatch(defaultFont())}><H4Public>A </H4Public></span>
                            <span onClick={() => dispatch(decreaseFont())}><H4Public>A -</H4Public></span>
                        </div>

                        <div className="form-check form-switch toggel_buttom">
                            <input type="checkbox" className="form-check-input" onChange={() => { darkWhiteToggle() }} />
                        </div>

                        <div className="langauge_main">
                            <div id="google_translate_element" className='google'></div>
                        </div>

                    </div>

                </div>

                <div className="header_section"
                    style={{
                        backgroundColor: `${theme === "WHITE" ? "#E1ECF3" : "#1E1E20"}`,
                        borderTop: `${theme === "WHITE" ? "1px solid #E1ECF3" : "1px solid #707070"}`,
                        borderBottom: `${theme === "WHITE" ? "1px solid #E1ECF3" : "1px solid #707070"}`
                    }}>

                    <div>
                        {theme === "WHITE" ?
                            <img src="/headlogolight.png" alt="vande matram chanda" /> : <img src="headlogodark.png" alt="vande matram chanda" />}
                    </div>
                   
                        <div>
                            <button style={{
                                color: `${theme === "WHITE" ? "#000" : "#000"}`,
                                backgroundColor: `${theme === "WHITE" ? "#fff" : "#FFF"}`,
                                border: `${theme === "WHITE" ? "1px solid #60676E" : "1px solid #FFF"}`
                            }} onClick={() => setShowModals({ mobileVerification: true })}>
                                Raise Complaint - Online
                            </button>
                        </div>
           
                </div>
                    <PublicNavbar />
            </header>
        </>
    );
}
export default PublicHeader;

