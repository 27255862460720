import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import H3 from '../../../../text-components/H3';
import useValidator from "../../../../utilities/useValidator";
import InputField from '../../../../common-components/InputField';
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from '../../../../utilities/Loader';

const CreateSubDepartment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const [validator, showValidationMessage] = useValidator();
    const [departments, setDepartments] = useState([]);
    const loader = useSelector((state) => state.loader.showHideLoader);


    //get all input box values
    const [subDepartmentDetails, setSubDepartmentDetails] = useState(
        {
            subDepartment: "",
            departmentName: "",
            departmentId: "",
            location: ""
        }
    )


    //fetch Department details on initial load
    async function fetchDepartment() {
        try {
            dispatch(showLoader());
            //call API 
            const serverResponse = await officeCommunication.getDepartments();
            if (serverResponse.data.status === "SUCCESS") {
                setDepartments(serverResponse.data.department);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setDepartments([]);
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error.message, icon: "warning" });
            dispatch(hideLoader());
        }
    }




    //Create Sub Department
    const createSubDepartment = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());

            const dataToSend = {
                subDepartmentName: subDepartmentDetails.subDepartment,
                departmentName: subDepartmentDetails.departmentName,
                departmentId: subDepartmentDetails.departmentId,
                location: subDepartmentDetails.location
            }

            //call API 
            const serverResponse = await officeCommunication.createSubDepartment(dataToSend);
            if (serverResponse.data.status === "SUCCESS") {
                navigate("/dashboard/sub-department-management")
                swal({ text: serverResponse.data?.message, icon: "success" });
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error.message, icon: "error" });
            dispatch(hideLoader());
        }
    }


    useEffect(() => {
        fetchDepartment()
    }, [])

    return (
        <div className="dashboard_form_section">
            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form" style={{ boxShadow: `${theme === "WHITE" ? "0px 0px 8px #e4e4e4" : "0px 0px 8px #575757"}` }}>
                    <div className="from_title">
                        <H3>Create Sub Department</H3>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <InputField type="text" name="subDepartment" value={subDepartmentDetails.subDepartment} onChange={(event) => setSubDepartmentDetails({ ...subDepartmentDetails, subDepartment: event.target.value  })} placeholder="Enter Sub Department Name" />
                            {validator.message("subDepartment", subDepartmentDetails.subDepartment, "required", {
                                messages: {
                                    required: "Sub Department is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <select name="department" onChange={(event) => setSubDepartmentDetails({ ...subDepartmentDetails, departmentName: JSON.parse(event.target.value).departmentName, departmentId: JSON.parse(event.target.value)._id })} className="form-select">
                                <option value={JSON.stringify({ _id: "", departmentName: "" })}>Select Department</option>
                                {departments.map((department, index) => {
                                    return (

                                        <option value={JSON.stringify(department)} selected={department._id === subDepartmentDetails.departmentId}>{department.departmentName}</option>
                                    )
                                })}
                            </select>
                            {validator.message("department", subDepartmentDetails.departmentId, "required", {
                                messages: {
                                    required: "Department is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 input_field">
                            <InputField type="text" name="location" value={subDepartmentDetails.location} onChange={(event) => setSubDepartmentDetails({ ...subDepartmentDetails, location: event.target.value  })} placeholder="Enter Location" />
                            {validator.message("location", subDepartmentDetails.location, "required", {
                                messages: {
                                    required: "Location is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="button_group">
                        <button className="button" onClick={() => createSubDepartment()}>Create</button>
                        <button className="button" onClick={() => navigate("/dashboard/sub-department-management")}>Cancel</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default CreateSubDepartment;