import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import H2Public from '../../../text-components/H2Public'
import PPublic from '../../../text-components/PPublic'

const AboutSection = () => {

    const navigate = useNavigate();

    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <>
            <div className="about_border">
                <div className="container-fluid about_section_bg" style={{ backgroundImage: `${theme === "WHITE" ? "linear-gradient(45deg, white, #E1ECF3, #E1ECF3, #E1ECF3)" : "linear-gradient(45deg,#121010, #5B5050)"}` }}>
                    <div className="row">

                        <div className="col-md-6 col-lg-6 about_content">
                            <div>
                                <H2Public>CHANDRAPUR</H2Public>
                                <div className="underline_thik"
                                    style={{ backgroundColor: `${theme === "WHITE" ? "#A6CAE2" : "#8A8D8F"}` }}
                                ></div>
                                <div className="underline_thin"
                                ></div>
                                <div className="paragraph"
                                    style={{
                                        color: `${theme === "WHITE" ? "#394D61" : "#FFF"}`
                                    }}>
                                    <PPublic>
                                        The district Chandrapur was earlier known as ‘Chanda’ according to tradition and legend the name of the place was ‘Lokapura’ which was first changed to ‘ Indpur’ and subsequently to Chandrapur. During the British colonial period it was called Chanda district, which was again changed to its original name ‘Chandrapur ‘ around 1964. Other places of the region in ancient times include wairangad, Kosala, Bhadravati and Markanda. Hindu and Buddhist kings are said to have ruled the area for a long time, Later on Gonds overtook Dana Chiefs who ruled Chandrapur around 9 th century and Gond Kings ruled the area till 1751 after which Maratha period started.
                                    </PPublic>
                                </div>
                                <button onClick={() => { navigate("/public/about-us") }}
                                    style={{
                                        color: `${theme === "WHITE" ? "#394D61" : "#000"}`,
                                        backgroundColor: `${theme === "WHITE" ? "transparent" : "#FFF"}`,
                                        border: `${theme === "WHITE" ? "1px solid #394D61" : "1px solid #FFF"}`
                                    }}
                                >About Us</button>
                            </div>

                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="about_img_box">
                                <img src="/Home/AboutUs.png" alt="chandrapur railway station" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutSection;