import React, { useEffect, useState } from 'react';
import useValidator from "../../../utilities/useValidator";
import InputField from '../../../common-components/InputField';
import swal from 'sweetalert';
import { hideLoader, showLoader } from '../../../redux-store/loaderReducer';
import { publicCommunication } from '../../../services/publicCommunication';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../utilities/Loader';
import { city } from "../../../utilities/cityArray";
import { gramPanchayat } from '../../../utilities/gramPanchayatArray';

const RaiseNewComplaintForm = ({ setRaiseComplaint, setResponse, mobile, setComplaintId }) => {
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader.showHideLoader);

    const [active, setActive] = useState("personal_detail");
    const [validator, showValidationMessage] = useValidator();
    const [departments, setDepartments] = useState([]);
    const [subDepartments, setSubDepartments] = useState([]);
    const [gramPanchayatList, setGramPanchayatList] = useState([]);
    const [personalDetail, setPersonalDetail] = useState(
        {
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            district: "Chandrapur",
            areaType: "",
            city: "",
            panchayatSamiti: "",
            gramPanchayat: "",
            pinCode: "",
            landmark: ""
        }
    );

    const [complaintData, setComplainData] = useState({
        complaintType: "",
        departmentId: "",
        subDepartmentId: "",
        areaType: "",
        city: "",
        panchayatSamiti: "",
        gramPanchayat: "",
        complaintDescription: ""
    });
    const [file, setFile] = useState("");

    //Get Panchayat Samiti wise Gram Panchayat List
    const panchayatSamitiWiseGramPanchayat = (panchayatSamiti) => {
        const filteredGrapmPanchayat = gramPanchayat?.filter((ele) => (ele?.panchayatSamiti === panchayatSamiti));
        setGramPanchayatList(filteredGrapmPanchayat[0]?.gramPanchayat);
    }

    //Save Personal Detail
    const savePersonalDetail = () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            setActive("complaint_detail");
        } catch (error) {
            swal({ text: error.message, icon: "warning" });
        }
    }


    //Submit Complaint
    const SubmitComplaint = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            let isFileAttached = false

            let formData = new FormData();
            if (file) {
                isFileAttached = true
                formData.append("attachment", file);
            }
            const dataToSend = {
                personalDetail,
                mobileNumber: mobile,
                complaintData
            }
            let serverResponse;
            if (isFileAttached === true) {
                formData.append("complaintData", JSON.stringify(dataToSend));
                serverResponse = await publicCommunication.raiseComplaint(isFileAttached, formData);
            } else {
                serverResponse = await publicCommunication.raiseComplaint(isFileAttached, dataToSend);
            }
            if (serverResponse.data.status === "SUCCESS") {
                setComplaintId(serverResponse.data.complaintId)
                setRaiseComplaint(false);
                setResponse(true);
                dispatch(hideLoader());
            } else {
                swal({ text: serverResponse.data.message, icon: "warning" });
                dispatch(hideLoader());
            }

        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
            dispatch(hideLoader());

        }

    }

    //Preview File
    const filePreview = () => {
        if (file) {
            const previewDocument = URL.createObjectURL(file);
            window.open(previewDocument);
        }
    }

    //get all department on initial load
    const getDepartment = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await publicCommunication.getAllDepartment();
            if (serverResponse.data.status === "SUCCESS") {
                setDepartments(serverResponse.data.department);
                dispatch(hideLoader())
            } else {
                setDepartments([]);
                dispatch(hideLoader())

            }

        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
            dispatch(hideLoader())

        }
    }

    //get departmentWise subdepartment
    async function getSubDepartment(departmentId) {
        try {
            if (!["", null, undefined].includes(departmentId)) {
                const serverResponse = await publicCommunication.getDepartmentWiseSubDepartment(departmentId);
                if (serverResponse.data.status === "SUCCESS") {
                    setSubDepartments(serverResponse.data.subdepartment);
                } else {
                    setSubDepartments([]);
                }

            }
        } catch (error) {
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });

        }
    }

    useEffect(() => {
        getDepartment();
    }, [])
    return (
        <div className="public_form_section">
            {loader === true ?
                <Loader />
                :
                <div className="form_main raise_complaint_form">
                    <div className="form_header">
                        <h6>Complaint Form </h6>
                        <span onClick={() => setRaiseComplaint(false)}>
                            <i className="fa-solid fa-xmark"></i>
                        </span>
                    </div>

                    <div className="form">
                        <div className="raise_complaint_tabs">
                            <div className={`tab ${active === "personal_detail" ? "personal_detail" : ""}`} onClick={() => setActive("personal_detail")}>
                                <h6>Personal Detail</h6>
                            </div>
                            <div className={`tab ${active === "complaint_detail" ? "complaint_detail" : ""}`} onClick={() => setActive("complaint_detail")}>
                                <h6>Complaint Detail</h6>
                            </div>
                        </div>
                        {active === "personal_detail" &&
                            <div className="public_personal_detail_form">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <InputField type="text" name="firstName" value={personalDetail?.firstName} onChange={(event) => setPersonalDetail({ ...personalDetail, firstName: event.target.value })} placeholder="First Name*" />
                                        {validator.message("firstName", personalDetail.firstName, "required", {
                                            messages: {
                                                required: "First Name is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <InputField type="text" name="lastName" value={personalDetail?.lastName} onChange={(event) => setPersonalDetail({ ...personalDetail, lastName: event.target.value })} placeholder="Last Name*" />
                                        {validator.message("lastName", personalDetail.lastName, "required", {
                                            messages: {
                                                required: "Last Name is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <InputField type="email" name="email" value={personalDetail?.email} onChange={(event) => setPersonalDetail({ ...personalDetail, email: event.target.value })} placeholder="Email" />
                                        {/* {validator.message("email", personalDetail.email, "email", {
                                            messages: {
                                                required: "Email is required"
                                            }
                                        })} */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <InputField type="number" name="mobileNumber" value={mobile} disabled placeholder="Mobile Number*" />
                                        {validator.message("mobileNumber", mobile, "required|numeric|min:10|max:10", {
                                            messages: {
                                                required: "Mobile Number is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <InputField type="text" name="district" value={personalDetail?.district} onChange={(event) => setPersonalDetail({ ...personalDetail, district: event.target.value })} placeholder="District" disabled />
                                    </div>
                                    <div className="col-lg-4 col-md-4 input_field">
                                        <InputField type="number" name="pinCode" value={personalDetail?.pinCode} onChange={(event) => setPersonalDetail({ ...personalDetail, pinCode: event.target.value })} placeholder="Pin Code*" />
                                        {validator.message("pinCode", personalDetail.pinCode, "required|numeric|min:6|max:6", {
                                            messages: {
                                                required: "Pin Code is required"
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 input_field">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="areaType" value="urban" checked={personalDetail?.areaType === "urban" ? true : false} onChange={(event) => setPersonalDetail({ ...personalDetail, areaType: event.target.value })} id="flexRadioDefault1" />
                                            <label className="form-check-label" for="flexRadioDefault1">
                                                <h6>Urban</h6>
                                            </label>
                                        </div>
                                        {validator.message("areaType", personalDetail.areaType, "required", {
                                            messages: {
                                                required: "Area Type is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-2 col-md-2 input_field">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="areaType" value="rural" checked={personalDetail?.areaType === "rural" ? true : false} onChange={(event) => setPersonalDetail({ ...personalDetail, areaType: event.target.value })} id="flexRadioDefault1" />
                                            <label className="form-check-label" for="flexRadioDefault1">
                                                <h6>Rural</h6>
                                            </label>
                                        </div>
                                    </div>
                                    {personalDetail?.areaType === "urban" &&
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <select name="city" onChange={(event) => setPersonalDetail({ ...personalDetail, city: event.target.value })} className="form-select">
                                                <option value="">Select City*</option>
                                                {city?.map((cityName, index) => (
                                                    <option value={cityName} selected={cityName === personalDetail.city ? true : false} key={index}>{cityName}</option>
                                                ))}
                                            </select>
                                            {validator.message("city", personalDetail.city, "required", {
                                                messages: {
                                                    required: "City is required"
                                                }
                                            })}
                                        </div>
                                    }
                                    {personalDetail?.areaType === "rural" &&
                                        <>
                                            <div className="col-lg-4 col-md-4 input_field">
                                                <select name="panchayatSamiti" onChange={(event) => { setPersonalDetail({ ...personalDetail, panchayatSamiti: event.target.value }); panchayatSamitiWiseGramPanchayat(event.target.value) }} className="form-select">
                                                    <option value="">Select Tehsil*</option>
                                                    {gramPanchayat?.map((panchayatSamitiList, index) => (
                                                        <option value={panchayatSamitiList?.panchayatSamiti} selected={panchayatSamitiList?.panchayatSamiti === personalDetail.panchayatSamiti ? true : false} key={index}>{panchayatSamitiList?.panchayatSamiti}</option>
                                                    ))}
                                                </select>
                                                {validator.message("panchayatSamiti", personalDetail.panchayatSamiti, "required", {
                                                    messages: {
                                                        required: "Panchayat Samiti is required"
                                                    }
                                                })}
                                            </div>
                                            <div className="col-lg-4 col-md-4 input_field">
                                                <select name="grampanchayat" onChange={(event) => setPersonalDetail({ ...personalDetail, gramPanchayat: event.target.value })} className="form-select">
                                                    <option value="">Select Grampanchayat*</option>
                                                    {gramPanchayatList?.map((gramPanchayat, index) => (
                                                        <option value={gramPanchayat} selected={gramPanchayat === personalDetail.gramPanchayat ? true : false} key={index}>{gramPanchayat}</option>
                                                    ))}
                                                </select>
                                                {validator.message("grampanchayat", personalDetail.gramPanchayat, "required", {
                                                    messages: {
                                                        required: "Gram Panchayat is required"
                                                    }
                                                })}
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 input_field">
                                        <textarea name="landmark" value={personalDetail?.landmark} className="form-control" onChange={(event) => setPersonalDetail({ ...personalDetail, landmark: event.target.value })} placeholder="Landmark*" />
                                        {validator.message("landmark", personalDetail.landmark, "required", {
                                            messages: {
                                                required: "Landmark is required"
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="form_button_group">
                                    <button onClick={savePersonalDetail}>Save & Next</button>
                                </div>
                            </div>
                        }
                        {active === "complaint_detail" &&
                            <div className="public_complaint_detail_form">
                                <div className="row mb-lg-4 mb-md-4 mb-0">
                                    <div className="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-2">
                                        <select name="complaintType" onChange={(event) => setComplainData({ ...complaintData, complaintType: event.target.value })} className="form-select" >
                                            <option value="">Complaint Type*</option>
                                            <option value="Administrative" selected={complaintData?.complaintType === "Administrative" ? true : false}>Administrative</option>
                                        </select>
                                        {validator.message("complaintType", complaintData?.complaintType, "required", {
                                            messages: {
                                                required: "Complaint Type is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-md-4 mb-lg-0 mb-md-0 mb-2">
                                        <select name="department" onChange={(event) => { setComplainData({ ...complaintData, departmentId: event.target.value }); getSubDepartment(event.target.value) }} className="form-select" >
                                            <option value="">Department Name*</option>
                                            {departments?.map((department, index) => {
                                                return (
                                                    <option value={department._id} key={index} selected={complaintData.departmentId === department._id ? true : false}>{department.departmentName}</option>

                                                )
                                            })}
                                        </select>
                                        {validator.message("department", complaintData?.departmentId, "required", {
                                            messages: {
                                                required: "Department is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-md-4 mb-lg-0 mb-md-0 mb-2">
                                        <select name="subDepartment" onChange={(event) => setComplainData({ ...complaintData, subDepartmentId: event.target.value })} className="form-select" >
                                            <option value="">Sub Department Name*</option>
                                            {subDepartments.map((subDepartment, index) => {
                                                return (
                                                    <option value={subDepartment._id} selected={complaintData?.subDepartmentId === subDepartment._id ? true : false}>{subDepartment.subDepartmentName}</option>
                                                )
                                            })}
                                        </select>
                                        {validator.message("subDepartment", complaintData?.subDepartmentId, "required", {
                                            messages: {
                                                required: "Sub Department is required"
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="row mb-lg-4 mb-md-4 mb-0">
                                    <div className="col-lg-2 col-md-2 input_field">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="areaType" value="urban" checked={complaintData?.areaType === "urban" ? true : false} onChange={(event) => setComplainData({ ...complaintData, areaType: event.target.value })} id="flexRadioDefault1" />
                                            <label className="form-check-label" for="flexRadioDefault1">
                                                <h6>Urban</h6>
                                            </label>
                                        </div>
                                        {validator.message("areaType", complaintData.areaType, "required", {
                                            messages: {
                                                required: "Area Type is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-2 col-md-2 input_field">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="areaType" value="rural" checked={complaintData?.areaType === "rural" ? true : false} onChange={(event) => setComplainData({ ...complaintData, areaType: event.target.value })} id="flexRadioDefault1" />
                                            <label className="form-check-label" for="flexRadioDefault1">
                                                <h6>Rural</h6>
                                            </label>
                                        </div>
                                    </div>
                                    {complaintData?.areaType === "urban" &&
                                        <div className="col-lg-4 col-md-4 input_field">
                                            <select name="city" onChange={(event) => setComplainData({ ...complaintData, city: event.target.value })} className="form-select">
                                                <option value="">Select City*</option>
                                                {city?.map((cityName, index) => (
                                                    <option value={cityName} selected={cityName === complaintData.city ? true : false} key={index}>{cityName}</option>
                                                ))}
                                            </select>
                                            {validator.message("city", complaintData.city, "required", {
                                                messages: {
                                                    required: "City is required"
                                                }
                                            })}
                                        </div>
                                    }
                                    {complaintData?.areaType === "rural" &&
                                        <>
                                            <div className="col-lg-4 col-md-4 input_field">
                                                <select name="panchayatSamiti" onChange={(event) => { setComplainData({ ...complaintData, panchayatSamiti: event.target.value }); panchayatSamitiWiseGramPanchayat(event.target.value) }} className="form-select">
                                                    <option value="">Select Tehsil*</option>
                                                    {gramPanchayat?.map((panchayatSamitiList, index) => (
                                                        <option value={panchayatSamitiList?.panchayatSamiti} selected={panchayatSamitiList?.panchayatSamiti === complaintData.panchayatSamiti ? true : false} key={index}>{panchayatSamitiList?.panchayatSamiti}</option>
                                                    ))}
                                                </select>
                                                {validator.message("panchayatSamiti", complaintData.panchayatSamiti, "required", {
                                                    messages: {
                                                        required: "Tehsil is required"
                                                    }
                                                })}
                                            </div>
                                            <div className="col-lg-4 col-md-4 input_field">
                                                <select name="grampanchayat" onChange={(event) => setComplainData({ ...complaintData, gramPanchayat: event.target.value })} className="form-select">
                                                    <option value="">Select Grampanchayat*</option>
                                                    {gramPanchayatList?.map((gramPanchayat, index) => (
                                                        <option value={gramPanchayat} selected={gramPanchayat === complaintData.gramPanchayat ? true : false} key={index}>{gramPanchayat}</option>
                                                    ))}
                                                </select>
                                                {validator.message("grampanchayat", complaintData.gramPanchayat, "required", {
                                                    messages: {
                                                        required: "Gram Panchayat is required"
                                                    }
                                                })}
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="row mb-lg-4 mb-md-4 mb-0">
                                    <div className="col-lg-8 col-md-8 mb-lg-0 mb-md-0 mb-2">
                                        <textarea name="complaintDescription" value={complaintData?.complaintDescription} onChange={(event) => setComplainData({ ...complaintData, complaintDescription: event.target.value })} className="form-control" placeholder="Complaint Detail*" />
                                        {validator.message("complaintDescription", complaintData?.complaintDescription, "required|min:100", {
                                            messages: {
                                                required: "Complaint Description is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-2 attachement">
                                        <InputField type="file" name="attachement" onChange={(event) => setFile(event.target.files[0])} placeholder="Attach" />
                                        <p>*Mandatory Field Allowed Format(pdf, jpg, jpeg, png & Size must be less than 2MB) </p>
                                        <h6>*Upload the document while physical raising the complaint.</h6>
                                        {![undefined, null, ""].includes(file) &&
                                            <span onClick={filePreview} title="Attached Document Preview"><i className="fa-solid fa-paperclip preview"></i></span>
                                        }
                                        {validator.message("attachment", file, "required", {
                                            messages: {
                                                required: "File is required"
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="form_button_group">
                                    <button onClick={SubmitComplaint} >Submit Complaint</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default RaiseNewComplaintForm