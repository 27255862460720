import React from 'react';
import { useSelector } from 'react-redux';

const HeaderText = ({ children }) => {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier.fontSize);

    return (
        <h4 className="header_text" style={{ fontSize: `${15 * fontSizeMultiplier / 100}px` }}>{children}</h4>

    )
}

export default HeaderText