import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import H5 from "../../../../text-components/H5";
import HeaderText from "../../../../text-components/HeaderText";
import ComplaintDetail from "../../../../common-components/ComplaintDetail";
import { showComplaintDetail } from "../../../../redux-store/showComplaintDetailReducer";
import { officeCommunication } from '../../../../services/officeCommunication';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import swal from 'sweetalert';
import Loader from "../../../../utilities/Loader";
import H3 from "../../../../text-components/H3";
import Button from "../../../../common-components/Button";
import ReactPaginate from "react-paginate";
import ExportToExcelModal from "../../../../common-components/ExportToExcelModal";
import FileSaver from 'file-saver';


const AllComplaints = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const showComplaintForm = useSelector((state) => state.showComplaintDetailReducer.formState);
    const [exportToExcelModalState, setExportToExcelModalState] = useState(false);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const [filterComplaints, setFilterComplaints] = useState([]);
    const [dateFilter, setFilterDate] = useState({
        startDate: "",
        endDate: ""
    });
    const [selectedComplaint, setSelectedComplaint] = useState("");
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_LIMIT ?? 20

    //Onclick set current page
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            getComplaintsBySearch(searchValue, selectedPage + 1);
        } else {
            getComplaints(selectedPage + 1);
        }
    };


    //Export Data to Excel
    const exportData = async () => {
        try {
            const totalComplaints = `${pageCount === 1 ? pageCount * limit : (pageCount - 1) * limit}`
            swal({
                // title: "Are you sure?",
                text: pageCount > 1 ? `Complaints are more than ${totalComplaints}, Are you sure you want to export?` : "Are you sure you want to export?",
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    dispatch(showLoader());
                    const serverResponse = await officeCommunication.exportDataAllComplaints("all");
                    if (serverResponse.data) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([serverResponse.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        });
                        FileSaver.saveAs(file, "AllComplaint.xlsx");
                    } else {
                        swal({ text: "Failed to export data in excel", icon: "warning" });
                    }
                    dispatch(hideLoader());

                } else {
                    return;
                }
            });
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    }


    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString === "") {
            const serverResponse = await officeCommunication.getComplaintByStatus("all");
            if (serverResponse?.data?.status === "SUCCESS") {
                setFilterComplaints(serverResponse?.data?.complaints);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
            } else {
                setFilterComplaints([]);
                setPageCount(0);
            }
        } else {
            getComplaintsBySearch(searchString);
        }
    }

    //get Complaints By Search
    const getComplaintsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await officeCommunication.getLookupComplaintsBySearch("all", searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilterComplaints(responseFromServer?.data?.complaints);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilterComplaints([]);
                setPageCount(0);
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }

    //get complaints details on initial load
    const getComplaints = async (selectedPage) => {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getComplaintByStatus("all", selectedPage, dateFilter.startDate, dateFilter.endDate);
            if (serverResponse?.data?.status === "SUCCESS") {
                setFilterComplaints(serverResponse?.data?.complaints);
                setPageCount(serverResponse?.data?.totalPages);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setFilterComplaints([]);
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };



    useEffect(() => {
        getComplaints()
    }, [])

    //run this code when user change the date
    useEffect(() => {
        if (!(["", null, undefined].includes(dateFilter.startDate)) && !(["", null, undefined].includes(dateFilter.endDate))) {
            getComplaints();
        }
    }, [dateFilter]);

    return (
        <>
            {loader === true ?
                <Loader />
                :
                <>
                    <div className="lookups_search_header">
                        <div className="search_input">
                            <input type="text" className="form-control" placeholder="Search by complaintId, department, subDepartment" title="Search by Sub Departement, Departement, location" onChange={(e) => { searchFilter(e.target.value.trim()); setSearchValue(e.target.value.trim()) }} style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </div>
                        <div className="date_filter">
                            <H5>From Date:</H5>
                            <input type="date" className="form-control" value={dateFilter.startDate} onChange={(e) => setFilterDate({ ...dateFilter, startDate: e.target.value })} style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                        </div>
                        <div className="date_filter">
                            <H5>To Date:</H5>
                            <input type="date" className="form-control" value={dateFilter.endDate} onChange={(e) => setFilterDate({ ...dateFilter, endDate: e.target.value })} style={{ color: `${theme === "WHITE" ? "#8887C9" : "#ffffff"}` }} />
                        </div>
                        {filterComplaints.length > 0 &&
                            <div className="button_group lookup_button">
                                {(loginUserDetails.userType.toLowerCase() === "department user" && ["Nodal Officer", "Sub HOD"].includes(loginUserDetails.level)) ?
                                    <Button name="Export to Excel" onClick={() => exportData()} icon={<i className="fa-regular fa-file-lines"></i>} />
                                    :
                                    <Button name="Export to Excel" onClick={() => setExportToExcelModalState(true)} icon={<i className="fa-regular fa-file-lines"></i>} />
                                }
                            </div>
                        }
                    </div>
                    <div className="table_main lookup_table_main">
                        <table>
                            <thead>
                                <tr>
                                    <th><HeaderText>Serial Number</HeaderText></th>
                                    <th><HeaderText>Complaint Id</HeaderText></th>
                                    <th><HeaderText>Mobile No</HeaderText></th>
                                    <th><HeaderText>Department</HeaderText></th>
                                    <th><HeaderText>Sub Department</HeaderText></th>
                                    <th><HeaderText>Post</HeaderText></th>
                                    <th><HeaderText>Level</HeaderText></th>
                                    <th><HeaderText>Complaint Type</HeaderText></th>
                                    <th><HeaderText>Area Type</HeaderText></th>
                                    <th><HeaderText>City /Grampanchayat</HeaderText></th>
                                    <th><HeaderText>Date</HeaderText></th>
                                    <th><HeaderText>Status</HeaderText></th>
                                </tr>
                            </thead>
                            {filterComplaints.length > 0 ?
                                <>
                                    <tbody>
                                        {filterComplaints.map((complaint, index) => {
                                            return (
                                                <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}`, boxShadow: `${theme === "WHITE" ? "3px 3px 3px #e4e4e4" : "3px 3px 3px #575757"}` }} key={index}>
                                                    <td><H5>{(limit * (currentPage)) + (index + 1)}</H5></td>
                                                    <td className="link" onClick={() => { dispatch(showComplaintDetail()); setSelectedComplaint(complaint.complaintId) }}><H5>{complaint.complaintId}</H5></td>
                                                    <td><H5>{complaint?.personalDetails.mobileNumber}</H5></td>
                                                    <td><H5>{complaint?.departmentName}</H5></td>
                                                    <td><H5>{complaint?.subDepartmentName}</H5></td>
                                                    <td>{complaint?.postName ? <H5>{complaint?.postName}</H5> : <H5>--</H5>}</td>
                                                    <td><H5>{complaint?.level}</H5></td>
                                                    <td><H5>{complaint?.complaintType}</H5></td>
                                                    <td><H5>{complaint?.personalDetails.areaType}</H5></td>
                                                    <td><H5>{complaint?.personalDetails?.city !== "" ? complaint?.personalDetails?.city : complaint?.personalDetails?.gramPanchayat}</H5></td>
                                                    <td><H5>{new Date(complaint?.creationDate).toLocaleDateString()}</H5></td>
                                                    <td><H5>{complaint?.departmentStatus}</H5></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </>
                                :
                                <tbody>
                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}><td></td><td></td><td></td><td></td><td></td></tr>
                                    <tr style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "rgb(68, 68, 68)"}` }}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><H3>Complaint Not Available</H3></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            }

                        </table>
                    </div>
                    {showComplaintForm === false &&
                        <>
                            {pageCount > 1 &&
                                <div className='pagination_div' style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#3A3939"}` }}>
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        forcePage={currentPage}
                                        activeClassName={"active"} />
                                </div>
                            }
                        </>
                    }
                    {showComplaintForm &&
                        <ComplaintDetail selectedComplaint={selectedComplaint} getComplaints={getComplaints} />
                    }
                    {exportToExcelModalState &&
                        <ExportToExcelModal setExportToExcelModalState={setExportToExcelModalState} pageCount={pageCount} complaintStatus="all" />
                    }
                </>
            }
        </>
    );
}

export default AllComplaints;