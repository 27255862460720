import React from "react";
import { Outlet } from "react-router-dom";
import ComplaintLookupTabs from "../../../../common-components/ComplaintLookupTabs";
import H3 from "../../../../text-components/H3";

const ComplaintLookups = () => {

    return (
        <div className="panel">
            <div className="complain_lookups_tab_header">
                <H3>Complaint Lookups</H3>
                <ComplaintLookupTabs />
            </div>
            <Outlet></Outlet>
        </div>
    );
}

export default ComplaintLookups;