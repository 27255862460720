import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import H3Public from '../../../text-components/H3Public';
import { publicCommunication } from '../../../services/publicCommunication';
import swal from 'sweetalert';
import IntegerToRoman from '../../../utilities/interger-to-roman';
import Loader from '../../../utilities/Loader';
import { hideLoader, showLoader } from '../../../redux-store/loaderReducer';
import { scrollToTop } from '../../../utilities/scrollToTopFunction';

const Department = () => {

  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader.showHideLoader);
  const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const [departments, setDepartments] = useState([]);
  const scrollPage = useRef();
  const scroll = useSelector((state) => state.scrollToTop.scroll);

  //get Department list
  async function getDepartments() {
    try {
      dispatch(showLoader());
      const serverResponse = await publicCommunication.fetchDepartment();
      if (serverResponse.data.status === "SUCCESS") {
        setDepartments(serverResponse.data.department)
        dispatch(hideLoader());
      } else {
        setDepartments([]);
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(hideLoader());
      swal({ text: error.message, icon: "warning" });

    }
  }

  useEffect(() => {
    getDepartments()
  }, [])

  useEffect(() => {
    scrollToTop(scrollPage);
  }, [scroll]);

  return (
    <>
      {loader === true ?
        <Loader />
        :
        <section className="department_main" ref={scrollPage}>

          <div className="department_heading">
            <H3Public>District Administration</H3Public>
          </div>

          <div className="heading_line">
            <div className="underline_thik"></div>
            <div className="underline_thin"></div>
          </div>

          <div className='container-fluid department_table_main'>
            <div className="row pb-4">

              <div className="department_table_heading" style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#858282"}`, color: `${theme === "WHITE" ? "#FFF" : "#000"}` }}>
                <div className="first_heading"><H3Public>Serial Number.</H3Public></div>
                <div className="second_heading"><H3Public>Departments</H3Public></div>
                <div className="second_heading"><H3Public>Sub-Departments</H3Public></div>
              </div>

              {departments.length > 0 ?
                <>
                  {departments.map((departmentDetails, index) => {
                    const { departmentName, subdepartmentList } = departmentDetails
                    return (
                      <div key={index}>
                        <div className="department_table_data">
                          <div className="first_data"><H3Public>{index + 1}</H3Public></div>
                          <div className="second_data"><H3Public>{departmentName}</H3Public></div>
                          <div className="second_data">
                            {subdepartmentList?.map((subDepartment, sunDepartmentIndex) => {
                              return (
                                <div className="row_subdepartment">
                                  <div className="col_subdepartment">
                                    <H3Public>{`${IntegerToRoman(sunDepartmentIndex + 1).toLowerCase()}. ${subDepartment.subDepartmentName}`}</H3Public>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
                :
                <div className="department_list_not_available">Department Not available</div>
              }
            </div>
          </div>

        </section>
      }

    </>
  )
}

export default Department