import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import InputField from '../../../../common-components/InputField';
import { officeCommunication } from '../../../../services/officeCommunication';
import H3 from '../../../../text-components/H3';
import H5 from '../../../../text-components/H5';
import { showLoader, hideLoader } from '../../../../redux-store/loaderReducer';
import Loader from '../../../../utilities/Loader';

const SuperUserAssignedDepartments = ({ showSuperUserAccessDepartment, setSuperUserAccessDepartment }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const loader = useSelector((state) => state.loader.showHideLoader);
    const [userArray, setUserArray] = useState([]);

    //get Access Departments
    const fetchUsers = async () => {
        try {
            dispatch(showLoader());
            const responseFromServer = await officeCommunication.getUserByUserId(showSuperUserAccessDepartment?.userId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setUserArray(responseFromServer?.data?.usersDetails?.users);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                setUserArray([]);
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error?.message, icon: "warning" });
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <div className="complaint_detail_section" style={{ backgroundColor: `${theme === "WHITE" ? "rgba(0, 0, 0, 0.521)" : "#4444448e"}` }}>
            {loader === true ?
                <Loader />
                :
                <div className="dashboard_form super_user_access_list_form" style={{ backgroundColor: `${theme === "WHITE" ? "#fff" : "rgb(48, 48, 48)"}`, boxShadow: `${theme === "WHITE" ? "none" : "0px 0px 8px #575757"}` }}>
                    <div className="close_complaint_model" onClick={() => setSuperUserAccessDepartment({ modal: false, userId: "" })}>
                        <span style={{ color: `${theme === "WHITE" ? "#000" : "#fff"}` }}><i className="fa-solid fa-xmark"></i></span>
                    </div>
                    <div className="from_title">
                        <H3>Super User Access Departments</H3>
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-1 col-md-1">
                            <H5>Sr. No</H5>
                        </div>
                        <div className="col-lg-2 col-md-2">
                            <H5>User Id</H5>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <H5>Department</H5>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <H5>Sub Department</H5>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <H5>Post</H5>
                        </div>
                    </div>
                    {userArray?.map((users, index) => (
                        <div className="row mb-2" key={index}>
                            <div className="col-lg-1 col-md-1">
                                <InputField value={index + 1} disabled />
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <InputField value={users?.userId} disabled />
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <InputField value={users?.departmentName} disabled />
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <InputField value={users?.subDepartmentName} disabled />
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <InputField value={users?.postName} disabled />
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default SuperUserAssignedDepartments