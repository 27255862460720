function IntegerToRoman(num) {
    var lookup = { XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 }, roman = '';
    for (let i in lookup) {
        while (num >= lookup[i]) {
            roman += i;
            num -= lookup[i];
        }
    }
    return roman;
}

export default IntegerToRoman;