import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const ComplaintLookupTabs = () => {

    const loginUserDetails = useSelector((state) => state.userDetails);

    return (
        <div className="complaint_lookups_tab_main">
            <NavLink to="/dashboard/complaint-lookups/all-complaints">
                <div className="lookup_tab">
                    <span>ALL</span>
                </div>
            </NavLink>
            <NavLink to="/dashboard/complaint-lookups/new-complaints">
                <div className="lookup_tab">
                    <span>NEW</span>
                </div>
            </NavLink>
            <NavLink to="/dashboard/complaint-lookups/in-progress-complaints">
                <div className="lookup_tab">
                    <span>REASSIGNED</span>
                </div>
            </NavLink>
            <NavLink to="/dashboard/complaint-lookups/resolved-complaints">
                <div className="lookup_tab">
                    <span>RESOLVED</span>
                </div>
            </NavLink>
            <NavLink to="/dashboard/complaint-lookups/re-opened-complaints">
                <div className="lookup_tab">
                    <span>RE-OPENED</span>
                </div>
            </NavLink>
            <NavLink to="/dashboard/complaint-lookups/closed-complaints">
                <div className="lookup_tab">
                    <span>CLOSED</span>
                </div>
            </NavLink>
            {["customer care", "admin"].includes(loginUserDetails.userType.toLowerCase()) &&
                <NavLink to="/dashboard/complaint-lookups/rejected-complaints">
                    <div className="lookup_tab">
                        <span>REJECTED</span>
                    </div>
                </NavLink>
            }
        </div>
    );
}

export default ComplaintLookupTabs;