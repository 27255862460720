import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { increaseFont, defaultFont, decreaseFont } from '../redux-store/fontSizeMultiplier';
import { darkWhiteTheme } from '../redux-store/darkWhiteThemeToggle';
import { navToggle } from '../redux-store/sideNavToggle';
import { officeCommunication } from '../services/officeCommunication';
import { showLoader, hideLoader } from '../redux-store/loaderReducer';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

const DashboardTopHeader = ({ setToggleProfile, toggleProfile }) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.userDetails.userId);
    const navigate = useNavigate();
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
    const sideNavToggle = useSelector((state) => state.sideNavToggle);
    const [imgBlob, setImgBlob] = useState("");
    const [userDetails, setUserDetails] = useState({
        userName: "",
        userType: ""
    });




    //Toggle Theme
    const lightTheme = () => {
        dispatch(darkWhiteTheme("WHITE"));
    }
    const DarkTheme = () => {
        dispatch(darkWhiteTheme("DARK"));
    }

    //side nav toggle
    const toggleSideNav = () => {
        dispatch(navToggle(sideNavToggle.panelWidth === 95 ? 80 : 95));
    }

    // Google Translate API
    const googleTranslateElementInit = (() => {
        new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: "en,hi,mr" }, 'google_translate_element');
    });
    let load = 0
    useEffect(() => {
        if (load === 0) {
            load += 1;
            const script = document.createElement("script");
            script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            script.async = true;
            document.body.appendChild(script);
            window.googleTranslateElementInit = googleTranslateElementInit;
        }
    }, []);

    //get user profile image
    async function getProfileImage() {
        try {
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getUserProfileImage();
            if (serverResponse.data && serverResponse.data.type === "text/xml") {
                //Create a Blob from the PDF Stream
                const file = new Blob([serverResponse.data], {
                    type: "image/jpg"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                setImgBlob(pdfBlob);
            } else {
                setImgBlob("");
            }

            dispatch(hideLoader());

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    };


    //get login userDetails on initial load
    const getUserDetails = async () => {
        try {
            //call api
            dispatch(showLoader());
            const serverResponse = await officeCommunication.getUserByUserId(userId)
            if (serverResponse?.data?.status === "SUCCESS") {
                setUserDetails(serverResponse?.data?.usersDetails);
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                navigate("/login");
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                dispatch(hideLoader());
            } else {
                setUserDetails({});
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.response?.data?.message || error.message, icon: "warning" });
        }
    }


    useEffect(() => {
        getUserDetails();
        getProfileImage();
    }, [])

    return (
        <header className="top_header" style={{ backgroundColor: `${theme === "WHITE" ? "#FFFFFF" : "#444444"}`, boxShadow: `${theme === "WHITE" ? "5px 5px 5px #e4e4e4" : "5px 5px 5px #575757"}` }}>
            <div className="toggler_icon" onClick={() => toggleSideNav()}>
                <img className="img-fluid" src="toggler-icon.png" alt="toggler button" />
            </div>
            <div className="header_action_section">
                <div className="font_multiplier">
                    <span onClick={() => dispatch(increaseFont())}>A<sup>+</sup></span>
                    <span onClick={() => dispatch(defaultFont())}>A</span>
                    <span onClick={() => dispatch(decreaseFont())}>A<sup>-</sup></span>
                </div>
                <div className="dark_light_mode" style={{ backgroundColor: `${theme === "WHITE" ? "#F4F4FC" : "#444444"}` }}>
                    <div className="light_mode" style={{ backgroundColor: `${theme === "WHITE" ? "#ffffff" : "#444444"}` }} onClick={() => lightTheme()}>
                        <i className="fa-solid fa-sun"></i>
                    </div>
                    <div className="dark_mode" style={{ backgroundColor: `${theme === "WHITE" ? "#F4F4FC" : "#3A3939"}` }} onClick={() => DarkTheme()}>
                        <i className="fa-solid fa-moon"></i>
                    </div>
                </div>

                <div className="langauge_main">
                    <div id="google_translate_element" className='google'></div>
                </div>

                <div className="user_profile_action_section">
                    <div className="user_icon">
                        {/* image */}
                        {imgBlob ?
                            <img className="img-fluid" src={imgBlob} alt="profile" />
                            :
                            <div>
                                <i className="fa-solid fa-user"></i>
                            </div>
                        }
                    </div>
                    <div className="user_detail">
                        <div className="user_name">
                            <h5>{userDetails.userName}</h5>
                            <div onClick={() => setToggleProfile(!toggleProfile)}>
                                <i className="fa-solid fa-angle-down"></i>
                            </div>
                        </div>
                        <div className="user_type">
                            <h6>{userDetails.userType}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </header >
    )
}

export default DashboardTopHeader