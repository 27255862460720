export const sliderOneLink = [
    {
        "name": "Government of India",
        "link": "https://www.india.gov.in/",
        "img": "/Home/3.png",
    },
    {
        "name": "Government of Maharashtra",
        "link": "https://www.maharashtra.gov.in/1125/Home",
        "img": "/Home/1.png",
    },
    {
        "name": "Zilha Parishad Chandrapur",
        "link": "https://zpchandrapur.maharashtra.gov.in/en",
        "img": "/Home/2.png",
    },
    {
        "name": "Collector Office Chandrapur",
        "link": "https://chanda.nic.in/",
        "img": "/Home/1.png",
    },
];

export const sliderTwoLink = [
    {
        "name": "Chandrapur Municipal Corporation",
        "link": "https://www.cmcchandrapur.com",
        "img": "/Home/4.png",
    },
    {
        "name": "Maharashtra  Industrial Development Corporation",
        "link": "https://www.midcindia.org/",
        "img": "/Home/5.png",
    },
    {
        "name": "Maharashtra Knowledge Corporation",
        "link": "https://www.mkcl.org/",
        "img": "/Home/6.png",
    },
    {
        "name": "Mumbai High Court",
        "link": "https://bombayhighcourt.nic.in/index.php",
        "img": "/Home/7.png",
    },
];
