import React from 'react'
import { useSelector } from 'react-redux';

const Loader = () => {
    const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);

    return (
        <div className="Loader_main">
            <div className="loader" style={{backgroundColor:`${theme === "WHITE" ? "#fff" : "#3A3939"}`}}>
                <img className="img-fluid" src="lodar.png" alt="logo" />
            </div>
        </div>
    )
}

export default Loader;