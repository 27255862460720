import React from 'react'
import { useSelector } from 'react-redux';

const H3Public = ({children }) => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier.fontSize);

    return (
        <h3 style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>{children}</h3>
    )
}

export default H3Public