import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import H3Public from "../../../text-components/H3Public";
import PPublic from "../../../text-components/PPublic";

const AboutUs = () => {
  const scrollPage = useRef();
  const theme = useSelector((state) => state.darkWhiteThemeToggle.theme);
  const scroll = useSelector((state) => state.scrollToTop.scroll);

  const scrollToTop = () => {
    scrollPage?.current?.scrollIntoView({ behavior: "smooth"});
  }

  useEffect(() => {
    scrollToTop();
  }, [scroll]);

  return (
    <>
      <div className="about_page_main" ref={scrollPage}>
        <div className="app_flow_main">
          <div className="app_flow_bg">
            <div className="app_flow_row">
              <div className="flow_col">
                <img src="/AboutUs/contact-form.png" alt="" />
                <h5>New Complaint (Online/Toll free call) </h5>
              </div>

              <div className="flow_col">
                <img src="/AboutUs/settings.png" alt="" />
                <h5>Departmental user action</h5>
              </div>

              <div className="flow_col">
                <img src="/AboutUs/computer-worker.png" alt="" />
                <h5>Departments user working</h5>
              </div>

              <div className="flow_col">
                <img src="/AboutUs/task-complete.png" alt="" />
                <h5>Resolved </h5>
              </div>

              <div className="flow_col">
                <img src="/AboutUs/task.png" alt="" />
                <h5>Complaint closed </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="about_page_content_main">

          <div className="about_page_content_inner_main container-fluid" style={{ backgroundColor: `${theme === "WHITE" ? "#E1ECF3" : "#CBCBCB"}` }}>

            <div className="about_page_content_heading" style={{ color: `${theme === "WHITE" ? "#394D61" : "#000"}` }} >
              <h4>About us</h4>
            </div>

            <div className="about_page_content_heading_line">
              <div className="underline_thik" style={{ backgroundColor: `${theme === "WHITE" ? "#394D61" : "#858282"}` }}></div>
              <div className="underline_thin"></div>
            </div>

            <div className="row about_page_content_box">

              <div className="col-md-5 p-0 about_pg_img_box pe-md-4">
                <img src="/AboutUs/aboutmain.jpg" alt="Chandrapur" />
              </div>
              <div className="col-md-7 p-0 content_main">
                <div>
                  <H3Public>History</H3Public>
                  <PPublic>The district Chandrapur was earlier known as ‘Chanda’ according to tradition and legend the name of the place was ‘Lokapura’ which was first changed to ‘ Indpur’ and subsequently to Chandrapur. During the British colonial period it was called Chanda district, which was again changed to its original name ‘Chandrapur ‘ around 1964. Other places of the region in ancient times include wairangad, Kosala, Bhadravati and Markanda. Hindu and Buddhist kings are said to have ruled the area for a long time, Later on Gonds overtook Dana Chiefs who ruled Chandrapur around 9 th century and Gond Kings ruled the area till 1751 after which Maratha period started. Raghuji Bhosale, the last King of the dynasty, died heirless in 1853 and Nagpur province together with Chandrapur was declared annexed to British Empire.</PPublic>

                  <PPublic> In 1854, Chandrapur formed and independent district and in 1874, it comprised of three tehsils Viz Mul, Warora and Bramhpuri. In 1874, however, upper Godavai district of Madras was abolished and four tehsils were added to Chandrapur to form one tehsil with Sironcha as its headquarters. In 1895, the headquarters of one tehsil transferred to MUl to Chandrapur. A new tehsil with headquarter at Gadchoroli was created in 1905 by transfer of zamindari estates from Bramhpuri and Chandrapur tehsil. An small zamindari tract from Chandrapur district as transferred to newly from districts in 1907. In the same year and area of about 1560 sq. km. comprising of three divisions of the lower Sironcha tehsil namely Cherla, Albak nad Nugir were transferred to Madras State.</PPublic>

                  <PPublic> No major changes occurred in the boundaries of the district or its tehsils between 1911-1955. Consequent upon reorganization of the states in 1956, the district was transferred from Madhya Pradesh to Bombay state. In the same Rajura tehsil, a part of Adilabad district of Hydrabad state, was transferred to Nanded district subsequently it was transferred to Chandrapur district in 1959. the district became part of the Maharashtra since its creation in May 1960. For administrative convenience and industrial and agricultural development , this district was again divided into Chandrapur and Gadchiroli district after 1981 census. Chandrapur district now comprises of the tehsil of Chandrapur , Bhadravati, Warora, Chimur, Nagbhir, Bramhpuri, Sindhewahi, Mul, Gondpipri, Pomburna, Saoli, Rajura, Korpana, Jivati and Balharshah.</PPublic>
                </div>
              </div>

            </div>
          </div>

        </div>


      </div>
    </>
  )
}

export default AboutUs