import { createSlice } from "@reduxjs/toolkit";

const showComplaintDetailReducer = createSlice({
    name: "complaintDetailSlice",
    initialState: {
        formState: false
    },
    reducers: {
        showComplaintDetail: (state) => {
            state.formState = true;
        },
        hideComplaintDetail: (state) => {
            state.formState = false;
        }
    }
});

export const { showComplaintDetail, hideComplaintDetail } = showComplaintDetailReducer.actions;
export default showComplaintDetailReducer.reducer;